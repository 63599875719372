// React Imports
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// MUI Imports
import { Box, Button, Paper, Step, StepLabel, Stepper, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
// Component Imports
import KeyPermissions from './KeyPermissions';
import LoadingIndicator from 'components/ui/LoadingIndicator';
import { ManagerDataSelection, ManagerDataTable } from 'features/managers';
import { generateUARReport, NewReport } from 'features/reports';
import SureDialog from 'components/ui/SureDialog';
import { authStore, reportStore } from 'stores';

import { alertMessages } from 'data/alerts';

const GENERATING_REPORT_STEPS = [
    {
        key: 0,
        label: 'Retrieving Salesforce data'
    },
    {
        key: 1,
        label: 'Generating UAR Report'
    },
    {
        key: 2,
        label: 'Generating Report Rows'
    }
];

export default function UARStepper() {
    // Constants
    const { id } = useParams();
    const loadingSteps = GENERATING_REPORT_STEPS;
    const navigate = useNavigate();
    const reportCreationSteps = ['Report Details', 'Select Key Settings', 'Set Manager Data', 'Manager Report'];

    // States
    const [activeStep, setActiveStep] = useState(0);
    const [allSystemSettings] = reportStore.useState('allSystemSettings');
    const [passwordSession] = authStore.useState('passwordSession');
    const [generatingReport, setGeneratingReport] = useState(false);
    const [keyItemSelected, setKeyItemSelected] = useState(false);
    const [managerInfo, , updateManagerInfo] = reportStore.useState('managerInfo');
    const [newReport] = reportStore.useState('newReport');
    const [percentComplete, setPercentComplete] = useState(0);
    const [selectedObjects, , updateSelectedObjects] = reportStore.useState('selectedObjects');
    const [selectedSystemSettings, , updateSelectedSystemSettings] = reportStore.useState('selectedSystemSettings');
    const [skipped, setSkipped] = useState(new Set());
    const [stepperIndex, setStepperIndex] = useState(0);
    const [sureOpen, setSureOpen] = useState(false);

    /**
     * useEffect to reset store states.
     */
    useEffect(() => {
        updateManagerInfo((managerInfo) => {
            managerInfo.managerCSVName = '';
            managerInfo.managerData = [];
            managerInfo.managerField = { name: '', label: '' };
            managerInfo.managerNameApi = '';
            managerInfo.managerReady = false;
            managerInfo.managerSource = '';
        });
        updateSelectedSystemSettings(() => []);
        updateSelectedObjects(() => []);
        return () => {
            updateSelectedSystemSettings(() => []);
            updateSelectedObjects(() => []);
        };
    }, []);

    /**
     * useEffect to determine if at least one setting OR object has been selected
     */
    useEffect(() => {
        if (activeStep === 1) {
            setKeyItemSelected([...selectedSystemSettings, ...selectedObjects].length > 0);
        }
    }, [selectedSystemSettings, selectedObjects]);

    /**
     * useEffect to recalculate the Percentage Complete for Manager Data Table
     */
    useEffect(() => {
        calulcatePercentComplete(managerInfo.managerData);
    }, [managerInfo.managerData]);

    /**
     * Calculates the percentage of Users that have their Manager data filled.
     * @param {Array} managers array of Manager Data
     */
    const calulcatePercentComplete = (managers) => {
        const totalRows = Object.keys(managers).length;
        if (totalRows <= 0) {
            setPercentComplete(0);
        } else {
            const completedRows =
                managerInfo.managerSource === 'Salesforce'
                    ? Object.values(managers).filter((row) => row[managerInfo.managerField.QualifiedApiName]).length
                    : Object.values(managers).filter((row) => row[managerInfo.managerNameApi]).length;
            setPercentComplete(((completedRows / totalRows) * 100).toFixed(2));
        }
    };

    /**
     * Dictates which steps are optional
     * @param {Integer} step number of the step that is optional
     */
    const isStepOptional = () => {
        //return step === 1;
    };

    /**
     * Checks if a step is skipped.
     * @param {Integer} step number of step
     * @returns Boolean whether the step is skipped or not.
     */
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    /**
     * Handles the next button operations.
     */
    const handleNext = () => {
        //If this is the last step
        const isLastStep = activeStep === reportCreationSteps.length - 1;
        if (isLastStep) {
            //And the Managers aren't complete
            if (percentComplete < 100) {
                //Warn the User
                setSureOpen(true);
            } else {
                //Otherwise, generate the report
                generateReport();
            }
        } else {
            let newSkipped = skipped;
            if (isStepSkipped(activeStep)) {
                newSkipped = new Set(newSkipped.values());
                newSkipped.delete(activeStep);
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
        }
    };

    /**
     * Creates the UAR reports and rows and inserts them into the database.
     */
    const generateReport = async () => {
        setGeneratingReport(true);
        //These Timeouts should probably be revisited for a better solution
        await new Promise((r) => setTimeout(r, 2000));
        setStepperIndex(1);

        const uarReport = generateUARReport({
            allSystemSettings: allSystemSettings,
            commit: true,
            managerInfo: managerInfo,
            newReport: newReport,
            projId: id,
            selectedObjects: selectedObjects,
            selectedSystemSettings: selectedSystemSettings
        });
        try {
            const uarResults = await Promise.all([uarReport, (r) => setTimeout(r, 2000)]);
            setStepperIndex(2);
            await new Promise((r) => setTimeout(r, 2000));
            navigate(`/${passwordSession.sessionDetails.namespace}/project/${id}/uar-report/${uarResults[0].report._id}`, {
                replace: true,
                state: {
                    alert: true,
                    alertPayload: {
                        message: alertMessages.success.uar_report_generated,
                        severity: 'success'
                    }
                }
            });
        } catch (err) {
            navigate(`/${passwordSession.sessionDetails.namespace}/project/${id}`, {
                replace: true,
                state: {
                    alert: true,
                    alertPayload: {
                        message: alertMessages.error.uar_report_generated,
                        severity: 'error'
                    }
                }
            });
        }
    };

    /**
     * Handles the back button operations
     */
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    /**
     * Handles the cancel button operations
     */
    const handleCancel = () => {
        navigate(`/${passwordSession.sessionDetails.namespace}/project/${id}`);
    };

    /**
     * Closes Generate Warning
     */
    const handleGenerateNo = () => {
        setSureOpen(false);
    };

    const handleGenerateYes = () => {
        generateReport();
        setSureOpen(false);
    };

    /**
     * Skips the next step.
     */
    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    /**
     * Checks whether the user can proceed to the next step in the flow
     * @returns {Boolean}
     */
    const isNextDisabled = () => {
        switch (activeStep) {
            case 0: {
                const reportNameInvalid = newReport.name.length < 4;
                return reportNameInvalid;
            }
            case 1:
                return !keyItemSelected;
            case 2:
                return !managerInfo.managerReady;
            default:
                return false;
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, p: 15 }}>
            {generatingReport ? (
                <Box sx={{ flex: 1, alignSelf: 'center' }}>
                    <LoadingIndicator loadActiveStep={stepperIndex} steps={loadingSteps}></LoadingIndicator>
                </Box>
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', flex: 1 }}>
                    <Stepper activeStep={activeStep} sx={{ mb: 24 }}>
                        {reportCreationSteps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepOptional(index)) {
                                labelProps.optional = <Typography variant="caption">Optional</Typography>;
                            }
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <React.Fragment>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Paper sx={{ p: 15, minWidth: 800 }}>
                                {' '}
                                {/*Min Height 500*/}
                                {activeStep === 0 && <NewReport></NewReport>}
                                {activeStep === 1 && <KeyPermissions></KeyPermissions>}
                                {activeStep === 2 && <ManagerDataSelection></ManagerDataSelection>}
                                {activeStep === 3 && <ManagerDataTable percentComplete={percentComplete}></ManagerDataTable>}
                            </Paper>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 6 }}>
                            <Button onClick={handleCancel} color="primary">
                                <KeyboardArrowLeftIcon />
                                Cancel
                            </Button>
                            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            {isStepOptional(activeStep) && (
                                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                    Skip
                                </Button>
                            )}

                            <Button onClick={handleNext} disabled={isNextDisabled()}>
                                {activeStep === reportCreationSteps.length - 1 ? 'Generate Report' : 'Next'}
                            </Button>
                        </Box>
                    </React.Fragment>
                </Box>
            )}
            <SureDialog
                title="Generate Report"
                content="Nemos recommends 100% Manager Completion -- Are you sure you want to generate this report?"
                open={sureOpen}
                handleNeg={handleGenerateNo}
                handlePos={handleGenerateYes}
            ></SureDialog>
        </Box>
    );
}
