// React Imports
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// MUI Imports
import { Avatar, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Logout from '@mui/icons-material/Logout';
import PaymentIcon from '@mui/icons-material/Payment';

import { useCognito } from 'features/auth';
import { getProfilePicture } from 'features/accounts';

import { authStore, miscStore } from 'stores';
import NemosFoxLogoDark from 'assets/NemosFoxLogoDarkMode.png';

const AccountButton = () => {
    // Constants
    const navigate = useNavigate();

    // States
    const [anchorEl, setAnchorEl] = useState(null);
    const [passwordSession] = authStore.useState('passwordSession');
    const [profilePictureUrl, setProfilePictureUrl] = miscStore.useState('profilePictureUrl');
    const open = Boolean(anchorEl);

    const { signOut } = useCognito();

    /**
     * Handles the click event for the User Profile icon.
     * @param {Object} event detaild
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Handles the click event for the User Profile icon.
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * Handles the click event for an item in the account dropdown menu
     * @param {Object} event details
     */
    const handleMenuClick = (event) => {
        handleClose();
        if (event.target.id === 'logout') {
            signOut();
            navigate('/login', { replace: true });
        } else if (event.target.id === 'authorize') {
            navigate('/login', { replace: true });
        } else if (event.target.id === 'settings') {
            navigate(`/${passwordSession.sessionDetails.namespace}/settings/personal-profile`, { replace: true });
        } else if (event.target.id === 'billing') {
            navigate(`/${passwordSession.sessionDetails.namespace}/billing`, { replace: true });
        }
    };

    /**
     * This useEffect runs after currentSession is updated to grab the
     * user's profile picture.
     */
    useEffect(() => {
        if (Object.keys(passwordSession.sessionDetails).length > 0) {
            getProfilePicture()
                .then((response) => {
                    if (response.url) {
                        setProfilePictureUrl(response.url);
                    } else {
                        setProfilePictureUrl(null);
                    }
                })
                .catch((error) => {
                    console.log('Error getting profile picture: ', error);
                });
        }
    }, [passwordSession.sessionDetails]);

    return (
        <div style={{ float: 'right', marginRight: 6 }}>
            {passwordSession.isValid && (
                <Tooltip title="Account settings">
                    <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }} aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>
                        <Avatar
                            sx={{ borderColor: 'warning.main', borderWidth: '2px', borderStyle: 'solid', visibility: 'visible' }}
                            src={profilePictureUrl || NemosFoxLogoDark}
                            variant={'circular'}
                            imgProps={{ className: 'accountButtonIcon' }}
                        >
                            {profilePictureUrl && <img width={'100%'} height={'100%'} src={profilePictureUrl} />}
                            {!profilePictureUrl && <NemosFoxLogoDark />}
                        </Avatar>
                    </IconButton>
                </Tooltip>
            )}

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleMenuClick}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem id="settings">
                    <ListItemIcon>
                        <AccountBoxIcon fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem>
                <MenuItem id="billing">
                    <ListItemIcon>
                        <PaymentIcon fontSize="small" />
                    </ListItemIcon>
                    Billing
                </MenuItem>
                <MenuItem id="logout">
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </div>
    );
};

export default AccountButton;
