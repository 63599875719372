import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to update a UAR Row.
 * @param {Array} updatedRow row to update.
 * @returns the newly created UAR Rows.
 */
export const apiUpdateUARRow = async (updatedRow, oldValue) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        body: JSON.stringify({
            newValue: updatedRow,
            oldValue
        })
    };

    const requestPath = `/uarRow`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
