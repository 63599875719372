// React Imports
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
// MUI Imports
import { Button, Box, TextField, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
// File Imports
import { validateEmail } from 'utils/validateEmail';
import { useCognito } from '../hooks/useCognito';
import { checkReportAccess } from 'features/reports';

function CodeVerificationEmail({ setEmailSent, setEmail, setMainText }) {
    // States
    const [errorMessage, setErrorMessage] = useState();
    const [validEmail, setValidEmail] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const { initiatePasswordlessAuth } = useCognito();
    const { reportId } = useParams();
    const theme = useTheme();

    // Methods

    /**
     * Handles the submission of the email input.
     * @param {Object} event Contains the inputted email value.
     */
    const handleSubmit = async (event) => {
        event.preventDefault();
        setShowLoader(true);

        // Grab email from form
        const data = new FormData(event.currentTarget);
        const email = data.get('email');

        try {
            const hasReportAccess = await checkReportAccess(reportId, email);

            if (hasReportAccess) {
                setEmail(email);
                sendVerificationEmail(email).then(() => {
                    setShowLoader(false);
                    setMainText('Verification code has been sent to your email! Please enter the code below.');
                    setEmailSent(true);
                });
            } else {
                setShowLoader(false);
                setErrorMessage('The email you have entered does not have access to this report.');
            }
        } catch (err) {
            setErrorMessage('Unable to send email. Please contact your admin or try again.');
        }
    };

    /**
     * Sends the verification email and starts the passwordless auth session.
     * @param {String} email email of the user
     */
    const sendVerificationEmail = async (email) => {
        await initiatePasswordlessAuth(email);
    };

    /**
     * Handles the value change of the email field.
     * @param {Object} event contains the current value of the email field
     */
    const handleChange = (event) => {
        const email = event.target.value;
        setValidEmail(validateEmail(email));
    };

    return (
        <React.Fragment>
            <Box component="form" sx={{ display: 'flex', flexDirection: 'column', width: 340 }} onSubmit={handleSubmit}>
                <TextField type="email" id="email" name="email" label="Email" variant="outlined" onChange={handleChange} />
                <Typography variant="body2" color={theme.palette.text.error} fontWeight="bold">
                    {errorMessage}
                </Typography>
                <Button sx={{ mt: 5 }} type="submit" variant="contained" disabled={!validEmail}>
                    Submit
                </Button>
                {showLoader && <LinearProgress sx={{ mt: 5 }} />}
            </Box>
        </React.Fragment>
    );
}

export default CodeVerificationEmail;
