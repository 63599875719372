import React from 'react';
// MUI Imports
import { Divider, Grid, Tooltip } from '@mui/material';

// Constants Note: this needs to be moved out of this component since it is supposed to be reusable
const TOOLTIPS = {
    C: 'Create',
    R: 'Read',
    E: 'Edit',
    D: 'Delete',
    V: 'View All',
    M: 'Modify All'
};

/**
 * Generates and returns a grid of items divided by a line
 * @param {Object} listValues list of values to display in the divided list.
 * @param {String} uniqueKey unique key for each grid item created.
 * @returns Grid of items divided by a line
 */
function DividerList({ listValues, uniqueKey }) {
    return (
        <Grid container>
            {listValues.map((listValue, index, { length }) => {
                return (
                    <React.Fragment key={`${index}${uniqueKey}`}>
                        <Grid item xs={1.5} display="flex" justifyContent="center" alignItems="center">
                            <Tooltip title={TOOLTIPS[listValue] ?? ''}>
                                <p>{listValue}</p>
                            </Tooltip>
                        </Grid>
                        {length - 1 !== index && <Divider orientation="vertical" variant="middle" flexItem />}
                    </React.Fragment>
                );
            })}
        </Grid>
    );
}

export default DividerList;
