import { fetchData } from 'services/fetchData';
import { authStore } from 'stores';

/**
 * Sends a request to the API to authorize a new org for an Account
 * @param {String} orgId Id of the org to be authorized
 * @param {Object} options different options for authing the org
 */
export const authorizeAnOrg = async (orgId, options = {}) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const body = JSON.stringify({ ...options, orgId, redirectPath: window.location.pathname });

    const httpOptions = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        method: 'POST',
        withCredentials: true,
        body
    };

    const requestPath = `/salesforce/auth`;
    const { data } = await fetchData(requestPath, httpOptions);

    window.location.href = data.salesforceAuthUrl;
};
