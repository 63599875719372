import { fetchData } from 'services/fetchData';
import { authStore } from 'stores';

/**
 * Send a request to the API to create an org.
 * @param {String} orgType type of org
 * @param {String} envName name of the org.
 * @param {Object} options name of the org.
 * @returns created org.
 */
export const createOrg = async (orgType, envName, options) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const body = JSON.stringify({ ...options, newOrg: { orgType, envName }, redirectPath: window.location.pathname });

    const httpOptions = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        method: 'POST',
        withCredentials: true,
        body
    };

    const requestPath = `/salesforce/auth`;
    const { data } = await fetchData(requestPath, httpOptions);

    window.location.href = data.salesforceAuthUrl;
};
