// React Imports
import React, { useState, useMemo } from 'react';
// MUI Imports
import { Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
// Component Imports
import { RemediationTaskModal } from 'features/remediationTasks';
import { approvalStore, miscStore } from 'stores';
import { alertMessages } from 'data/alerts';

/**
 * Contains all of the approval options for a uar row
 */
function ApprovalOptions({ approval, useReport }) {
    const { approveApproval, isAdminView, refresh, rejectApproval, report } = useReport;

    const selectedApprovalRowIndex = approvalStore.getState('selectedApprovalRowIndex').value;

    const theme = useTheme();
    const iconColors = {
        approve: theme.palette.text.success,
        reject: theme.palette.text.error,
        pending: theme.palette.text.warning
    };

    const approvalsLocked = useMemo(() => isAdminView || new Date().toISOString() > report.dueDate, [isAdminView, report.dueDate]);

    const [, , updateAlert] = miscStore.useState('alert');
    const [rejectModalOpen, setRejectModalOpen] = useState(false);

    /**
     * Updates the approval status to Approved
     */
    const approve = async () => {
        if (isPastDueDate()) {
            postAlert('error', alertMessages.error.approval_closed);
            // TODO: refresh report to dhow message.
        } else {
            let oldStatus = approval.status;
            const newApproval = { ...approval, status: 'Approved' };

            try {
                await approveApproval(oldStatus, newApproval, selectedApprovalRowIndex, approval.index);

                postAlert('success', alertMessages.success.approval_status);
            } catch (err) {
                await refresh();
                postAlert('error', err.message ?? alertMessages.error.approval_status);
            }
        }
    };

    /**
     * Closes the Remediation modal when the user clicks cancel.
     */
    const handleCancelReject = () => {
        setRejectModalOpen(false);
    };

    /**
     * Determines whether thew report is past due.
     * @returns {Boolean}
     */
    const isPastDueDate = () => {
        return new Date().toISOString() > report.dueDate;
    };

    /**
     * Opens the rejection modal.
     */
    const openRejectModal = () => {
        setRejectModalOpen(true);
    };

    /**
     * Rejects the approval
     */
    const reject = async (updatedApproval) => {
        // Closed rejection modal
        setRejectModalOpen(false);

        let oldStatus = approval.status;
        const newApproval = { ...updatedApproval, status: 'Rejected' };
        console.log('new Approval = ', newApproval, selectedApprovalRowIndex, approval.index);

        try {
            await rejectApproval(oldStatus, newApproval, selectedApprovalRowIndex, approval.index);

            postAlert('success', alertMessages.success.approval_status);
        } catch (err) {
            postAlert('error', alertMessages.error.approval_status);
        }
    };

    /**
     * Function that Handles the close event from the Error Alert
     */
    const handleAlertClose = () => {
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    /**
     * Triggers a global alert.
     * @param {String} severity severity of the alert
     * @param {String} message message to display
     */
    const postAlert = (severity, message) => {
        updateAlert((alert) => {
            alert.autoHideDuration = 3000;
            alert.message = message;
            alert.open = true;
            alert.severity = severity;
            alert.onClose = handleAlertClose;
        });
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <IconButton aria-label="Approve" disabled={approvalsLocked} onClick={approve}>
                <CheckCircleIcon sx={{ color: approval.status === 'Approved' ? iconColors.approve : 'gray', pr: 5 }}></CheckCircleIcon>
            </IconButton>
            <IconButton aria-label="Reject" disabled={approvalsLocked} onClick={openRejectModal}>
                <DoNotDisturbIcon sx={{ color: approval.status === 'Rejected' ? iconColors.reject : 'gray', pr: 5 }}></DoNotDisturbIcon>
            </IconButton>
            {rejectModalOpen && <RemediationTaskModal approval={approval} open={rejectModalOpen} cancelReject={handleCancelReject} reject={reject}></RemediationTaskModal>}
        </Box>
    );
}

export default ApprovalOptions;
