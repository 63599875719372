import { Divider, Stack, Typography } from '@mui/material';
import ChipList from 'components/ui/ChipList';

export default function GrantedByInfo({ grantedBy }) {
    return (
        <Stack direction="row" spacing={4}>
            {grantedBy.profile && (
                <Stack spacing={4}>
                    <Typography sx={{ textAlign: 'left' }} color="primary" variant="body2" component="div">
                        <b>Profile</b>
                    </Typography>
                    <ChipList list={[grantedBy.profile]} />
                </Stack>
            )}
            {grantedBy.profile && grantedBy.permissionSets.length > 0 && <Divider orientation="vertical" variant="middle" flexItem />}

            {grantedBy.permissionSets.length > 0 && (
                <Stack spacing={4}>
                    <Typography sx={{ textAlign: 'left' }} color="primary" variant="body2" component="div">
                        <b>Permission Sets</b>
                    </Typography>
                    <ChipList list={grantedBy.permissionSets} />
                </Stack>
            )}
        </Stack>
    );
}
