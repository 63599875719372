// React Imports
import * as React from 'react';
// MUI Imports
import { styled } from '@mui/material/styles';
import { Box, CircularProgress, Stepper, Step, StepLabel, Typography, StepConnector } from '@mui/material';
import { stepConnectorClasses } from '@mui/material/StepConnector';
import { Settings, GroupAdd, VideoLabel, Check } from '@mui/icons-material';
// 3rd Party Imports
import PropTypes from 'prop-types';

/**
 * Element that is placed between each step. In this case a horizontal line.
 */
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            background: `linear-gradient(to right, #4BB543 50%, ${theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0'} 50%) left`,
            backgroundSize: '200% 100%',
            transition: 'all 1s ease-out'
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#4BB543'
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        background: theme.palette.mode === 'dark' ? `${theme.palette.grey[800]} right` : '#eaeaf0 right',
        borderRadius: 1
    }
}));

/**
 * Backgorundtyling paramaters for each step icon.
 */
const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: 'transparent'
    }),
    ...(ownerState.completed && {
        backgroundColor: '#4BB543',
        transition: '2s'
    })
}));

/**
 * Sets a returns the Icons to display for each step
 * @param {Objects} props different states for the locading indicator icons.
 * @returns the current element to display for the step.
 */
/**
 * Custom component for rendering step icons with loading progress or checkmarks.
 * @param {Object} props React component props.
 * @param {boolean} props.active Indicates whether the step is active.
 * @param {boolean} props.completed Indicates whether the step is completed.
 * @param {string} props.className Custom CSS class names.
 * @returns {React.Element} Rendered custom step icon component.
 */
function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <Settings />,
        2: <GroupAdd />,
        3: <VideoLabel />
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {completed ? <Check /> : active ? <CircularProgressWithLabel value={icons[String(props.icon)]} /> : icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

/**
 * Defines the props for the ColorlibStepIcon function.
 */
ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node
};

/**
 * Contains the elements to display a circular progress indicator with a label.
 * @param {Object} props
 * @returns circiluar progress element
 */
/**
 * Custom component for rendering a circular progress bar with a label inside.
 * @param {Object} props React component props.
 * @param {node} props.value Label to display inside the progress bar.
 * @returns {React.Element} Rendered circular progress bar with a label.
 */
function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                {props.value}
            </Box>
        </Box>
    );
}

/**
 * Defines the props for the CircularProgressWithLabel component.
 */
CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.object.isRequired
};

/**
 * Main component responsible for displaying the loading indicator with steps.
 * @param {Object} props
 * @param {number} props.loadActiveStep - The current active step of the loading indicator.
 * @param {Array} props.steps - The steps to be displayed by the loading indicator.
 * @returns {React.Element} The rendered loading indicator element with steps.
 */
/**
 * Main component for rendering the loading indicator with configurable steps.
 * @param {Object} props React component props.
 * @param {number} props.loadActiveStep The index of the current active step.
 * @param {Array<Object>} props.steps Array of step objects to be displayed.
 * @returns {React.Element} Rendered loading indicator component with steps.
 */
export default function LoadingIndicator({ loadActiveStep, steps }) {
    return (
        <>
            <Stepper alternativeLabel activeStep={loadActiveStep} connector={<ColorlibConnector />}>
                {steps.map((step) => (
                    <Step key={step.key}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 20 }}>
                                <Typography sx={{ pl: 3, transition: '2s', transform: '2s' }}>{step.label}</Typography>
                            </Box>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </>
    );
}
