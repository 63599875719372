// React Imports
import React from 'react';
// MUI Imports
import { Box } from '@mui/material';
// Component Imports
import { SettingsSideBar } from 'features/settings';
import { SalesforceOrgsTable } from 'features/organizations';

function SalesforceOrgs() {
    return (
        <Box sx={{ display: 'flex', flex: 1 }}>
            <SettingsSideBar />
            <Box sx={{ flex: 1 }}>
                <SalesforceOrgsTable />
            </Box>
        </Box>
    );
}

export default SalesforceOrgs;
