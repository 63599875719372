import { createStore } from 'state-pool';

const authStore = createStore();

// Debounce function for persist setup
function debounce(func, timeout) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

// Set up persist methods.
authStore.persist({
    saveState: function (key, value, isInitialSet) {
        const doStateSaving = () => {
            try {
                const serializedState = JSON.stringify(value);
                sessionStorage.setItem(key, serializedState);
            } catch {
                // Ignore write errors
            }
        };

        if (isInitialSet) {
            doStateSaving();
        } else {
            const DEBOUNCE_TIME = 1000;
            const processStateSaving = debounce(doStateSaving, DEBOUNCE_TIME);
            processStateSaving();
        }
    },
    loadState: function (key, noState) {
        try {
            const serializedState = sessionStorage.getItem(key);
            if (serializedState === null) {
                // No state saved
                return noState;
            }
            return JSON.parse(serializedState);
        } catch (err) {
            // Failed to load state
            return undefined;
        }
    },
    removeState: function (key) {
        sessionStorage.removeItem(key);
    },
    clear: function () {
        sessionStorage.clear();
    }
});

authStore.setState('passwordSession', { isValid: false, sessionDetails: {}, isLoading: true });
authStore.setState('passwordlessSession', { isValid: false, sessionDetails: {}, isLoading: true });
authStore.setState('passwordlessAuthChallenge', {});
authStore.setState('lastEmailVerificationSentTime', null, { persist: true });
authStore.setState('sessionError', '');

export default authStore;
