import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to create the approvals rows for a UAR.
 * @param {String} reportId Id of the UAR Report.
 * @returns l
 */
export const createApprovalRows = async (reportId) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        method: 'POST',
        withCredentials: true
    };

    const requestPath = `/approvalRow?reportId=${reportId}&isPasswordless=${false}`;
    const { response } = await fetchData(requestPath, httpOptions);
    return response;
};
