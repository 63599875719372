import { createStore } from 'state-pool';

import { alertSettings } from '../data/alerts';

const miscStore = createStore();

miscStore.setState('alert', alertSettings);
miscStore.setState('redirectInfo', {});
miscStore.setState('profilePictureUrl', '');
miscStore.setState('redirectPath', null);
miscStore.setState('tableRefreshTrigger', false);

export default miscStore;
