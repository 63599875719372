import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to delete a Project.
 * @param {Object} project Id of the Project to delete..
 * @returns the deleted Project.
 */
export const deleteProject = async (project) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        body: JSON.stringify(project)
    };

    const requestPath = `/project`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
