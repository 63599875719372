import React, { useEffect, useRef } from 'react';

import { useTheme } from '@mui/material/styles';
import { Button, Box, Grid, Typography, Paper, Stack } from '@mui/material';

import moment from 'moment';

import { billingStore } from 'stores';

function AccountDetails() {
    const [stripe] = billingStore.useState('stripe');
    const theme = useTheme();
    const componentIsMounted = useRef(true);
    useEffect(() => {
        return () => {
            componentIsMounted.current = false;
        };
    }, []);

    function accessSubscription() {
        window.open(process.env.REACT_APP_STRIPE_PORTAL_URL, '_blank');
    }

    return (
        <Box sx={{ padding: 10 }}>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
                        Account Details
                    </Typography>
                    <Paper elevation={2} sx={{ p: 10, mt: 10 }}>
                        <Grid container spacing={5}>
                            <Grid item xs={6}>
                                <Stack direction={'row'} sx={{}} spacing={4} alignItems={'baseline'} justifyContent={'flex-start'}>
                                    <Typography variant={'h6'} color={'text.primary'}>
                                        Status:
                                    </Typography>
                                    <Typography variant={'body1'} color={'text.primary'}>
                                        {stripe.status.charAt(0).toUpperCase() + stripe.status.slice(1)}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction={'row'} sx={{}} spacing={4} alignItems={'baseline'} justifyContent={'flex-start'}>
                                    <Typography variant={'h6'} color={'text.primary'}>
                                        Subscription Start:
                                    </Typography>
                                    <Typography variant={'body1'} color={'text.primary'}>
                                        {moment.unix(stripe.start_date).format('MMM Do YYYY')}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction={'row'} sx={{}} spacing={4} alignItems={'baseline'} justifyContent={'flex-start'}>
                                    <Typography variant={'h6'} color={'text.primary'}>
                                        Amount:
                                    </Typography>
                                    <Typography variant={'body1'} color={'text.primary'}>
                                        {'$' + stripe.plan.amount / 100 + ' per ' + stripe.plan.interval}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction={'row'} sx={{}} spacing={4} alignItems={'baseline'} justifyContent={'flex-start'}>
                                    <Typography variant={'h6'} color={'text.primary'}>
                                        Subscription End:
                                    </Typography>
                                    <Typography variant={'body1'} color={'text.primary'}>
                                        {moment.unix(stripe.current_period_end).format('MMM Do YYYY')}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={4}></Grid>
                <Grid item xs={2}>
                    <Button onClick={accessSubscription} variant={'contained'} color={'success'} sx={{ color: 'text.primary' }}>
                        Manage Billing
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default AccountDetails;
