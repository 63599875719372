// React Imports
import React from 'react';
// Mui Imports
import { Button, Chip } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFileSharp';
// File Imports
import { miscStore } from 'stores';
import { alertMessages } from 'data/alerts';

//TODO Make more general so that more than a CSV can be uploaded (line 29)
function FileUploader({ extensionsAccepted, fileSizeLimit, handleDelete, isMultiple, onFileUploaded, uploadedFile }) {
    //States
    const [, , updateAlert] = miscStore.useState('alert');

    /**
     * Function that handles a File being Selected
     * @param {Object} event
     * @returns
     */
    const handleFileSelect = async (event) => {
        //Retrieve File From Event
        const file = event.target.files[0];

        const fileTypeIsWrong = !file || file.type !== 'text/csv';
        const fileSizeIsTooLarge = file.size > fileSizeLimit;
        if (fileTypeIsWrong || fileSizeIsTooLarge) {
            updateAlert((alert) => {
                alert.autoHideDuration = 3000;
                alert.message = alertMessages.error.csv_type;
                alert.open = true;
                alert.severity = 'error';
                alert.onClose = handleAlertClose;
            });
            event.target.value = '';
            return;
        }

        //Get the Contents of the File and Call Parent to Validate
        const reader = new FileReader();
        reader.onload = async (event2) => {
            const contents = event2.target.result;
            await onFileUploaded(file.name, contents);
        };
        reader.readAsText(file);

        //Clear the value so the same file can be attempted twice in a row
        event.target.value = '';
    };

    /**
     * Function that Handles the close event from the Error Alert
     */
    const handleAlertClose = () => {
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    /**
     * Function that checks whether or not the Upload Button should be disabled
     * @returns Boolean Value for whether the Upload Button should be disabled or not
     */
    const checkDisabled = () => {
        return Boolean(!isMultiple && uploadedFile);
    };

    return (
        <React.Fragment>
            <input type="file" data-testid="file-upload" accept={extensionsAccepted} name="file" id="file-upload" onChange={handleFileSelect} style={{ display: 'none' }} />
            <label htmlFor="file-upload">
                <Button sx={{ mr: 5 }} variant="contained" color="primary" component="span" disabled={checkDisabled()}>
                    Upload
                </Button>
            </label>
            {uploadedFile && !isMultiple && (
                <React.Fragment>
                    <UploadFileIcon sx={{ mt: 5 }} fontSize="large" color="primary" />
                    <Chip label={uploadedFile} onDelete={handleDelete}></Chip>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default FileUploader;
