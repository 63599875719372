// React Imports
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
// MUI Imports
import { Grid } from '@mui/material';
// Component Imports
import { AuditorModal, AuditorSelector } from 'features/auditors';
import { ProjectCard } from 'features/projects';
import SureDialog from 'components/ui/SureDialog';
import { getUARReportsByProject, updateUARReports, UARReportsTable } from 'features/reports';
// API Imports
import { sendDiffEmail } from 'features/auditors';
import { getProject, updateProject, deleteProject } from 'features/projects';
import { authorizeAnOrg } from 'features/organizations';
// File Imports
import { alertMessages } from 'data/alerts';
import { authStore, miscStore, reportStore } from 'stores';

function Project() {
    //Constants
    const { id } = useParams();
    const isMounted = useRef(true);
    const location = useLocation();
    const navigate = useNavigate();

    //useStates
    const [, , updateAlert] = miscStore.useState('alert');
    const [allSystemSettings] = reportStore.useState('allSystemSettings');
    const [auditorOpen, setAuditorOpen] = useState(false);
    const [completeOpen, setCompleteOpen] = useState(false);
    const [passwordSession] = authStore.useState('passwordSession');
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [project, setProject] = useState();
    const [reports, setReports] = useState([]);
    const [searchParams] = useSearchParams();

    //useEffects
    //Retrieve Project from Mongo
    useEffect(() => {
        const getProjectPageDetails = async () => {
            try {
                // Get Project details and all related reports
                const [project, reports] = await Promise.all([getProject(id), getUARReportsByProject(id, { status: { $ne: 'Deleted' } })]);
                if (isMounted.current) {
                    setProject(project);
                    setReports(reports);
                }
            } catch (err) {
                if (isMounted.current) {
                    postAlert('error', alertMessages.error.project_get);
                }
            }
        };
        isMounted.current = true;
        getProjectPageDetails();
        return () => {
            isMounted.current = false;
        };
    }, []);

    // useEffect to take the searchParams and display notifcations based on returned payloads/open modals/fill in text
    useEffect(() => {
        // Checks if alert should be posted for successful auth
        const authResult = searchParams.get('authResult');
        if (authResult === 'success') {
            setAuditorOpen(true);
            postAlert('success', alertMessages.success.auth);
        } else if (authResult === 'failure') {
            // We need to set the redirect info for another attempt
            reportStore.setState('diffReportRedirectInfo', JSON.parse(searchParams.get('redirectInfo')));
            const errorPayload = JSON.parse(searchParams.get('errorPayload'));
            postAlert('error', errorPayload.message);
        }
    }, [searchParams]);

    // useEffect to open a notification if navigated with one
    useEffect(() => {
        if (location.state?.alert) {
            postAlert(location.state.alertPayload.severity, location.state.alertPayload.message);
            window.history.replaceState({}, document.title);
        }
    }, [location]);

    //Functions
    /*
     * Calls API to update project to completed
     */
    const callUpdateProject = async () => {
        try {
            await updateProject({ ...project, status: 'Completed' });
            toHome('updated');
        } catch (err) {
            setCompleteOpen(false);
            postAlert('error', alertMessages.error.project_updated);
        }
    };

    /**
     * Function to close the toast notification
     */
    const handleAlertClose = () => {
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    /**
     * Function to set the mark complete Modal Closed
     */
    const handleCompleteProjectClose = () => {
        setCompleteOpen(false);
    };

    /**
     * Function to call the API to update the project and reports, then navigate to the home page
     */
    const handleCompleteProjectYes = async () => {
        try {
            setReports(() =>
                reports.map((report) => {
                    if (report.status !== 'Completed') return { ...report, status: 'Completed' };
                    return { ...report };
                })
            );
            await updateUARReports({
                filter: { status: { $ne: 'Completed' }, projId: project._id },
                updateDoc: { status: 'Completed' }
            });

            await callUpdateProject();
        } catch (err) {
            postAlert('error', alertMessages.error.uar_report_updated);
        } finally {
            setCompleteOpen(false);
        }
    };

    /**
     * Function to close the Delete Project Modal
     */
    const handleDeleteProjectClose = () => {
        setDeleteOpen(false);
    };

    /**
     * Function to set the Delete Modal Open
     */
    const handleDeleteProjectOpen = () => {
        setDeleteOpen(true);
    };

    /**
     * Function to call the API to Delete/Archive the Project and Children, then navigate to the home page
     */
    const handleDeleteProjectYes = async () => {
        try {
            await deleteProject({ project: project, reports: reports });
            toHome('deleted');
        } catch (err) {
            handleDeleteProjectClose();
            postAlert('error', alertMessages.error.project_deleted);
        }
    };

    /**
     * Function to set the Delete Modal Closed
     */
    const handleMarkComplete = async () => {
        const openReports = reports.filter((report) => report.status !== 'Completed');
        if (openReports.length > 0) {
            setCompleteOpen(true);
        } else {
            await callUpdateProject();
        }
    };

    /**
     * Function that calls the API to send the diff email
     * @param {Array} reports Relevant Report Object
     */
    const handleSendDiff = async (reports) => {
        setIsLoading(true);
        try {
            //Send Diff Email
            const result = await sendDiffEmail({
                accId: reports[0].accId,
                allSystemSettings: allSystemSettings,
                auditors: project.auditors,
                ccAddress: passwordSession.sessionDetails.email,
                commit: false,
                fromCompany: passwordSession.sessionDetails.companyName,
                uarReport: reports[0],
                orgId: project.orgId,
                projId: id,
                selectedObjects: reports[0].keyObjects,
                selectedSystemSettings: reports[0].keySystemSettings
            });
            // Check a response to see if access token is still valid
            const accessTokenHasExpired = result.response.status === 400;
            if (accessTokenHasExpired) {
                const options = { redirectInfo: reportStore.getState('diffReportRedirectInfo').value };
                authorizeAnOrg(project.orgId, options);
            } else {
                postAlert('success', alertMessages.success.email_diff);
            }
        } catch (err) {
            postAlert('error', alertMessages.error.email_diff);
        } finally {
            setIsLoading(false);
            setAuditorOpen(false);
        }
    };

    /**
     * Funciton to Close Sure Modal
     */
    const handleSureClose = () => {
        setAuditorOpen(false);
    };

    /**
     * Function to Open Sure Modal
     */
    const handleSureOpen = () => {
        setAuditorOpen(true);
    };

    /**
     * Function to initial Auditor Review and Project Update
     */
    const handleSureYes = (selections) => {
        handleSendDiff(retrieveReports(selections));
    };

    /**
     * Triggers a global alert.
     * @param {String} severity severity of the alert
     * @param {String} message message to display
     */
    const postAlert = (severity, message) => {
        updateAlert((alert) => {
            alert.autoHideDuration = 3000;
            alert.message = message;
            alert.open = true;
            alert.severity = severity;
            alert.onClose = handleAlertClose;
        });
    };

    const retrieveReports = (selection) => {
        return reports.filter((report) => report.name === selection.label);
    };

    /**
     * Function to Navigate to the Home Page
     */
    const toHome = (reason) => {
        const isDeleted = reason === 'deleted';
        const isUpdated = reason === 'updated';

        const navOptions = {
            replace: isDeleted || isUpdated ? true : false,
            state:
                isDeleted || isUpdated
                    ? {
                          alert: true,
                          alertPayload: {
                              message: isDeleted ? alertMessages.success.project_deleted : alertMessages.success.project_updated,
                              severity: 'success'
                          }
                      }
                    : null
        };

        navigate(`/${passwordSession.sessionDetails.namespace}`, navOptions);
    };

    return (
        <React.Fragment>
            {project && (
                <>
                    <Grid container spacing={20} alignContent="start">
                        <Grid item xs={12}>
                            <ProjectCard project={project} deleteHandler={handleDeleteProjectOpen} markCompleteHandler={handleMarkComplete} sendToAuditorHandler={handleSureOpen}></ProjectCard>
                        </Grid>

                        <Grid item xs={12} md={7}>
                            <UARReportsTable reports={reports} setReports={setReports}></UARReportsTable>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <AuditorSelector project={project} setProject={setProject}></AuditorSelector>{' '}
                        </Grid>
                    </Grid>
                    {auditorOpen && (
                        <AuditorModal auditors={project.auditors} handleNo={handleSureClose} handleYes={handleSureYes} isLoading={isLoading} isOpen={auditorOpen} reports={reports}></AuditorModal>
                    )}
                </>
            )}
            <SureDialog
                title="Delete Project"
                content="Are you sure you want to delete this Project? This action is irreversible"
                open={deleteOpen}
                handleNeg={handleDeleteProjectClose}
                handlePos={handleDeleteProjectYes}
            ></SureDialog>
            <SureDialog
                title="Complete Project"
                content="You currently have open reports in this project. Marking it complete will close these reports. Are you sure?"
                open={completeOpen}
                handleNeg={handleCompleteProjectClose}
                handlePos={handleCompleteProjectYes}
            ></SureDialog>
        </React.Fragment>
    );
}

export default Project;
