// React Imports
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// MUI Imports
import { Container, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// Components
import InfoPaper from 'components/ui/InfoPaper';
import { ResendEmailVerificationButton } from 'features/auth';

import { urls } from 'data/constants';

function SignUpSuccess() {
    // Constants
    const currentTheme = useTheme();
    const { state } = useLocation();

    return (
        <>
            {state?.email ? (
                <Container maxWidth="sm" sx={{ pt: 80 }}>
                    <InfoPaper
                        bodyText={`Your Nemos account is almost ready to go. An email has been sent to ${state.email} with a verification link. Please click this link within 24 hours to activate your
                                account and start simplifying your compliance processes.`}
                        footerButton={<ResendEmailVerificationButton />}
                        footerText={
                            <span>
                                Need a hand? Our customer support is <Link href={urls.prod.customerSupport}>here</Link> to assist you every step of the way!
                            </span>
                        }
                        headerIcon={<CheckCircleOutlineIcon sx={{ alignSelf: 'center', fontSize: 80, color: currentTheme.palette.text.success }} />}
                        headerText={'Account created!'}
                    />
                </Container>
            ) : (
                <Navigate to="/sign-up" />
            )}
        </>
    );
}

export default SignUpSuccess;
