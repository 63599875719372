import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export const getTheme = (mode) => ({
    spacing: 2,
    typography: {
        button: {
            textTransform: 'none'
        },
        h6: {
            fontWeight: 'bold'
        }
    },
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                  // palette values for light mode
                  primary: {
                      main: '#000000' // black
                  },
                  secondary: {
                      main: '#f3b040' // orange
                  },
                  background: {
                      default: '#f7f7f8', // off white
                      paper: '#ffffff' // white
                  },
                  text: {
                      primary: '#000000', // black
                      secondary: '#000000', // black
                      success: '#14ac14',
                      error: '#ff3f3f',
                      warning: '#f3b040'
                  }
              }
            : {
                  // palette values for dark mode
                  primary: {
                      main: '#f3b040' // orange
                  },
                  secondary: {
                      main: '#ffffff' // white
                  },
                  background: {
                      default: '#282c34', // dark grey
                      paper: '#282c34' // dark grey
                  },
                  text: {
                      primary: '#fff', // white
                      secondary: '#fff', // white
                      success: '#51ff51', // bright green
                      error: '#ff3f3f',
                      warning: '#f3b040'
                  }
              })
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {},
                head: {
                    fontWeight: 'bold'
                }
            }
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    '&::-webkit-scrollbar': {
                        width: '0.5em'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'grey',
                        borderRadius: '1rem'
                    }
                }
            }
        },
        ...(mode === 'light'
            ? {
                  MuiToolbar: {
                      styleOverrides: {
                          root: {
                              backgroundColor: '#f3b040' //orange
                          }
                      }
                  },
                  MuiTablePagination: {
                      styleOverrides: {
                          toolbar: {
                              /*We have this here to overwrite the background color assigned on the toolbar. 
                              This is because the table pagination built into Mui Datagrid generates a toolbar 
                              that we do not want colored.*/
                              backgroundColor: '#fff !important'
                          }
                      }
                  },
                  MuiDataGrid: {
                      styleOverrides: {
                          root: {
                              borderRadius: '0px 0px' // removes small gap that appears between toolbars and datagrids in light mode
                          },
                          columnHeaders: {
                              backgroundColor: '#f7f7f8'
                          },
                          columnHeaderTitle: {
                              fontWeight: 'bold'
                          }
                      }
                  }
              }
            : {
                  MuiTableCell: {
                      styleOverrides: {
                          head: {
                              fontWeight: 'bold'
                          }
                      }
                  },
                  MuiDataGrid: {
                      styleOverrides: {
                          columnHeaders: {
                              backgroundColor: '#282c34'
                          },
                          columnHeaderTitle: {
                              fontWeight: 'bold'
                          }
                      }
                  }
              })
    }
});

export const styles = {
    table: {
        '& .MuiTableCell-root': {
            padding: '4px 8px', // adjust the padding here
            fontSize: 13 // adjust the font size here
        }
    }
};

export const styledComponents = {
    mainBox: styled(Box)(({ theme }) => ({
        bgcolor: 'text.disabled',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.default
    }))
};
