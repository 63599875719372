// React Imports
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
// MUI Imports
import { Badge, Box, Button, Divider, IconButton, Paper, Tooltip, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import { KeyboardArrowRight, Delete } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import SummarizeIcon from '@mui/icons-material/Summarize';
import TaskIcon from '@mui/icons-material/Task';
// API Imports
import { updateUARReport } from '../services/updateUARReport';
import { getPendingRemediationTaskCounts } from 'features/remediationTasks';
// Utils
import { createMapFromMongoDBArray } from 'utils/createMapFromMongoDBArray';
// Component Imports
import NoRowsAlert from 'components/ui/NoRowsAlert';
import TableHeader from 'components/ui/TableHeader';

import { alertMessages } from 'data/alerts';
import { miscStore } from 'stores';

function UARReportsTable({ reports, setReports }) {
    // States
    const [, , updateAlert] = miscStore.useState('alert');
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(true);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();
    const [pageSize, setPageSize] = useState();
    const [reportTaskCountsMap, setReportTaskCountsMap] = useState({});
    const [totalTaskCount, setTotalTaskCount] = useState();

    // Constants
    const columns = [
        { headerName: 'Name', field: 'name', flex: 1 },
        {
            headerName: 'Created Date',
            field: 'createdAt',
            type: 'date',
            flex: 1,
            hidden: isSmallScreen ? true : false,
            renderCell: (cellValues) => new Date(cellValues.value).toLocaleDateString()
        },
        {
            headerName: 'Status',
            field: 'status',
            renderCell: (cellValues) => {
                return isSmallScreen ? getStatusIcon(cellValues.value) : cellValues.value;
            }
        },
        {
            align: 'center',
            headerName: 'Remediation Tasks',
            field: 'remediationTasks',
            flex: 1,
            renderCell: (cellValues) => {
                return reportTaskCountsMap[cellValues.id]?.count || 0;
            },
            renderHeader: (cellValues) => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Badge sx={{ ml: 5 }} badgeContent={totalTaskCount} color="primary"></Badge>
                        <strong>
                            <Box sx={{ pl: 8 }}>{cellValues.colDef.headerName}</Box>
                        </strong>
                    </Box>
                );
            }
        },
        {
            headerName: '',
            field: 'rowActions',
            flex: 1,
            align: 'center',
            sortable: false,
            filterable: false,
            renderCell: (cellValues) => {
                const rowIndex = cellValues.api.getRowIndex(cellValues.id);
                return (
                    <Box sx={{ display: 'flex' }}>
                        {cellValues.row.status === 'Approval Review' && (
                            <>
                                <Link to={`${location.pathname}/uar-report/${cellValues.row._id}/remediation-tasks`}>
                                    <Tooltip title="Remediation Tasks">
                                        <IconButton aria-label="open" color="primary">
                                            <TaskIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Link>
                                <Divider orientation="vertical" variant="middle" flexItem />
                            </>
                        )}
                        {cellValues.row.confirmVisible ? (
                            <Box sx={{ display: 'flex' }}>
                                <IconButton aria-label="confirm" color="success" onClick={() => deleteReport(cellValues.row._id, rowIndex)}>
                                    <CheckIcon />
                                </IconButton>
                                <IconButton aria-label="cancel" color="error" onClick={() => cancelDelete(rowIndex)}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Tooltip title="Delete">
                                <IconButton aria-label="open" color="primary" onClick={() => showConfirm(rowIndex)}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Link to={`${location.pathname}/uar-report/${cellValues.row._id}`}>
                            <Tooltip title="Report">
                                <IconButton aria-label="open" color="primary">
                                    <KeyboardArrowRight />
                                </IconButton>
                            </Tooltip>
                        </Link>
                    </Box>
                );
            }
        }
    ];

    const UAR_REPORT_TABLE_ACTIONS = [
        <Link key="newReportLink" to={`${location.pathname}/uar-process`} style={{ textDecoration: 'none' }}>
            <Button sx={{ whiteSpace: 'nowrap' }} id="salesforce" variant="contained">
                New Report
            </Button>
        </Link>
    ];

    // UseEffects
    useEffect(() => {
        let isMounted = true;
        const getRemediationTaskCounts = async () => {
            // Check if there are any completed reports
            const completeReportIds = reports
                .reduce((ids, report) => {
                    if (report.status === 'Approval Review') ids.push(`uarReportIds=${report._id}`);
                    return ids;
                }, [])
                .join('&');
            if (completeReportIds) {
                // Get all Remediation Tasks for completed reports
                const taskCountResults = await getPendingRemediationTaskCounts(completeReportIds);
                const reportTaskCountsMap = createMapFromMongoDBArray(taskCountResults);
                if (isMounted) {
                    setReportTaskCountsMap(reportTaskCountsMap);
                    setTotalTaskCount(taskCountResults.reduce((totalCount, result) => (totalCount += result.count), 0));
                }
            }
            setIsLoading(false);
        };
        getRemediationTaskCounts();
        return () => {
            isMounted = false;
        };
    }, [reports]);

    /**
     * Cancels the delete operation.
     * @param {Integer} index index position of the report
     */
    const cancelDelete = (index) => {
        reports[index].confirmVisible = false;
        setReports([...reports]);
    };

    /**
     * Deletes a report and all child rows based on the privided report id.
     * @param {String} reportId id of the report to delete.
     * @param {Integer} index which row to remove from the table
     */
    const deleteReport = async (reportId, index) => {
        let updatedReports = [...reports];
        const report = updatedReports[index];
        report.status = 'Deleted';
        const result = await updateUARReport(report);
        if (result._id === reportId) {
            updatedReports.splice(index, 1);
            setReports(updatedReports);
            postAlert('success', alertMessages.success.uar_report_deleted);
        } else {
            postAlert('error', alertMessages.error.uar_report_deleted);
        }
    };

    /**
     * Function to return the correct icon for the status
     * @param {String} status
     * @returns markup element
     */
    const getStatusIcon = (status) => {
        if (status === 'Completed') {
            return <CheckCircleOutlineIcon sx={{ color: 'lightgreen' }} />;
        } else if (status === 'Ready to Send') {
            return <MoreHorizIcon color="primary" />;
        } else if (status === 'Pending Approvals') {
            return <PendingActionsIcon sx={{ color: 'orange' }} />;
        }
    };

    /**
     * Function to close the toast notification
     */
    const handleAlertClose = () => {
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    /**
     * When resizing the window to a small screen, the autoPageSize prop on DataGrid kicks in
     * and calulates the num of rows to show to prevent scrolling. We store that value in
     * pageSize and pass it to the pageSize prop.
     * @param {Number} numOfRows the number of rows to show in the table.
     */
    const handlePageSizeChange = (numOfRows) => {
        setPageSize(numOfRows);
    };

    /**
     * Triggers a global alert.
     * @param {String} severity severity of the alert
     * @param {String} message message to display
     */
    const postAlert = (severity, message) => {
        updateAlert((alert) => {
            alert.autoHideDuration = 3000;
            alert.message = message;
            alert.open = true;
            alert.severity = severity;
            alert.onClose = handleAlertClose;
        });
    };

    /**
     * Shows the confirm options when trying to delete a Report.
     * @param {Integer} index index position of the report
     */
    const showConfirm = (index) => {
        reports[index].confirmVisible = true;
        setReports([...reports]);
    };

    return (
        <React.Fragment>
            <Paper elevaton={6} sx={{ display: 'flex', flexDirection: 'column', height: 400 }}>
                <TableHeader icon={<SummarizeIcon color="primary" />} title="Reports" showActions actions={UAR_REPORT_TABLE_ACTIONS} />
                <DataGrid
                    autoPageSize={isSmallScreen ? true : false}
                    columns={columns}
                    columnVisibilityModel={columns.reduce((acc, column) => {
                        acc[column.field] = column.hidden ? false : true;
                        return acc;
                    }, {})}
                    components={{ NoRowsOverlay: () => <NoRowsAlert title="No Reports" message="Get started by creating a new report!"></NoRowsAlert> }}
                    disableSelectionOnClick
                    getRowId={(row) => row._id}
                    loading={isLoading}
                    onPageSizeChange={handlePageSizeChange}
                    pageSize={isSmallScreen ? pageSize : 100}
                    rows={reports}
                    rowHeight={50}
                    rowsPerPageOptions={[100]}
                ></DataGrid>
            </Paper>
        </React.Fragment>
    );
}

export default UARReportsTable;
