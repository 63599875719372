import { fetchData } from 'services/fetchData';

import { authStore, reportStore } from 'stores';

/**
 * Function to retrieve all System Settings from a Salesforce org.
 * @returns
 */
export const getSystemSettings = async () => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;
    const organization = reportStore.getState('currentOrg').value;

    const httpOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`
        },
        withCredentials: true
    };

    // All other quries
    const { data, response } = await fetchData(`/salesforce/system-settings/${organization._id}`, httpOptions);
    return { data, response };
};
