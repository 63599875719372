// React Imports
import React, { useEffect, useState } from 'react';
// MUI Imports
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function PasswordValidator(props) {
    const { passwordVal, setPasswordValid } = props;

    // States
    const [characterLengthColor, setCharacterLengthColor] = useState('gray');
    const [numberColor, setNumberColor] = useState('gray');
    const [specialCharacterColor, setSpecialCharacterColor] = useState('gray');
    const [upperCaseColor, setUpperCaseColor] = useState('gray');
    const [lowerCaseColor, setLowerCaseColor] = useState('gray');

    /**
     * This useEffect checks the password for each requirement and sets the appropriate color.
     */
    useEffect(() => {
        let passCounter = 0;
        if (passwordVal.length >= 8) {
            setCharacterLengthColor('lightgreen');
            passCounter++;
        } else {
            setCharacterLengthColor('gray');
        }
        if (/\d/.test(passwordVal)) {
            setNumberColor('lightgreen');
            passCounter++;
        } else {
            setNumberColor('gray');
        }
        if (/_|[^\w]/.test(passwordVal)) {
            setSpecialCharacterColor('lightgreen');
            passCounter++;
        } else {
            setSpecialCharacterColor('gray');
        }
        if (/[A-Z]/.test(passwordVal)) {
            setUpperCaseColor('lightgreen');
            passCounter++;
        } else {
            setUpperCaseColor('gray');
        }
        if (/[a-z]/.test(passwordVal)) {
            setLowerCaseColor('lightgreen');
            passCounter++;
        } else {
            setLowerCaseColor('gray');
        }
        passCounter === 5 ? setPasswordValid(true) : setPasswordValid(false);
    });
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body2" color={characterLengthColor} sx={{ display: 'flex' }}>
                        <CheckCircleOutlineIcon fontSize="small" sx={{ mr: 4 }} /> 8 characters
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" color={numberColor} sx={{ display: 'flex' }}>
                        <CheckCircleOutlineIcon fontSize="small" sx={{ mr: 4 }} /> 1 number
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" color={specialCharacterColor} sx={{ display: 'flex' }}>
                        <CheckCircleOutlineIcon fontSize="small" sx={{ mr: 4 }} /> 1 special character
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" color={upperCaseColor} sx={{ display: 'flex' }}>
                        <CheckCircleOutlineIcon fontSize="small" sx={{ mr: 4 }} /> 1 uppercase letter
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" color={lowerCaseColor} sx={{ display: 'flex' }}>
                        <CheckCircleOutlineIcon fontSize="small" sx={{ mr: 4 }} /> 1 lowercase letter
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}
