import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Function to delete Manager Data from S3
 * @param {String} rowId Id of the row
 * @returns Response Object from API
 */
export const deleteManagerData = async (rowId) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        method: 'DELETE',
        withCredentials: true
    };

    const requestPath = `/managerData?rowId=${rowId}`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
