// React Imports
import { Link } from 'react-router-dom';
// MUI Imports
import { Card, Grid, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
// Component Imports
import SplitButton from 'components/ui/SplitButton';

function ProjectCard({ deleteHandler, markCompleteHandler, project, sendToAuditorHandler }) {
    //Constants
    const buttonOptions = [
        { label: 'Send to Auditors', disabled: project.auditors.length === 0, handler: sendToAuditorHandler },
        { label: 'Mark Completed', disabled: project.status !== 'Open', handler: markCompleteHandler },
        { label: 'Delete', handler: deleteHandler }
    ];
    const theme = useTheme();

    return (
        <Card>
            <Grid container>
                <Grid item xs={12}>
                    <Link to={'/'}>
                        <IconButton aria-label="open" color="primary">
                            <KeyboardArrowLeftIcon />
                            <Typography variant="body2">Home</Typography>
                        </IconButton>
                    </Link>
                </Grid>
                <Grid container item sx={{ pl: 10, pr: 10, pb: 10 }} spacing={10}>
                    <Grid container item xs={12} md={10}>
                        <Grid item xs={12} md={3}>
                            <Typography>
                                <span style={{ fontSize: '1.3rem', fontWeight: 'bold', color: theme.palette.primary.main }}>Project Name:</span>
                                <br />
                                {project.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography>
                                <span style={{ fontSize: '1.3rem', fontWeight: 'bold', color: theme.palette.primary.main }}>Org Name:</span> <br />
                                {project.orgName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography>
                                <span style={{ fontSize: '1.3rem', fontWeight: 'bold', color: theme.palette.primary.main }}>Status:</span> <br />
                                {project.status}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <SplitButton sx={{ justifyContent: 'flex-end' }} buttonOptions={buttonOptions} defaultIndex={2}></SplitButton>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
}

export default ProjectCard;
