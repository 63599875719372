import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';

import { authStore } from 'stores';

export const PublicLayout = () => {
    const location = useLocation();
    const { reportId } = useParams();
    const isPasswordlessAuth = location?.pathname.includes('/uar-report-approval') ? true : false;

    if (isPasswordlessAuth) {
        const isAuthed = authStore.getState('passwordlessSession').value.isValid;
        if (isAuthed) return <Navigate to={`/uar-report-approval/${reportId}`} />;
    } else {
        const passwordSession = authStore.getState('passwordSession').value;
        const isAuthed = passwordSession.isValid;
        if (isAuthed) return <Navigate to={`${passwordSession.sessionDetails.namespace}`} />;
    }

    return <Outlet />;
};
