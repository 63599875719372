import { fetchData } from 'services/fetchData';

import { authStore, reportStore } from 'stores';

/**
 *
 * @param {[String]} sObjects sObjects to query for the report
 * @param {[String]} fieldTypes Salesforce field types to filter for
 * @returns Array of objects containing field information
 */
export const updateAuditHistory = async (histories) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;
    const auth = reportStore.getState('currentOrg').value;
    const httpOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`
        },
        withCredentials: true,
        body: JSON.stringify({
            histories
        })
    };
    console.log(httpOptions);
    const { data } = await fetchData(`/audit/update?orgId=${auth._id}`, httpOptions);
    return data;
};
