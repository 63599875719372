// React Imports
import React from 'react';
// MUI Imports
import { styled, useTheme } from '@mui/material/styles';
import { Box, Grid, Paper, Typography } from '@mui/material';
// Component Imports

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

function ProfileAndPermissionSetAssignments({ approvalRow }) {
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <Typography color="primary" variant="body1" component="div">
                <b>Profile:</b>{' '}
                <Typography display="inline" variant="body1" color={theme.palette.text.secondary}>
                    {approvalRow.profile}
                </Typography>
            </Typography>
            <Typography color="primary" variant="body1">
                <b>Permission Sets:</b>
            </Typography>
            <Grid container spacing={8}>
                {approvalRow.permissionSets.map((name) => (
                    <Grid item key={name} xs={6} sx={{ overflowWrap: 'break-word' }}>
                        <Item>{name}</Item>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default ProfileAndPermissionSetAssignments;
