// React Imports
import React, { useState, useRef, useEffect } from 'react';
// MUI Imports
import { TextField, Box, Typography, Container, Link } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
// File Imports
import { containsOnlyNumbers } from 'utils/containsOnlyNumbers';
import { useCognito } from '../hooks/useCognito';

const FAILED_CHALLENGE_ATTEMPT_MESSAGE = 'The verification code you entered is incorrect. Please try again or request a new code.';
const FAILED_AUTH_MESSAGE = 'You have exceeded the maximum number of attempts for this verification code. Please request a new code or contact support for further assistance.';
const RESEND_CODE_MESSAGE = 'Verification code resent. Please wait 30 seconds before requesting another code.';

function CodeVerification({ codeLength, email }) {
    // States
    const [code, setCode] = useState(Array(codeLength).fill({ value: '', focused: false }));
    const [ignoreChange, setIgnoreChange] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [resendAvailable, setResendAvailable] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const inputRefs = useRef([]);
    const timeoutRef = useRef();

    const { initiatePasswordlessAuth, sendCustomChallengeAnswer } = useCognito();

    // UseEffects

    useEffect(() => {
        // Focus the first input in the code verification on initial page load.
        inputRefs.current[0].focus();

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    /**
     * Takes in a new entry for the code and updates the state.
     * @param {Object} event contains the code entry value
     * @param {Integer} index position of the code entry
     */
    const handleChange = (event, index) => {
        if (!ignoreChange) {
            const newValue = event.target.value;
            updateCode(newValue, index);
            if (newValue !== '') updateFocus(index + 1);
        } else {
            setIgnoreChange(false);
        }
    };

    /**
     * Handles the key down event on the code inputs.
     * @param {Object} event event details
     * @param {Integer} index index where the event was triggered.
     */
    const handleKeyDown = async (event, index) => {
        if (event.key === 'Backspace' && index > 0 && event.target.value === '') {
            // If on empty input, move focus back 1
            updateFocus(index - 1);
        } else if (event.key === 'Enter' && index === codeLength - 1) {
            const codeValue = code.map((entry) => entry.value).join('');
            try {
                // Attempt challenge
                setShowLoader(true);
                await submitChallenge(codeValue);
            } catch (e) {
                console.log('Challenge code error: ', e);
            }
        } else if (event.key === event.target.value) {
            // If the input is the same, move focus forward 1
            setIgnoreChange(true); // We need to set ignore change to true here because otherwise the onChange will change the following value.
            updateFocus(index + 1);
        }
    };

    /**
     * Sets the ref for each code input field.
     * @param {Object} ref reference to the element
     * @param {Integer} index index of the reference.
     */
    const handleSetRef = (ref, index) => {
        inputRefs.current[index] = ref;
    };

    /**
     * Updates the code with each new input.
     * @param {Integer} newValue new code value
     * @param {Integer} index index to update
     */
    const updateCode = (newValue, index) => {
        let newCode = [...code];
        newCode[index] = { ...newCode[index], value: newValue };
        setCode(newCode);
    };

    /**
     * Updates the current focus depending on the index provided.
     * @param {Integer} index index to switch focus to
     */
    const updateFocus = (index) => {
        if (inputRefs.current[index]) {
            inputRefs.current[index].focus();
        }
    };

    /**
     * This method handles the focus event. It selects the focused element, making it easier for the user to edit values.
     * @param {Object} event event details
     */
    const handleFocus = (event) => {
        event.target.select();
    };

    /**
     * Handles the paste event for the code input.
     * @param {*} event
     */
    const handlePaste = (event) => {
        event.preventDefault();
        setShowLoader(true);
        const rawValue = event.clipboardData.getData('Text').trim();
        const pasteValue = rawValue.split('');
        // Check if clipboard value equals code length and only contains numbers.
        if (pasteValue.length === codeLength && containsOnlyNumbers(rawValue)) {
            // Insert code
            let newCode = [...code];
            pasteValue.forEach((val, index) => {
                newCode[index] = { ...newCode[index], value: val };
            });
            setCode(newCode);
            // Set focus to last input
            inputRefs.current[code.length - 1].focus();
            // Attempt challenge
            submitChallenge(rawValue);
        }
    };

    /**
     * @description Submits the code challenge to cognito and handles the response
     * @param {String} codeValue challenge response value
     */
    const submitChallenge = async (codeValue) => {
        try {
            await sendCustomChallengeAnswer(codeValue);
        } catch (e) {
            setShowLoader(false);
            // Error responses are set in the CognitoAPI method request. Do NOT chnge these values unless you change them there too
            if (e === 'Failed Authentication') {
                setErrorText(FAILED_AUTH_MESSAGE);
            } else if (e === 'Failed Challenge Attempt') {
                setIsError(true);
                setErrorText(FAILED_CHALLENGE_ATTEMPT_MESSAGE);
            }
        }
    };

    /**
     * Sends the verification email and starts the passwordless auth session.
     * @param {String} email email of the user
     */
    const resendVerificationEmail = async () => {
        // Disable resend button to prevent the generation of multiple codes
        setResendAvailable(false);

        // Remove error messages and clear code
        if (errorText === FAILED_AUTH_MESSAGE) {
            setIsError(false);
            setErrorText('');
            setCode(Array(codeLength).fill({ value: '', focused: false }));
        }

        // Send new verification code
        await initiatePasswordlessAuth(email);

        // Set timer before user can request another code
        timeoutRef.current = setTimeout(() => {
            setResendAvailable(true);
        }, 30000);
    };

    return (
        <React.Fragment>
            <Container component="main" maxWidth="xs">
                <Box className="App" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box>
                        {code.map((codeEntry, index) => (
                            <TextField
                                sx={{ width: 50, mr: 5 }}
                                key={`"code-val-${index}`}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 1 }}
                                inputRef={(ref) => handleSetRef(ref, index)}
                                value={codeEntry.value}
                                hiddenLabel
                                variant="filled"
                                error={isError}
                                onChange={(event) => handleChange(event, index)}
                                onKeyDown={(event) => handleKeyDown(event, index)}
                                onPaste={(event) => handlePaste(event)}
                                onFocus={(event) => handleFocus(event)}
                            />
                        ))}
                        {showLoader && <LinearProgress sx={{ mt: 5, mr: 5 }} />}
                    </Box>
                    {resendAvailable ? (
                        <Box sx={{ textAlign: 'right', pt: 5, mr: 5, width: 355 }}>
                            <Link href="#" variant="body2" onClick={resendVerificationEmail}>
                                RESEND CODE
                            </Link>
                        </Box>
                    ) : (
                        <Box sx={{ textAlign: 'center', pt: 5, mr: 5, width: 355 }}>
                            <Typography variant="body2" sx={{ color: 'text.primary' }}>
                                {RESEND_CODE_MESSAGE}
                            </Typography>
                        </Box>
                    )}
                    <Box sx={{ textAlign: 'center', pt: 10, width: 355 }}>
                        <Typography variant="body2" sx={{ color: '#f44336' }}>
                            {errorText}
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </React.Fragment>
    );
}

export default CodeVerification;
