// React Imports
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// MUI Imports
import { Box, Button, IconButton, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
// API Imports
import { deleteOrg } from '../services/deleteOrg';
import { getOrgsByAccount } from '../services/getOrgsByAccount';
import { updateOrg } from '../services/updateOrg';
// Component Imports
import NoRowsAlert from 'components/ui/NoRowsAlert';
import OrgDialog from './OrgDialog';
import TableHeader from 'components/ui/TableHeader';
//Data Imports
import { alertMessages } from 'data/alerts';
import { miscStore } from 'stores';
import SalesforceLogo from 'assets/Salesforce_Logo_White.svg';

function SalesforceOrgsTable() {
    // Constants
    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1.5,
            editable: true,
            sortable: true,
            preProcessEditCellProps: (params) => {
                // Enforce at least 3 character length
                const hasError = params.props.value.length < 3;
                return { ...params.props, error: hasError };
            }
        },
        {
            field: 'instanceUrl',
            headerName: 'Instance URL',
            flex: 3,
            editable: false,
            sortable: false
        },
        {
            field: 'orgType',
            headerName: 'Org Type',
            flex: 3,
            editable: false,
            sortable: true
        },
        {
            field: 'deleteButton',
            flex: 0.5,
            headerName: '',
            renderCell: (row) => (
                // Button for deleting Salesforce orgs
                <IconButton aria-label="deleteOrg" sx={{ '&:hover': { color: 'salmon' } }} onClick={() => deleteRow(row)}>
                    <DeleteIcon />
                </IconButton>
            )
        }
    ];

    // States
    const [, , updateAlert] = miscStore.useState('alert');
    const [isLoading, setIsLoading] = useState(true);
    const [orgs, setOrgs] = useState({});
    const [open, setOpen] = useState(false);
    const isMounted = useRef(true);
    const [searchParams] = useSearchParams();

    const SALESFORCE_ORGS_TABLE_ACTIONS = [
        <Button key="addOrgButton" sx={{ whiteSpace: 'nowrap', mr: 5 }} variant="contained" onClick={() => handleAddOrgOpen()}>
            Add Org
        </Button>
    ];

    /**
     * Retrieves the Salesforce Orgs for the current user session. Runs on first render only.
     */
    useEffect(() => {
        const onFirstLoad = async () => {
            // Get Salesforce Orgs
            getOrgs();
        };
        onFirstLoad();
        return () => {
            isMounted.current = false;
        };
    }, []);

    /**
     * useEFfect to take the searchParams and create a newOrg and display notifications
     */
    useEffect(() => {
        const checkSearchParams = async () => {
            const authResult = searchParams.get('authResult');
            if (authResult === 'success') {
                refreshList();
                postAlert('success', alertMessages.success.auth);
            } else if (authResult === 'failure') {
                const errorPayload = JSON.parse(searchParams.get('errorPayload'));
                postAlert('error', errorPayload.message);
            }
        };

        isMounted.current = true;
        checkSearchParams();

        return () => {
            isMounted.current = false;
        };
    }, [searchParams]);

    // Methods
    /**
     * Deletes a Salesforce Org depending on the row clicked.
     * @param {Object} env details of the row that was clicked.
     */
    const deleteRow = async (env) => {
        await deleteOrg(env.row.id);
        postAlert('success', alertMessages.success.organization_deleted);
        refreshList();
    };

    /**
     * Sends a request to get all Salesforce Orgs for an Account.
     */
    const getOrgs = async () => {
        const _orgs = await getOrgsByAccount();
        if (isMounted.current) {
            setOrgs(
                _orgs.reduce((acc, val) => {
                    acc[val._id] = { ...val, id: val._id };
                    return acc;
                }, {})
            );
            setIsLoading(false);
        }
    };

    /**
     * Handles the closing of the Org dialog.
     */
    const handleAddOrgClose = () => {
        setOpen(false);
    };

    /**
     * Handles the opening of the Org dialog.
     */
    const handleAddOrgOpen = () => {
        setOpen(true);
    };

    /**
     * Function to close the toast notification
     */
    const handleAlertClose = () => {
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    /**
     * Handles any error during the process row update action.
     */
    const handleProcessRowUpdateError = useCallback(() => {
        // Error popup message
        postAlert('error', alertMessages.error.uar_row_update);
    }, []);

    /**
     * @description Checks if the new org would be considered a dupe
     * @returns {Boolean} duplicate flag
     */
    const isDuplicate = (name) => {
        return Object.values(orgs).filter((org) => org.name === name).length === 0 ? false : true;
    };

    /**
     * Triggers a global alert.
     * @param {String} severity severity of the alert
     * @param {String} message message to display
     */
    const postAlert = (severity, message) => {
        updateAlert((alert) => {
            alert.autoHideDuration = 3000;
            alert.message = message;
            alert.open = true;
            alert.severity = severity;
            alert.onClose = handleAlertClose;
        });
    };

    /**
     * Takes the updated row and makes a call to the database to update.
     * @param {Object} newRow updated row
     * @returns Updated document or error
     */
    const processRowUpdate = async (newRow) => {
        if (newRow.name !== orgs[newRow.id].name) {
            // Check if name is taken and that it's not blank
            if (!isDuplicate(newRow.name) && newRow.name !== '') {
                const result = await updateOrg(newRow);
                refreshList();
                postAlert('success', alertMessages.success.uar_row_update);
                return result;
            } else {
                throw Error('Invalid name.');
            }
        }
        return newRow;
    };

    /**
     * Refreshes the Salesforce Orgs displayed in the table.
     */
    const refreshList = async () => {
        getOrgs();
    };

    return (
        <React.Fragment>
            <OrgDialog open={open} onClose={handleAddOrgClose} orgs={Object.values(orgs)}></OrgDialog>
            <Paper elevation={6}>
                <TableHeader icon={<img src={SalesforceLogo} height={25} width={25} />} title="Salesforce Orgs" showActions actions={SALESFORCE_ORGS_TABLE_ACTIONS} />
                {Object.values(orgs).length > 0 && (
                    <Box sx={{ height: 400 }}>
                        <DataGrid
                            rows={Object.values(orgs)}
                            getRowId={(row) => row._id}
                            columns={columns}
                            components={{ NoRowsOverlay: () => <NoRowsAlert title="No Salesforce Orgs" message="Add a new Salesforce Org above!"></NoRowsAlert> }}
                            rowHeight={50}
                            loading={isLoading}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                            disableColumnMenu
                            experimentalFeatures={{ newEditingApi: true }}
                            processRowUpdate={processRowUpdate}
                            onProcessRowUpdateError={handleProcessRowUpdateError}
                        />
                    </Box>
                )}
            </Paper>
        </React.Fragment>
    );
}

export default SalesforceOrgsTable;
