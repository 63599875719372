import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const tagManagerArgs = {
    gtmId: process.env.GOOGLE_TAG_MANAGER_TAG
};
TagManager.initialize(tagManagerArgs);

// Disabling the eslint rule since fixing requires upgrading to React 18
// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();
