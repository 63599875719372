/* eslint-disable no-unused-vars */
// React Imports
import React, { useMemo, useState } from 'react';
// MUI Imports
import { Box, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
// Component Imports
import RenderExpandableCell from 'components/ui/RenderExpandableCell';
import ManagerApprovalsCard from './ManagerApprovalsCard';
import { approvalStore } from 'stores';
// 3rd Party Imports
import * as dayjs from 'dayjs';

const COLUMNS_TO_HIDE_ON_CARD_OPEN = { userEmail: false, active: false, lastLogin: false };

function ManagerApprovalsTable({ approvalRows, useReport, isAdminView }) {
    const [selectedApprovalRowIndex, setSelectedApprovalRowIndex] = approvalStore.useState('selectedApprovalRowIndex');

    const selectedRow = useMemo(() => approvalRows[selectedApprovalRowIndex], [approvalRows, selectedApprovalRowIndex]);

    const baseColumns = [
        {
            field: 'name',
            flex: 1,
            headerName: 'Employee Name',
            renderCell: (cellValues) => {
                return <RenderExpandableCell {...cellValues} />;
            }
        },
        {
            field: 'userEmail',
            flex: 1,
            headerName: 'Employee Email',
            renderCell: (cellValues) => {
                return <RenderExpandableCell {...cellValues} />;
            }
        },
        {
            field: 'active',
            flex: 1,
            headerName: 'Active',
            renderCell: (cellValues) => (cellValues.value ? <CheckIcon sx={{ color: 'text.success' }} /> : <CloseIcon sx={{ color: 'text.error' }} />)
        },
        {
            field: 'lastLogin',
            flex: 1,
            headerName: 'Last Login',
            renderCell: (cellValues) => {
                cellValues.value = cellValues.value ? `${dayjs(cellValues.value).format('h:mm A')} on ${dayjs(cellValues.value).format('MM/DD/YYYY')}` : 'No Login History';
                return <RenderExpandableCell {...cellValues} />;
            }
        },
        {
            field: 'status',
            flex: 1,
            headerName: 'Status',
            renderCell: (cellValues) => {
                return <RenderExpandableCell {...cellValues} />;
            }
        }
    ];

    const columns = useMemo(() => {
        if (isAdminView) {
            const managerColumns = [
                {
                    field: 'managerName',
                    flex: 1,
                    headerName: 'Manager Name',
                    renderCell: (cellValues) => {
                        return <RenderExpandableCell {...cellValues} />;
                    }
                },
                {
                    field: 'managerEmail',
                    flex: 1,
                    headerName: 'Manager Email',
                    renderCell: (cellValues) => {
                        return <RenderExpandableCell {...cellValues} />;
                    }
                }
            ];
            return [...managerColumns, ...baseColumns];
        } else {
            return baseColumns;
        }
    }, [isAdminView]);

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

    /**
     * When a row is selected, display a card with additional details
     * @param {Object} params contains details for the selected row
     */
    const handleRowSelection = (params) => {
        const row = params.row;

        if (row._id === selectedRow?._id) {
            // Shrink the remediation task card if the same row is clicked again.
            setSelectedApprovalRowIndex(null);

            // Show all columns when card view is closed.
            setColumnVisibilityModel({});
        } else {
            setSelectedApprovalRowIndex(approvalRows.findIndex((approvalRow) => approvalRow._id === row._id));

            // Hide columns to provide more space for card
            setColumnVisibilityModel(COLUMNS_TO_HIDE_ON_CARD_OPEN);
        }
    };

    return (
        <Paper elevation={6} sx={{ display: 'flex', height: '100%' }}>
            <Box
                sx={{
                    flex: selectedRow ? '0 1 30%' : '1 1 100%',
                    transition: 'flex 0.5s ease-in-out',
                    overflow: 'hidden'
                }}
            >
                <DataGrid columns={columns} columnVisibilityModel={columnVisibilityModel} getRowId={(row) => row._id} rows={approvalRows} rowHeight={50} onRowClick={handleRowSelection} />
            </Box>
            <Box
                sx={{
                    flex: selectedRow ? '0 1 70%' : '0 0 0%',
                    transition: 'flex 0.5s ease-in-out',
                    overflow: 'hidden',
                    transform: selectedRow ? 'translateX(0)' : 'translateX(100%)'
                }}
            >
                {selectedRow && <ManagerApprovalsCard key={selectedRow._id} approvalRow={selectedRow} useReport={useReport}></ManagerApprovalsCard>}
            </Box>
        </Paper>
    );
}

export default ManagerApprovalsTable;
