// React Imports
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// MUI Imports
import { Avatar, Button, Box, Checkbox, CircularProgress, Container, CssBaseline, FormControlLabel, Grid, Link, Typography, TextField } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// API Imports

import { authStore, miscStore } from 'stores';
import { useCognito } from 'features/auth';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="http://www.nemos.io/">
                Nemos
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function Login() {
    // Constants
    const navigate = useNavigate();
    const params = useParams();
    const paramsRaw = new URLSearchParams(window.location.search);
    const theme = useTheme();
    const isMounted = useRef(true);

    //States
    const [isLoading, setIsLoading] = useState(true);
    const [isSigningIn, setIsSigningIn] = useState(false);
    const [passwordFilled, setPasswordFilled] = useState(false);
    const [redirectPath, setRedirectPath] = miscStore.useState('redirectPath');
    const [rememberMe] = useState(localStorage.getItem('nemosUserName') !== null);
    const [signInErrorMessage, setSignInErrorMessage] = useState('');
    const [signInError, setSignInError] = useState(false);

    const { confirmRegistration, signIn, verifyEmail } = useCognito();

    // Effects

    /**
     * Newly Signed-Up users are redirected to the this page with a confirmation code. Alternatively,
     * users who updated their email are redirected here with a verification code when thet click the link
     * emailed to them, then fires a request to cognito to verify the users email.
     */
    useEffect(async () => {
        const userName = paramsRaw.get('user_name');
        const confirmationCode = paramsRaw.get('confirmation_code');
        const verificationCode = paramsRaw.get('verification_code');
        if (confirmationCode) {
            try {
                await confirmRegistration(userName, confirmationCode);
            } catch (err) {
                if (err.code === 'CodeMismatchException' || err.code === 'ExpiredCodeException') {
                    navigate('/expired-email-verification', { replace: true, state: { email: userName } });
                }
                if (isMounted.current) {
                    setSignInError(true);
                    setSignInErrorMessage(err.message);
                }
            } finally {
                if (isMounted.current) setIsLoading(false);
            }
        } else if (verificationCode) {
            verifyEmail(verificationCode);
        }
        if (isMounted.current) setIsLoading(false);

        return () => {
            isMounted.current = false;
        };
    }, []);

    // Local Methods

    const navigateToHome = () => {
        navigate('/', { replace: true });
    };

    const navigateToSignUp = () => {
        navigate('/sign-up', { replace: true });
    };

    const navigateToForgotPassword = () => {
        navigate('/forgot-password', { replace: true });
    };

    /**
     * Handles the Sign In Event
     * @param {Object} event submit event
     */
    const handleSignIn = async (event) => {
        event.preventDefault();
        setIsSigningIn(true);
        const data = new FormData(event.currentTarget);
        const rememberDevice = data.get('rememberMe') === 'on' ? true : false;
        try {
            // Ternary operator check if they're logging in via namespace or not.
            !params?.namespace
                ? await signIn(data.get('userName'), data.get('password'), rememberDevice)
                : await signIn(data.get('userName') + '.' + params?.namespace, data.get('password'), rememberDevice);
            // Manage local storage for Remember Me option
            if (rememberDevice) {
                localStorage.setItem('nemosUserName', data.get('userName'));
            } else {
                localStorage.removeItem('nemosUserName');
            }

            if (redirectPath) {
                // Copy path
                const path = redirectPath;

                // Reset redirect path after logging in
                setRedirectPath(null);
                authStore.setState('sessionError', false);

                navigate(path, { replace: true });
            } else {
                navigateToHome();
            }
        } catch (err) {
            if (err.code === 'UserNotConfirmedException') {
                err.message = (
                    <span>
                        Your email address has not been verified. Please check your email for the verification link. If you need a new verification link, you can request one&nbsp;
                        <Link
                            component="button"
                            variant="body2"
                            onClick={() => {
                                navigate('/expired-email-verification', { replace: true, state: { email: data.get('userName') } });
                            }}
                        >
                            here
                        </Link>
                        .
                    </span>
                );
            }
            setIsSigningIn(false);
            setSignInError(true);
            setSignInErrorMessage(err.message);
        }
    };

    const handlePasswordChange = (event) => {
        event.target.value === '' ? setPasswordFilled(false) : setPasswordFilled(true);
    };

    return (
        <>
            {isLoading ? (
                <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignSelf: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <AccountCircleIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box component="form" onSubmit={handleSignIn} noValidate sx={{ mt: 1 }}>
                            {signInError && (
                                <Typography variant="body2" color={theme.palette.text.error} textAlign="center" fontWeight="bold">
                                    {signInErrorMessage}
                                </Typography>
                            )}
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="userName"
                                label="Username"
                                name="userName"
                                autoComplete="user-name"
                                defaultValue={localStorage.getItem('nemosUserName')}
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                onChange={handlePasswordChange}
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <FormControlLabel control={<Checkbox id="rememberMe" name="rememberMe" color="primary" defaultChecked={rememberMe} />} label="Remember me" />
                            {isSigningIn ? (
                                <LinearProgress></LinearProgress>
                            ) : (
                                <Button type="submit" fullWidth disabled={!passwordFilled} variant="contained" sx={{ mt: 3, mb: 2 }}>
                                    Sign In
                                </Button>
                            )}
                            <Grid container>
                                <Grid item xs>
                                    <Link onClick={navigateToForgotPassword} component="button" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link onClick={navigateToSignUp} component="button" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Copyright sx={{ mt: 8, mb: 4 }} />
                </Container>
            )}
        </>
    );
}

export default Login;
