export const alertMessages = {
    error: {
        approval_closed: 'Change not saved. Report is passed the due date.',
        approval_status: 'Unable to change approval status.',
        auditor_account_email: "Auditor Email can't be main Account's Email",
        auditor_deleted: 'Error Deleting Auditor',
        auditor_duplicate: 'Duplicate Auditor',
        audit_history_sync: 'Error Syncing Audit History Data',
        audit_history_save: 'Error Saving Audit History',
        auth: 'Authentication Failed',
        auth_mismatch: 'Authentication Mismatch',
        csv_limit: 'File Size Limit Exceeded',
        csv_type: 'File Must Be Proper Type',
        csv_upload: 'Upload Failed',
        email_diff: 'Error Emailing Auditors',
        email_format: 'Email Improperly Formatted',
        expired_password_link: 'Reset Password Link is no longer valid. Request a new link to reset your password',
        picture_uploaded: 'Error Uploading Picture',
        profile_updated: 'Error Updating Profile',
        project_created: 'Error Creating Project',
        project_deleted: 'Error Deleting Project',
        project_get: 'Error Retrieving Project',
        project_updated: 'Error Updating Project',
        saved: 'Error Saving',
        send_diff: 'Send Diff Report Failed',
        sign_up: 'Error Signing Up',
        uar_report_approvals_closed: 'Error Closing Approvals',
        uar_report_deleted: 'Error Deleting UAR Report',
        uar_report_due_date_changed: 'Error Changing Due Date',
        uar_report_generated: 'Error Creating UAR Report',
        uar_report_sent: 'Error Sending Report',
        uar_report_updated: 'Error Updating Report',
        uar_row_update: 'Unable to update row'
    },
    success: {
        approval_status: 'Approval status successfully updated.',
        auditor_created: 'Auditor Successfully Added',
        auditor_deleted: 'Auditor Successfully Deleted',
        audit_history_save: 'Audit History Successfully Updated',
        auth: 'Successful Authentication',
        csv_upload: 'Upload Successful',
        email_diff: 'Diff Email Successfully Sent',
        email_verification_sent: 'Email sent successfully!',
        organization_deleted: 'Organization Deleted',
        picture_uploaded: 'Picture Uploaded Successfully',
        profile_updated: 'Successfully updated profile information!',
        project_created: 'Project Created Successfully',
        project_deleted: 'Project Deleted',
        project_updated: 'Project Updated',
        saved: 'Successfully Saved',
        send_diff: 'Successfully Sent Diff Report',
        uar_report_approvals_closed: 'Successfully Closed Approvals',
        uar_report_deleted: 'UAR Report Deleted',
        uar_report_due_date_changed: 'Due Date Successfully Changed',
        uar_report_generated: 'UAR Report Successfully Created',
        uar_report_sent: 'Report Successfully Sent',
        uar_report_updated: 'Successfully Updated Report',
        uar_row_update: 'Row successfully saved'
    }
};

export const alertSettings = {
    autoHideDuration: 3000,
    horizontal: 'center',
    message: '',
    open: false,
    onClose: null,
    severity: 'success',
    variant: 'filled',
    vertical: 'top',
    width: '100%'
};
