import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';

export default function ManagerInfo({ rejectedApproval }) {
    const theme = useTheme();
    return (
        <Stack sx={{ textAlign: 'left', flex: 1, pl: 8 }} spacing={3}>
            <Typography color="primary" variant="body1" component="div">
                <b>Manager Information</b>
            </Typography>
            <Typography color="primary" variant="body1" component="div">
                <b>Name:</b>{' '}
                <Typography display="inline" variant="body1" color={theme.palette.text.secondary}>
                    {rejectedApproval.managerName}
                </Typography>
            </Typography>
            <Typography color="primary" variant="body1" component="div">
                <b>Role:</b>{' '}
                <Typography display="inline" variant="body1" color={theme.palette.text.secondary}>
                    {rejectedApproval.managerRole}
                </Typography>
            </Typography>
            <Typography color="primary" variant="body1" component="div">
                <b>Email:</b>{' '}
                <Typography display="inline" variant="body1" color={theme.palette.text.secondary}>
                    {rejectedApproval.managerEmail}
                </Typography>
            </Typography>
        </Stack>
    );
}
