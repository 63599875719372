import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';

import { useCognito } from '../hooks/useCognito';
import { authStore, miscStore } from 'stores';
import { alertMessages } from 'data/alerts';

const RESEND_EMAIL_BUTTON_TEXT = 'Resend Email';

function ResendEmailVerificationButton() {
    const { resendConfirmationCode } = useCognito();
    const { state } = useLocation();
    const currentTheme = useTheme();

    const [, , updateAlert] = miscStore.useState('alert');
    const cooldownPeriod = 30000; // 5 minutes in milliseconds
    const [isDisabled, setIsDisabled] = useState();
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [lastEmailSentTime, setLastEmailSentTime] = authStore.useState('lastEmailVerificationSentTime');
    const [resendEmailError, setResendEmailError] = useState();
    const [timeRemaining, setTimeRemaining] = useState();

    /**
     * If an email has been sent, it calculates the remaning time cooldown time
     * for the button to be available to use again.
     */
    useEffect(() => {
        let interval;
        if (lastEmailSentTime) {
            const calculateTimeRemaining = () => {
                const currentTime = new Date().getTime();
                const timeElapsed = currentTime - lastEmailSentTime;
                const timeLeft = cooldownPeriod - timeElapsed;

                setTimeRemaining(timeLeft);
                setIsDisabled(true);

                if (timeLeft <= 0) {
                    clearInterval(interval);
                    setTimeRemaining(cooldownPeriod);
                    setIsDisabled(false);
                    setLastEmailSentTime(null);
                }
            };

            calculateTimeRemaining();
            interval = setInterval(calculateTimeRemaining, 1000); // Update every second
        }

        return () => clearInterval(interval);
    }, [lastEmailSentTime]);

    /**
     * Handels the resend email event
     */
    const handleResendEmail = async () => {
        setIsSendingEmail(true);
        try {
            await resendConfirmationCode(state.email);
            updateAlert((alert) => {
                alert.autoHideDuration = 3000;
                alert.message = alertMessages.success.email_verification_sent;
                alert.open = true;
                alert.severity = 'success';
                alert.onClose = handleAlertClose;
            });
            setIsDisabled(true);
            const currentTime = new Date().getTime();
            setLastEmailSentTime(currentTime);
        } catch (err) {
            setIsSendingEmail(false);
            setResendEmailError(err);
        } finally {
            setIsSendingEmail(false);
        }
    };

    /**
     * Calculates the time remaining for the disabled resend email button.
     * @returns the text for the disabled resend email button
     */
    const formatTimeRemaining = () => {
        const minutes = Math.floor(timeRemaining / 60000);
        const seconds = ((timeRemaining % 60000) / 1000).toFixed(0);
        return `Resend in ${minutes}:${seconds.padStart(2, '0')} minutes`;
    };

    /**
     * Function that Handles the close event from the Error Alert
     */
    const handleAlertClose = () => {
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    return (
        <>
            {resendEmailError ? (
                <Typography sx={{ pt: 12, pb: 12, textAlign: 'center', color: currentTheme.palette.text.error, fontWeight: 'bold' }} variant="body1">
                    {resendEmailError}
                </Typography>
            ) : (
                <LoadingButton disabled={isDisabled} loading={isSendingEmail} variant="contained" sx={{ width: 'fit-content', alignSelf: 'center', mt: 12 }} onClick={handleResendEmail}>
                    {isDisabled ? formatTimeRemaining() : RESEND_EMAIL_BUTTON_TEXT}
                </LoadingButton>
            )}
        </>
    );
}

export default ResendEmailVerificationButton;
