// Render the component with Material-UI Card, Typography, and optional Link elements// React Imports
import React from 'react';
import { Link } from 'react-router-dom';
// MUI Imports
import { Typography, Grid, Card, CardContent, Box, Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import VerifiedUser from '@mui/icons-material/VerifiedUser';

function StatCard(props) {
    /**
     * Renders a card with statistics.
     * @param {object} props - Properties passed to the component.
     * @param {string} props.title - The title of the card.
     * @param {string} props.stat - The statistic to display.
     * @param {string} props.navEnabled - Determines if the card is navigable.
     * @param {string} [props.link] - The URL to navigate to when the card is clicked, required if 'navEnabled' is 'true'.
     * @param {string} props.color - The color of the statistic text.
     * @returns {React.ReactElement} The StatCard component.
     */
    // Constants
    // Initializes material-ui theme and media query for responsiveness
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { title, stat, navEnabled, link, color } = props; // Destructures the required props for the component

    return (
        <Paper elevation={6}>
            <Card>
                <CardContent>
                    <Grid container sx={{ justifyContent: 'center' }}>
                        <Grid item xs={10} sx={{ textAlign: { xs: 'center', sm: 'start' } }}>
                            {navEnabled === 'true' ? (
                                <Typography
                                    component={Link}
                                    to={link}
                                    sx={{ '&:hover': { color: theme.palette.primary.main, textDecoration: 'underline' }, textDecoration: 'none', color: theme.palette.text.primary }}
                                    variant="body1"
                                    gutterBottom
                                >
                                    {title}
                                </Typography>
                            ) : (
                                <Typography variant="body1">{title}</Typography>
                            )}
                            <Typography variant="h2" component="div" color={color}>
                                {stat}
                            </Typography>
                        </Grid>
                        {!isSmallScreen && (
                            <Grid item xs={2}>
                                <Box>
                                    <VerifiedUser fontSize="large" sx={{ color: { color } }}></VerifiedUser>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </Card>
        </Paper>
    );
}

export default StatCard;
