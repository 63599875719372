import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Downloads a Manager Data CSV
 * @param {String} rowId Id of the row
 * @returns Resoonse Object from API
 */
export const downloadManagerData = async (rowId) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        method: 'GET',
        withCredentials: true
    };

    const requestPath = `/managerData/download?rowId=${rowId}`;
    const { response } = await fetchData(requestPath, httpOptions);
    return await response.blob();
};
