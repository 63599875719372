// React Imports
import React, { useState } from 'react';
// MUI Imports
import { Box, Tabs, Tab } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * Panel component that contains the body of each Tab
 * @param {Object} props all of the properties needed for building the TabPanel.
 * @returns html for the TabPanel component.
 */
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box sx={{ flex: 1, display: value === index ? 'flex' : 'none' }} role="tabpanel" id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ flex: 1, p: 3, boxSizing: 'border-box' }}>{children}</Box>}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

/**
 * TabSet is a functional component that renders a set of tabs containing different content.
 * @param {Array} tabs - Require array of the tabs to render.
 * @returns React Element
 */
function TabSet({ tabs }) {
    const [value, setValue] = useState(0);

    /**
     * Props to inject into each Tab element.
     * @param {Integer} index
     * @returns Object of the different properties to inject
     */
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }

    /**
     * Handles the change in tabs
     * @param {Object} event event object
     * @param {Integer} newValue new tab value
     */
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="Tabs">
                    {tabs.map((tab, index) => (
                        <Tab key={tab.key} label={tab.label} {...a11yProps(index)} />
                    ))}
                </Tabs>
            </Box>
            {tabs.map((tab, index) => (
                <TabPanel key={tab.key} value={value} index={index}>
                    {tab.content}
                </TabPanel>
            ))}
        </Box>
    );
}

export default TabSet;
