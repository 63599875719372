import { CognitoUserPool } from 'amazon-cognito-identity-js';

// Cognito User Pool info
const USER_POOL_ID = process.env.REACT_APP_COGNITO_USERPOOLID;
const CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENTID;
const poolData = {
    UserPoolId: `${USER_POOL_ID}`,
    ClientId: `${CLIENT_ID}`
};
export const userPool = new CognitoUserPool(poolData);

// Passwordless Auth Info
const USER_POOL_ID_PASSWORDLESS = process.env.REACT_APP_COGNITO_USERPOOLID_PASSWORDLESS;
const CLIENT_ID_PASSWORDLESS = process.env.REACT_APP_COGNITO_CLIENTID_PASSWORDLESS;
const poolDataPasswordless = {
    UserPoolId: `${USER_POOL_ID_PASSWORDLESS}`,
    ClientId: `${CLIENT_ID_PASSWORDLESS}`
};
export const userPoolPasswordless = new CognitoUserPool(poolDataPasswordless);
