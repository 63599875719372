import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Sends a report to the selected managers
 * @param {Object} emails array of emails
 * @param {String} reportId Id of the report to send.
 * @param {Date} dueDate due date of the report.
 * @param {String} adminEmail email address for the account owner.

 * @returns
 */
export const sendReportsToManagers = async (emails, reportId, dueDate, adminEmail) => {
    console.log(authStore.getState('passwordSession').value.sessionDetails);
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    // Send email to Managers.
    const body = {
        emails: emails,
        reportId: reportId,
        dueDate: dueDate,
        adminEmail: adminEmail
    };
    const httpOptions = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        body: JSON.stringify(body)
    };

    const requestPath = `/uarReport/email`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
