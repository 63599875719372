import React, { useEffect, useRef } from 'react';

import { Button, Box, Typography, Card, CardHeader, CardContent, CardActions, Grid } from '@mui/material';
import { Divider, makeStyles } from '@material-ui/core';

import { newAccount } from '../services/newAccount';

import { billingStore } from 'stores';

const useStyles = makeStyles((theme) => ({
    box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        borderRadius: 12,
        minWidth: 256,
        maxWidth: '50vw',
        textAlign: 'center',
        margin: 10
    },
    header: {
        textAlign: 'center',
        spacing: 10
    },
    header_highlight: {
        textAlign: 'center',
        spacing: 10,
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.common.white
    },
    list: {
        padding: '20px'
    },
    button: {
        margin: theme.spacing(1)
    },
    action: {
        display: 'flex',
        justifyContent: 'space-around'
    }
}));

function PricingCards() {
    const classes = useStyles();
    const [cognito] = billingStore.useState('cognito');
    const componentIsMounted = useRef(true);
    useEffect(() => {
        return () => {
            componentIsMounted.current = false;
        };
    }, []);
    useEffect(() => {
        const getData = async () => {};

        if (componentIsMounted.current) {
            getData();
        }
    }, []);

    const openUrl = async () => {
        newAccount(cognito.sub);
    };

    return (
        <Box className={classes.box}>
            <Grid container justifyContent="center">
                <Grid item>
                    <Card className={classes.root}>
                        <CardHeader title="Trial" className={classes.header} />
                        <Divider variant="middle" />
                        <CardContent>
                            <Typography variant="h4" align="center">
                                $0.00
                            </Typography>
                            <div className={classes.list}>
                                <Typography align="center">Key Permission Identification</Typography>
                                <Typography align="center">User Specific Access Reporting</Typography>
                                <Typography align="center">Export to Excel</Typography>
                            </div>
                        </CardContent>
                        <Divider variant="middle" />
                        <CardActions className={classes.action}>
                            <Button variant="contained" color="primary" className={classes.button} disabled>
                                Selected
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item>
                    <Card className={classes.root}>
                        <CardHeader title="Paid Plan" className={classes.header_highlight} />
                        <Divider variant="middle" />
                        <CardContent>
                            <Typography variant="h4" align="center">
                                $1000.00/yr
                            </Typography>
                            <div className={classes.list}>
                                <Typography align="center">Key Permission Identification</Typography>
                                <Typography align="center">User Specific Access Reporting</Typography>
                                <Typography align="center">Export to Excel</Typography>
                            </div>
                        </CardContent>
                        <Divider variant="middle" />
                        <CardActions className={classes.action}>
                            <Button variant="contained" color="primary" className={classes.button} onClick={openUrl}>
                                Buy
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}

export default PricingCards;
