import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to retrieve all Reports for a Project.
 * @param {String} projId Id of the Project to retrieve reports for.
 * @returns array of reports
 */
export const getUARReportsByProject = async (projId, filterCriteria = {}) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        method: 'GET',
        withCredentials: true
    };

    const requestPath = `/uarReport/byProj?projId=${projId}&filterCriteria=${encodeURIComponent(JSON.stringify(filterCriteria))}`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
