// React Imports
import React, { useRef, useState } from 'react';
// MUI Imports
import { Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

/**
 * SplitButton component providing a dropdown button group with options.
 * @param {Object[]} buttonOptions - An array of objects which hold label, handler, disabled and hidden properties for each button option.
 * @param {number} defaultIndex - The index of the default button option.
 */
export default function SplitButton({ buttonOptions, defaultIndex }) {
    //States
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(defaultIndex);

    //Functions
    /**
     * Function to handle the menuitem click
     */
    /**
     * Handles the click event for the select button.
     * Calls the handler function from the currently selected button option.
     */
    const handleClick = () => {
        const index = buttonOptions[selectedIndex].hidden ? defaultIndex : selectedIndex;
        buttonOptions[index].handler();
    };

    /**
     * Function to handle the close of the menu
     * @param {Object} event focus off event
     */
    /**
     * Handles the event when focus is moved away from button group.
     * Closes the open menu if the focus is not within the component.
     * @param {Object} event - The blur event object.
     */
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    /**
     * Function to handle a menu item selection
     * @param {Number} index Index of the item that was selected (zero-based index)
     */
    /**
     * Handles the selection of a menu item.
     * Updates the selected index and closes the menu.
     * @param {number} index - The index of the selected item.
     */
    const handleMenuItemClick = (index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    /**
     * Function that handles the click event of the menu button
     */
    /**
     * Toggles the visibility of the menu.
     */
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    return (
        <React.Fragment>
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                <Button sx={{ width: 'fit-content' }} onClick={handleClick}>
                    {buttonOptions[selectedIndex].hidden ? buttonOptions[defaultIndex].label : buttonOptions[selectedIndex].label}
                </Button>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 2
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {buttonOptions.map((option, index) => (
                                        <MenuItem
                                            sx={{ display: option.hidden ? 'none' : 'block' }}
                                            key={option.label}
                                            disabled={option.disabled}
                                            selected={index === selectedIndex}
                                            onClick={() => handleMenuItemClick(index)}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
}
