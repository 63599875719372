import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to delete a UAR Report.
 * @param {String} uarReportId Id of the report to delete.
 * @returns the delete UAR Report.
 */
export const apiDeleteReport = async (uarReportId) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        method: 'DELETE',
        withCredentials: true
    };

    const requestPath = `/uarReport?id=${uarReportId}`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
