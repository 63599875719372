// React Imports
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// MUI Imports
import { Container, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';

// Components
import { ResendEmailVerificationButton } from 'features/auth';
import InfoPaper from 'components/ui/InfoPaper';

import { urls } from 'data/constants';

function ExpiredEmailVerification() {
    // Constants
    const currentTheme = useTheme();
    const { state } = useLocation();

    return (
        <>
            {state?.email ? (
                <Container maxWidth="sm" sx={{ pt: 80 }}>
                    <InfoPaper
                        bodyText={
                            <span>
                                Sorry for the inconvenience, but it looks like the verification link you&apos;ve clicked has expired. This can happen if the link is older than 24 hours or a newer one
                                has been sent. Don&apos;t worry, though – getting you set up is still a breeze!
                            </span>
                        }
                        footerButton={<ResendEmailVerificationButton />}
                        footerText={
                            <span>
                                Need a hand? Our customer support is <Link href={urls.prod.customerSupport}>here</Link> to assist you every step of the way!
                            </span>
                        }
                        headerIcon={<EmailIcon sx={{ alignSelf: 'center', fontSize: 80, color: currentTheme.palette.primary.main }} />}
                        headerText={'Oops! This Verification Link Has Expired'}
                    />
                </Container>
            ) : (
                <Navigate to="/sign-up" />
            )}
        </>
    );
}

export default ExpiredEmailVerification;
