import { Box, Typography } from '@mui/material';

/**
 * NoRowsAlert component displays an alert message when there are no rows to display on the screen.
 *
 * @param {string} title - The title of the alert to display.
 * @param {string} message - The detailed message of the alert.
 * @param {string|number} width - The width of the alert box, can be a percentage or pixels.
 * @returns {JSX.Element} - A Material-UI Box component with alert content.
 */
function NoRowsAlert({ title, message, width }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width, gap: 8, textAlign: 'center' }}>
            <Typography variant="h5" sx={{ pt: 10 }}>
                {title}
            </Typography>
            <Typography>{message}</Typography>
        </Box>
    );
}

export default NoRowsAlert;
