import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Function that gets all Manager Data for an Account from S3
 * @returns Resoonse Object from API
 */
export const getManagerDataByAccId = async () => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        method: 'GET',
        withCredentials: true
    };

    const requestPath = `/managerData/byAccId`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
