import AccountManagement from 'pages/AccountManagement';
import DefaultKeyPermissions from 'pages/DefaultKeyPermissions';
import Home from 'pages/Home';
import ManagerData from 'pages/ManagerData';
import PersonalProfile from 'pages/PersonalProfile';
import Project from 'pages/Project';
import ProjectHistory from 'pages/ProjectHistory';
import RemediationTasks from 'pages/RemediationTasks';
import SalesforceOrgs from 'pages/SalesforceOrgs';
import SalesforceScanner from 'pages/SalesforceScanner';
import StripeCallback from 'pages/StripeCallback';
import UARProcess from 'pages/UARProcess';
import UARReport from 'pages/UARReport';
import UARReportApproval from 'pages/UARReportApproval';
import AuditHistoryManagement from 'pages/AuditHistoryManagement';

import { ProtectedLayout } from 'layouts/ProtectedLayout';
import { Navigate } from 'react-router-dom';

export const getProtectedRoutes = (namespace) => [
    {
        path: '/',
        element: <ProtectedLayout />,
        children: [
            {
                index: true,
                element: <Navigate to={namespace} />
            },
            {
                path: namespace,
                children: [
                    {
                        index: true,
                        element: <Home />
                    },
                    {
                        path: 'project/:id',
                        element: <Project />
                    },
                    {
                        path: 'project/:id/uar-process',
                        element: <UARProcess />
                    },
                    {
                        path: 'project/:id/uar-report/:reportId',
                        element: <UARReport />
                    },
                    {
                        path: 'project-history',
                        element: <ProjectHistory />
                    },
                    {
                        path: 'project/:id/uar-report/:reportId/remediation-tasks',
                        element: <RemediationTasks />
                    },
                    {
                        path: 'salesforce-scanner',
                        element: <SalesforceScanner />
                    },
                    {
                        path: 'settings/personal-profile',
                        element: <PersonalProfile />
                    },
                    {
                        path: 'settings/salesforce-orgs',
                        element: <SalesforceOrgs />
                    },
                    {
                        path: 'settings/manager-data',
                        element: <ManagerData />
                    },
                    {
                        path: 'settings/billing',
                        element: <AccountManagement />
                    },
                    {
                        path: 'settings/key-permissions',
                        element: <DefaultKeyPermissions />
                    },
                    {
                        path: 'billing/:status',
                        element: <StripeCallback />
                    },
                    {
                        path: 'billing',
                        element: <AccountManagement />
                    },
                    {
                        path: 'audit',
                        element: <AuditHistoryManagement />
                    }
                ]
            },
            { path: 'uar-report-approval/:reportId', element: <UARReportApproval /> }
        ]
    }
];
