// React Imports
import React, { useCallback, useState } from 'react';
// MUI Imports
import { LoadingButton } from '@mui/lab';
import { Box, ButtonGroup, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
// Component Imports
//import LoadingIndicator from 'components/ui/LoadingIndicator';
import NoRowsAlert from 'components/ui/NoRowsAlert';
import { miscStore, reportStore } from 'stores';
// API Imports
import { useSalesforceData } from 'features/salesforce';
import { OrgCombobox } from 'features/organizations';
import { getAuditHistory, updateAuditHistory } from '../features/auditHistory';
import { alertMessages } from 'data/alerts';

function AuditHistoryManagement() {
    const theme = useTheme();

    const salesforceRequests = useCallback(() => [getAuditHistory()], []);
    const { data, refresh } = useSalesforceData(salesforceRequests, null, false, true);

    const [auditHistory, setAuditHistory] = useState(data);
    const [hasBeenChanged, setHasBeenChanged] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const [, , updateAlert] = miscStore.useState('alert');

    const isValidOrg = Object.keys(reportStore.getState('currentOrg').value).length > 0;

    const handleUpdate = (row) => {
        const index = auditHistory.findIndex((element) => element._id === row._id);
        const newRows = [...auditHistory];
        newRows[index] = row;
        setAuditHistory(newRows);
        setHasBeenChanged(true);
        return row;
    };

    const handleAlertClose = () => {
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    const postAlert = (severity, message) => {
        updateAlert((alert) => {
            alert.autoHideDuration = 3000;
            alert.message = message;
            alert.open = true;
            alert.severity = severity;
            alert.onClose = handleAlertClose;
        });
    };

    const handleProcessRowUpdateError = useCallback(() => {
        // Error popup message
        postAlert('error', alertMessages.error.audit_history_sync);
    }, []);

    const handleSave = async () => {
        try {
            setIsLoading(true);
            await updateAuditHistory(auditHistory);

            setHasBeenChanged(false);
            postAlert('success', alertMessages.success.audit_history_save);
            refresh();
        } catch (err) {
            setIsLoading(false);
            postAlert('error', alertMessages.error.audit_history_save);
        }
    };

    return (
        <Box sx={{ p: 10 }} width={'-webkit-fill-available'}>
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 10 }}>
                <Stack direction="row" justifyContent={isValidOrg ? 'space-between' : 'flex-end'} alignItems="center" spacing={2} width={'100%'}>
                    {isValidOrg && (
                        <ButtonGroup variant="contained">
                            <LoadingButton loading={loading} onClick={refresh} variant={'contained'}>
                                Sync
                            </LoadingButton>
                            {hasBeenChanged && (
                                <LoadingButton loading={loading} onClick={handleSave} variant={'contained'}>
                                    Save
                                </LoadingButton>
                            )}
                        </ButtonGroup>
                    )}
                    <Box>
                        <OrgCombobox />
                    </Box>
                </Stack>
            </Box>
            <React.Fragment>
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 10 }}>
                    {isValidOrg && (
                        <DataGrid
                            sx={{
                                '& .MuiDataGrid-columnHeader': {
                                    backgroundColor: theme.palette.mode === 'light' ? '#f3b040' : 'f7f7f8'
                                }
                            }}
                            autoHeight
                            rows={auditHistory}
                            columns={[
                                {
                                    field: 'createdDate',
                                    headerName: 'Date',
                                    flex: 1,
                                    valueFormatter: (val) => {
                                        return dayjs(val.value).format('MM/DD/YYYY');
                                    }
                                },
                                { field: 'action', headerName: 'Action', flex: 2 },
                                { field: 'createdBy', headerName: 'User', flex: 1 },
                                { field: 'display', headerName: 'Display', flex: 3 },
                                //{ field: 'section', headerName: 'Section', flex: 1 },
                                {
                                    field: 'caseId',
                                    headerName: 'Issue Link',
                                    flex: 1,
                                    editable: true,
                                    valueFormatter: (val) => {
                                        return !val.value?.length ? 'Open' : 'Linked';
                                    }
                                }
                            ]}
                            components={{ NoRowsOverlay: () => <NoRowsAlert title="No Audit History" message="There is no existing audit history for this Salesforce org."></NoRowsAlert> }}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'createdDate', sort: 'desc' }]
                                }
                            }}
                            disableSelectionOnClick
                            processRowUpdate={(row) => handleUpdate(row)}
                            onProcessRowUpdateError={handleProcessRowUpdateError}
                            experimentalFeatures={{ newEditingApi: true }}
                            getRowId={(row) => {
                                //console.log(row);
                                return row.recordId;
                            }}
                        />
                    )}
                </Box>
            </React.Fragment>
        </Box>
    );
}

export default AuditHistoryManagement;
