// React Imports
import React, { useState } from 'react';
// MUI Imports
import { useTheme } from '@mui/material/styles';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Stack, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// Libraries
import * as dayjs from 'dayjs';

/**
 *
 * @param {Function} handleNeg function that handles the negative action
 * @param {Function} handlePos function that handles the positive action
 * @param {Boolean} open designates whether the component is open or not
 * @returns
 */
function DueDateEditorDialog({ handleNeg, handlePos, open, title }) {
    const [dueDate, setDueDate] = useState(null);
    const [resendEmails, setResendEmails] = useState(false);
    const theme = useTheme();

    /**
     * Handles the change in value for the Due Date field.
     * @param {Object} newValue new Date Value
     */
    const handleDateChange = (date) => {
        if (date) {
            // Round all entries down to the nearest hour
            date = dayjs(date).endOf('day');
        }
        setDueDate(date);
    };

    /**
     * Handles the change in state for the Resend Email checkbox.
     * @param {Object} event contains the new checked value
     */
    const handleResendEmailChange = (event) => {
        setResendEmails(event.target.checked);
    };

    return (
        <Dialog maxWidth="xs" fullWidth={true} open={open} onClose={handleNeg} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle sx={{ display: 'flex', justifyContent: 'center', color: theme.palette.primary.main }} id="alert-dialog-title">
                {title}
            </DialogTitle>
            <Divider variant="middle" />
            <DialogContent sx={{ display: 'flex', p: 15, justifyContent: 'center' }}>
                <Stack direction="column" sx={{ gap: 8 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Due Date"
                            value={dueDate}
                            onChange={(val) => handleDateChange(val)}
                            disablePast={true}
                            renderInput={(params) => {
                                return <TextField {...params} />;
                            }}
                            views={['year', 'month', 'day']}
                        />
                    </LocalizationProvider>
                    <FormControlLabel control={<Checkbox checked={resendEmails} />} label="Re-send emails on update of the Due Date." onChange={handleResendEmailChange} />
                </Stack>
            </DialogContent>
            <Divider variant="middle" />
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', p: 5 }}>
                <Button onClick={handleNeg} variant="contained">
                    Cancel
                </Button>
                <Button autoFocus disabled={dueDate === null} onClick={() => handlePos({ dueDate, resendEmails })} variant="contained">
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DueDateEditorDialog;
