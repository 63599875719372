import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to update a UAR Report.
 * @param {Array} updatedReport report to update.
 * @returns the newly updated UAR Report.
 */
export const updateUARReports = async (options) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        body: JSON.stringify(options)
    };

    const requestPath = `/uarReport/updateAll`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
