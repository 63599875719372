// React Imports
import React, { useEffect, useState, useRef } from 'react';
// MUI Imports
import { Box, IconButton, TableContainer } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
// Component Imports
import ManagerUploadButton from './ManagerUploadButton';
import DownloadTemplate from './DownloadTemplate';
import NoRowsAlert from 'components/ui/NoRowsAlert';
import SureDialog from 'components/ui/SureDialog';
import TableHeader from 'components/ui/TableHeader';
// API Imports
import { deleteManagerData } from '../services/deleteManagerData';
import { downloadManagerData } from '../services/downloadManagerData';
import { getManagerDataByAccId } from '../services/getManagerDataByAccId';
// Utils
import { downloadFile } from 'utils/downloadFile';
// Data
import { miscStore, reportStore } from 'stores';

function ManagerCSVs({ tableOptions }) {
    //Columns for the DataGrid in ManagerCSVs
    const columns = [
        { id: 'name', field: 'name', headerName: 'Name', headerAlign: 'center', minWidth: tableOptions.name.minWidth, align: 'left', flex: 1 },
        {
            id: 'download',
            field: 'download',
            headerName: 'Download',
            headerAlign: 'center',
            minWidth: 60,
            align: 'center',
            sortable: false,
            flex: 1,
            renderCell: (row) => {
                return (
                    <IconButton onClick={() => handleDownload(row)}>
                        <DownloadIcon />
                    </IconButton>
                );
            }
        },
        {
            id: 'delete',
            field: 'delete',
            headerName: 'Delete',
            headerAlign: 'center',
            minWidth: 60,
            align: 'center',
            sortable: false,
            flex: 1,
            renderCell: (row) => {
                return (
                    <IconButton onClick={() => handleOpen(row)}>
                        <DeleteIcon color="red" />
                    </IconButton>
                );
            }
        }
    ];

    //useStates
    const [csvs, setCSVs] = useState([]);
    const [deleteRowInfo, setDeleteRowInfo] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const isMounted = useRef(true);
    const [isOpen, setIsOpen] = useState(false);
    const [, , updateManagerInfo] = reportStore.useState('managerInfo');
    const [tableRefreshTrigger, setTableRefreshTrigger] = miscStore.useState('tableRefreshTrigger');
    const [selectionModel, setSelectionModel] = useState([]);

    const MANAGER_CSV_TABLE_ACTIONS = [
        <ManagerUploadButton key="managerUploadButton" isMultiple={true} setTableRefreshTrigger={setTableRefreshTrigger} tableRefreshTrigger={tableRefreshTrigger}></ManagerUploadButton>,
        <DownloadTemplate key="downloadTemplate"></DownloadTemplate>
    ];

    //useEffects

    useEffect(() => {
        isMounted.current = true;
        const getManagerData = async () => {
            const managerData = await getManagerDataByAccId();
            //Set the ManagerData state variable
            if (isMounted.current) {
                setCSVs(managerData);
            }
            setIsLoading(false);
        };
        getManagerData();
        return () => {
            isMounted.current = false;
        };
    }, [tableRefreshTrigger]);

    //Functions
    /**
     * Function to Open the Are You Sure modal and prep the states for Deletion
     * @param {Object} row Originating Row
     */
    const handleOpen = (row) => {
        setDeleteRowInfo(row.id);
        setIsOpen(true);
    };

    /**
     * Function to Close the Are You Sure Modal and cleare the deletion States
     */
    const handleClose = () => {
        setDeleteRowInfo({});
        setIsOpen(false);
    };

    /**
     * Function that handles a Yes click in the Are You Sure Modal
     */
    const handleDelete = () => {
        deleteRow();
        handleClose();
    };

    /**
     * Function that deletes a CSV from S3
     */
    const deleteRow = async () => {
        await deleteManagerData(deleteRowInfo);
        setTableRefreshTrigger(!tableRefreshTrigger);
    };

    /**
     * Function that handles the download of a row from Manager Data from S3
     * @param {Object} row row with S3 key
     */
    const handleDownload = async (row) => {
        //append the accId to the front of the filename and retrieve it from S3
        const response = await downloadManagerData(row.id);
        downloadFile(response, row.id);
    };

    /**
     * Handler function for a row selection of the Manager Data table
     * @param {Array} selections Array of selections on the table
     */
    const handleSelect = async (selections) => {
        /* MUI datagrid does not allow you to enable single select with
        with checkboxes enabled. We filter the selection here to remove
        previous selections.*/
        if (selections.length > 1) {
            selections = selections.filter((row) => {
                return selectionModel.indexOf(row) === -1;
            });
        }
        setSelectionModel(selections);
        updateManagerInfo((managerInfo) => {
            managerInfo.managerCSVName = selections[0];
            managerInfo.managerReady = selections.length > 0 ? true : false;
        });
    };

    return (
        <React.Fragment>
            <TableHeader icon={<InsertDriveFileIcon color="primary" />} title="Manager Data" showActions actions={MANAGER_CSV_TABLE_ACTIONS} />
            <TableContainer sx={{ maxHeight: '50vh' }}>
                <Box sx={{ height: 350, width: '100%' }}>
                    <DataGrid
                        checkboxSelection={tableOptions.checkbox}
                        columns={columns.filter((column) => {
                            return tableOptions[column.field].display;
                        })}
                        components={{ NoRowsOverlay: () => <NoRowsAlert title="No Manager Data" message="No Manager Data has been Uploaded Yet"></NoRowsAlert> }}
                        disableSelectionOnClick
                        getRowId={(row) => row.name}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'Key', sort: 'asc' }]
                            }
                        }}
                        loading={isLoading}
                        pageSize={10}
                        rows={csvs}
                        rowsPerPageOptions={[10]}
                        selectionModel={selectionModel}
                        sx={{ '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': { display: 'none' } }}
                        onSelectionModelChange={handleSelect}
                    ></DataGrid>
                </Box>
            </TableContainer>
            <SureDialog title="Are You Sure?" open={isOpen} handleNeg={handleClose} handlePos={handleDelete}></SureDialog>
        </React.Fragment>
    );
}

export default ManagerCSVs;
