// React Imports
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// MUI Imports
import { Box, Button, Container, Grid, Paper, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
// File Imports
import { useCognito } from 'features/auth';
import nemosLogoWithTextDarkMode from 'assets/NemosFoxLogoWithTextDarkMode.svg';
import nemosLogoWithTextLightMode from 'assets/NemosFoxLogoWithTextLightMode.svg';

function ForgotPassword() {
    // Constants
    const navigate = useNavigate();
    const currentTheme = useTheme();
    const { forgotPassword } = useCognito();

    // Effects
    const [userNameError, setUserNameError] = useState(false);
    const [userNameErrorText, setUserNameErrorText] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Local Methods
    /**
     * Handles the Forgot Password Event
     * @param {Object} event submit event
     */
    const handleForgotPassword = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const data = new FormData(event.currentTarget);
        if (data.get('userName')) {
            await forgotPassword(data.get('userName'));
            setEmailSent(true);
        } else {
            setUserNameError(true);
            setUserNameErrorText('Invalid userName');
        }
    };

    /**
     * Handles the Cancel event
     */
    const handleCancel = () => {
        navigate('/login', { replace: true });
    };

    /**
     * Handles the userName change event
     * @param {Object} event
     */
    const handleUserNameChange = (event) => {
        if (event.target.value.length > 0) {
            setUserNameError(false);
            setUserNameErrorText('');
        }
    };

    const handleReturnToLogin = () => {
        navigate('/login', { replace: true });
    };

    return (
        <Container component="main" maxWidth="xs" sx={{ pt: 80 }}>
            <Paper elevation={3}>
                <Stack sx={{ p: 10, borderRadius: 10 }} spacing={6}>
                    <Typography component="h1" variant="h5" sx={{ alignSelf: 'center' }}>
                        {emailSent ? 'Check Your Email' : 'Forgot Password?'}
                    </Typography>
                    {currentTheme.palette.mode === 'dark' && <img src={nemosLogoWithTextDarkMode} alt="Helios" className="header-image" />}
                    {currentTheme.palette.mode === 'light' && <img src={nemosLogoWithTextLightMode} alt="Helios" className="header-image" />}
                    {emailSent ? (
                        <>
                            <Typography variant="body2" sx={{ textAlign: 'left' }}>
                                An email has been sent to you with instructions on resetting your password.
                            </Typography>
                            <Typography variant="body2" sx={{ textAlign: 'left' }}>
                                Can’t find the email? Try checking your spam folder.
                            </Typography>
                        </>
                    ) : (
                        <Typography variant="body2" sx={{ alignSelf: 'center' }}>
                            Enter your username to reset your password.
                        </Typography>
                    )}
                    {emailSent ? (
                        <Button variant="contained" onClick={handleReturnToLogin} sx={{ alignSelf: 'center' }}>
                            Return to Login
                        </Button>
                    ) : (
                        <Box component="form" onSubmit={handleForgotPassword} noValidate sx={{ alignSelf: 'center' }}>
                            <TextField
                                sx={{ width: '18rem' }}
                                margin="normal"
                                required
                                fullWidth
                                id="userName"
                                label="Username"
                                name="userName"
                                autoComplete="user-name"
                                autoFocus
                                error={userNameError}
                                helperText={userNameErrorText}
                                onChange={handleUserNameChange}
                            />
                            {isLoading ? (
                                <LinearProgress></LinearProgress>
                            ) : (
                                <Grid container spacing={10} justifyContent="space-between">
                                    <Grid item>
                                        <Button onClick={handleCancel} variant="outlined" sx={{}}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button type="submit" variant="contained" sx={{}}>
                                            Continue
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                    )}
                </Stack>
            </Paper>
        </Container>
    );
}

export default ForgotPassword;
