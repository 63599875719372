// React Imports
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
// MUI Imports
import { Box, FormControlLabel, Switch, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// 3rd Party Imports
import moment from 'moment';
// Component Imports
import { getUARReport, ReportCard, SendReportModal, UARCrudCards, UARCrudTable, UARSystemCards, UARSystemTable, updateUARReport, useReport } from 'features/reports';
import { ManagerApprovalsTable } from 'features/approvals';
import TabSet from 'components/ui/TabSet';
import { miscStore } from 'stores';

function UARReport() {
    const location = useLocation();
    const theme = useTheme();
    const [checked, setChecked] = useState(false);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { reportId } = useParams();
    const [, , updateAlert] = miscStore.useState('alert');
    const [sendReportIsOpen, setSendReportIsOpen] = useState(false);

    const useReportHook = useReport(reportId, null, 'uar-approvals', true);
    const { objectRows, report, systemRows } = useReportHook;

    const TABS = [
        {
            key: 'systemSettings',
            content: isSmallScreen ? <UARSystemCards report={report} rows={systemRows} /> : <UARSystemTable isAdmin={true} useReport={useReportHook} />,
            label: 'System Settings'
        },
        {
            key: 'objectAccess',
            content: isSmallScreen ? <UARCrudCards report={report} rows={objectRows} /> : <UARCrudTable isAdmin={true} useReport={useReportHook} />,
            label: 'Object Access'
        }
    ];
    /**
     * useEffect to set the lastViewed field for the report on first render
     */
    useEffect(() => {
        const stampReport = async () => {
            const report = await getUARReport(reportId);
            report.lastViewed = moment().format();
            await updateUARReport(report);
        };
        stampReport();
    }, []);

    /**
     * useEffect to display notifications from within a navigation
     */
    useEffect(() => {
        if (location.state?.alert) {
            updateAlert((alert) => {
                alert.autoHideDuration = 3000;
                alert.message = location.state.alertPayload.message;
                alert.open = true;
                alert.severity = location.state.alertPayload.severity;
                alert.onClose = handleAlertClose;
            });
        }
    }, [location]);

    /**
     * Function that handles the Notifications Close event
     */
    const handleAlertClose = () => {
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    const handleViewChange = (event) => {
        setChecked(event.target.checked);
    };

    return (
        <React.Fragment>
            {report && (systemRows?.length > 0 || objectRows?.length > 0) && (
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <ReportCard useReport={useReportHook} isAdminView={true} setSendReportIsOpen={setSendReportIsOpen}></ReportCard>
                    {report.status !== 'Ready to Send' && (
                        <FormControlLabel control={<Switch checked={checked} onChange={handleViewChange} />} label="Approval View" sx={{ color: theme.palette.text.primary, width: 'fit-content' }} />
                    )}
                    {checked ? <ManagerApprovalsTable approvalRows={report.approvalRows} isAdminView useReport={useReportHook} /> : <TabSet tabs={TABS} />}
                    <SendReportModal isOpen={sendReportIsOpen} useReport={useReportHook} setIsOpen={setSendReportIsOpen}></SendReportModal>
                </Box>
            )}
        </React.Fragment>
    );
}

export default UARReport;
