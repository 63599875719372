//React Imports
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

//MUI Imports
import { Box, Drawer, Toolbar, List, ListItem, ListItemButton, ListItemIcon, ListItemText, IconButton, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

//Icon Imports
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CreditCardIcon from '@mui/icons-material/CreditCard';
//import GroupIcon from '@mui/icons-material/Group';
import KeyIcon from '@mui/icons-material/Key';
import WorkIcon from '@mui/icons-material/Work';
import salesforceIconDarkMode from 'assets/Salesforce_Logo_White.svg';
import salesforceIconLightMode from 'assets/Salesforce_Logo_Grey.svg';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { authStore } from 'stores';

const DRAWER_WIDTH = '15vw';
const MINI_DRAWER_WIDTH = '230px';

const openedMixin = (theme, height) => ({
    width: MINI_DRAWER_WIDTH,
    height: `${height}px`,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    borderRight: '1px solid rgba(255, 255, 255, 0.12)'
});

const closedMixin = (theme, height) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: '66px',
    height: `${height}px`,
    position: 'absolute',
    top: 0,
    left: 0,
    borderRight: '1px solid rgba(255, 255, 255, 0.12)'
});

const MiniDrawerHeader = styled('div')(({ theme, open }) => ({
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(0, 1),
    ...(!open && {
        justifyContent: 'flex-end'
    }),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
}));

const MiniDrawerSpacer = styled('div')(() => ({
    height: '100%',
    width: '66px',
    flexShrink: 0,
    background: 'transparent',
    marginLeft: '-1rem',
    marginRight: '1rem'
}));

const MainDrawer = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open, height }) => ({
    flexShrink: 0,
    height: `${height}px`,
    [theme.breakpoints.up('lg')]: {
        width: DRAWER_WIDTH,
        [`& .MuiDrawer-paper`]: { width: DRAWER_WIDTH, boxSizing: 'border-box' }
    },
    [theme.breakpoints.down('lg')]: {
        width: MINI_DRAWER_WIDTH,
        boxSizing: 'border-box',
        overflowX: 'hidden',
        ...(open && {
            ...openedMixin(theme, height),
            '& .MuiDrawer-paper': openedMixin(theme, height)
        }),
        ...(!open && {
            ...closedMixin(theme, height),
            '& .MuiDrawer-paper': closedMixin(theme, height)
        })
    }
}));

function SettingsSideBar() {
    // Constants
    const [height] = useState(window.screen.height);
    const [passwordSession] = authStore.useState('passwordSession');
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const sideBarOptions = [
        { key: 'personalProfile', label: 'Personal Profile', icon: <AccountBoxIcon />, link: `/${passwordSession.sessionDetails.namespace}/settings/personal-profile` },
        {
            key: 'salesforceOrgs',
            label: 'Salesforce Orgs',
            icon: <img alt="Salesforce Orgs" height={25} width={25} src={theme.palette.mode === 'light' ? salesforceIconLightMode : salesforceIconDarkMode}></img>,
            link: `/${passwordSession.sessionDetails.namespace}/settings/salesforce-orgs`
        },
        { key: 'keySettings', label: 'Key Settings', icon: <KeyIcon />, link: `/${passwordSession.sessionDetails.namespace}/settings/key-permissions` },
        { key: 'managerData', label: 'Manager Data', icon: <WorkIcon />, link: `/${passwordSession.sessionDetails.namespace}/settings/manager-data` },
        { key: 'billing', label: 'Billing', icon: <CreditCardIcon />, link: `/${passwordSession.sessionDetails.namespace}/settings/billing` }
        /*{ key: 'userManagement', label: 'User Management', icon: <GroupIcon />, link: `/${passwordSession.sessionDetails.namespace}/settings/personal-profile` }*/
    ];

    return (
        <Fragment>
            <MiniDrawerSpacer sx={{ display: { md: 'block', lg: 'none' } }} />
            <MainDrawer variant="permanent" open={open} height={height}>
                <Toolbar />
                {isSmallScreen && (
                    <MiniDrawerHeader>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                display: open ? 'none' : 'block',
                                margin: 'auto',
                                ...(open && { display: 'none' })
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <IconButton onClick={handleDrawerClose} sx={{ display: open ? 'block' : 'none' }}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </MiniDrawerHeader>
                )}
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        {sideBarOptions.map((option) => (
                            <ListItem sx={{ '&:hover': { color: theme.palette.primary.main }, color: theme.palette.text.primary }} key={option.key} disablePadding component={Link} to={option.link}>
                                <ListItemButton sx={{ justifyContent: open ? 'initial' : 'center', px: 2.5, height: '64px' }}>
                                    <ListItemIcon sx={{ justifyContent: 'center' }}>{option.icon}</ListItemIcon>
                                    {(!isSmallScreen || (isSmallScreen && open)) && <ListItemText primary={option.label} sx={{ wordWrap: 'break-word' }} />}
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </MainDrawer>
        </Fragment>
    );
}

export default SettingsSideBar;
