import { AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';
import { getCognitoUser } from '../utils/getCognitoUser';
import { getCognitoUserPasswordless } from '../utils/getCognitoUserPasswordless';
import { genRandomString } from 'utils/genRandomString';
import { userPool, userPoolPasswordless } from '../data/constants';
import { authStore } from 'stores';

export const useCognito = () => {
    const [, , updatePasswordSession] = authStore.useState('passwordSession');
    const [, , updatePasswordlessSession] = authStore.useState('passwordlessSession');
    const [passwordlessAuthChallenge, setPasswordlessAuthChallenge] = authStore.useState('passwordlessAuthChallenge');

    /**
     * Confirms a users reset password link via the provided confirmation code.
     * @param {String} userName userName of the new user.
     * @param {String} newPassword new Password for the new User
     * @param {String} code confirmation code for the password reset.
     * @return result string
     */
    const confirmPasswordCode = async (userName, newPassword, code) => {
        const cognitoUser = getCognitoUser(userName);
        return new Promise(function (resolve, reject) {
            cognitoUser.confirmPassword(code, newPassword, {
                onSuccess() {
                    resolve('Password confirmed!');
                },
                onFailure(err) {
                    reject(err);
                }
            });
        });
    };

    /**
     * Confirms a users registration via the provided confirmation code.
     * @param {String} userName userName of the new user.
     * @param {String} code confirmation code for the unregistered user.
     * @return result object
     */
    const confirmRegistration = (userName, code) => {
        const cognitoUser = getCognitoUser(userName);
        return new Promise((resolve, reject) => {
            cognitoUser.confirmRegistration(code, true, (err, result) => {
                if (err) {
                    let message = 'Unable to verify your email address. Please contact support.';
                    switch (err.code) {
                        case 'CodeMismatchException':
                            message = 'Link is no longer valid. ';
                            break;
                        case 'ExpiredCodeException':
                            message = 'Email verification link has expired. ';
                            break;
                        default:
                            break;
                    }
                    reject({ code: err.code, message });
                } else {
                    resolve({ type: 'Success', body: result });
                }
            });
        });
    };

    /**
     * Creates a user in the passwordless Cognito User Pool.
     * @param {String} name name of the new user.
     * @param {String} email email of the new user.
     * @return result object
     */
    const createPasswordlessUser = async (name, email) => {
        const attributeList = [new CognitoUserAttribute({ Name: 'name', Value: name }), new CognitoUserAttribute({ Name: 'email', Value: email })];
        return new Promise((resolve, reject) => {
            userPoolPasswordless.signUp(email, genRandomString(30), attributeList, null, function (err, result) {
                if (err) {
                    reject({ type: 'Error', body: 'Unable to create passwordless User.' });
                } else {
                    const cognitoUser = result.user;
                    resolve({ type: 'Success', body: cognitoUser });
                }
            });
        });
    };

    /**
     * Sends a Reset Your Password email to the user
     * @param {String} userName userName of the user that wants to reset their password
     * @returns
     */
    const forgotPassword = async (userName) => {
        const cognitoUser = getCognitoUser(userName);
        return new Promise(function (resolve, reject) {
            cognitoUser.forgotPassword(
                {
                    onSuccess: function (data) {
                        // successfully initiated reset password request
                        resolve(data);
                    },
                    onFailure: function (err) {
                        reject(JSON.stringify(err));
                    }
                },
                { baseURL: window.location.origin }
            );
        });
    };

    /**
     * Gets both the current password and passwordless auth states
     * Password: For Admin users
     * Passwordless: For managers to perform approvals
     */
    const getCurrentAuthStates = async () => {
        const passwordAuthCheck = getSession();
        const passwordlessAuthCheck = getSessionPasswordless();
        try {
            await Promise.all([passwordAuthCheck, passwordlessAuthCheck]);
        } catch (err) {
            updatePasswordSession((passwordSession) => {
                passwordSession.isValid = false;
                passwordSession.sessionDetails = {};
            });
            updatePasswordlessSession((passwordlessSession) => {
                passwordlessSession.isValid = false;
                passwordlessSession.sessionDetails = {};
            });
        } finally {
            updatePasswordSession((passwordSession) => {
                passwordSession.isLoading = false;
            });
            updatePasswordlessSession((passwordlessSession) => {
                passwordlessSession.isLoading = false;
            });
        }
    };

    /**
     * Retrieves the current user from local storage.
     * @returns
     */
    const getSession = () => {
        const currentUser = userPool.getCurrentUser();

        if (currentUser != null) {
            return new Promise((resolve, reject) => {
                currentUser.getSession((err, session) => {
                    console.log('getting session');
                    if (err || !session.isValid()) {
                        console.log('session is invalid');
                        updatePasswordSession((passwordSession) => {
                            passwordSession.isValid = false;
                            passwordSession.sessionDetails = {};
                            reject();
                        });
                    } else if (session.isValid()) {
                        const jwtToken = session.accessToken.jwtToken;
                        const payload = session.idToken.payload;
                        updatePasswordSession((passwordSession) => {
                            passwordSession.isValid = true;
                            passwordSession.sessionDetails = { companyName: payload['custom:companyName'], email: payload.email, name: payload.name, namespace: payload['custom:namespace'], jwtToken };
                            resolve();
                        });
                    }
                });
            });
        }
    };

    /**
     * Retrieves the current user from local storage.
     * @returns
     */
    const getSessionPasswordless = () => {
        const currentPasswordlessUser = userPoolPasswordless.getCurrentUser();
        if (currentPasswordlessUser != null) {
            return new Promise((resolve, reject) => {
                currentPasswordlessUser.getSession((err, session) => {
                    if (err || !session.isValid()) {
                        updatePasswordlessSession((passwordlessSession) => {
                            passwordlessSession.isValid = false;
                            passwordlessSession.sessionDetails = {};
                        });
                        reject(false);
                    } else if (session.isValid()) {
                        const jwtToken = session.accessToken.jwtToken;
                        const payload = session.idToken.payload;
                        updatePasswordlessSession((passwordlessSession) => {
                            passwordlessSession.isValid = true;
                            passwordlessSession.sessionDetails = { email: payload.email, jwtToken };
                        });
                        resolve(true);
                    }
                });
            });
        }
    };

    /**
     * Refreshes current session and gets new data after updating Cognito info
     */
    const getUserData = async () => {
        const currentUser = userPool.getCurrentUser();
        currentUser.getSession(() => {
            currentUser.getUserData(
                function (err, userData) {
                    if (err) {
                        alert(err.message || JSON.stringify(err));
                        return;
                    }
                    return userData;
                },
                { bypassCache: true }
            );
        });
    };

    /**
     * Initiates the custom auth flow and emails the user the challenge answer.
     * @param {String} email email of the user to send the verification code
     * @returns initiate auth promise
     */
    const initiatePasswordlessAuth = async (email) => {
        const authenticationData = {
            Username: email
        };
        const authenticationDetails = new AuthenticationDetails(authenticationData);
        const cognitoUser = getCognitoUserPasswordless(email);
        console.log(cognitoUser);
        cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');

        return new Promise((resolve, reject) => {
            cognitoUser.initiateAuth(authenticationDetails, {
                onFailure(err) {
                    console.log('Err = ', err);
                    setPasswordlessAuthChallenge({});
                    reject();
                },
                customChallenge() {
                    console.log('set user to ', cognitoUser);
                    setPasswordlessAuthChallenge((answer, callback) => cognitoUser.sendCustomChallengeAnswer(answer, callback));
                    resolve();
                }
            });
        });
    };

    /**
     * Resends an email verification email to the users inbox.
     * @param {String} email email of the user to resend the verification code to
     */
    const resendConfirmationCode = (email) => {
        const cognitoUser = getCognitoUser(email);
        return new Promise((resolve, reject) => {
            cognitoUser.resendConfirmationCode(
                (err, result) => {
                    if (err) {
                        let message = 'Unable to resend email verification. Please reach out to support for assistance.';
                        switch (err.code) {
                            case 'LimitExceededException':
                                message = 'You have reached the limit for resending email verification emails. Please contact support.';
                                break;
                            default:
                                break;
                        }
                        reject(message);
                    }
                    resolve(result);
                },
                { baseURL: window.location.origin }
            );
        });
    };

    const sendCustomChallengeAnswer = async (answer) => {
        return new Promise(function (resolve, reject) {
            passwordlessAuthChallenge(answer, {
                onSuccess(session) {
                    const jwtToken = session.accessToken.jwtToken;
                    const payload = session.idToken.payload;
                    // Succeeded challenge
                    updatePasswordlessSession((passwordlessSession) => {
                        passwordlessSession.isValid = true;
                        passwordlessSession.sessionDetails = { email: payload.email, jwtToken };
                    });
                    resolve(session);
                },
                onFailure() {
                    // Failed challenge
                    reject('Failed Authentication');
                },
                customChallenge() {
                    // Incorrect challenge attempt.
                    reject('Failed Challenge Attempt');
                }
            });
        });
    };

    /**
     * Attempts to sign the User in with the provided credentials
     * @param {String} userName username of the User
     * @param {String} password password of the User
     * @returns
     */
    const signIn = (userName, password) => {
        const authenticationData = {
            Username: userName,
            Password: password
        };
        const authenticationDetails = new AuthenticationDetails(authenticationData);
        let cognitoUser = getCognitoUser(userName);

        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (session) => {
                    const jwtToken = session.accessToken.jwtToken;
                    const payload = session.idToken.payload;

                    // Used to set the cognito session in localstorage for AWS to retrieve in subsequent requests
                    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                        IdentityPoolId: '', // your identity pool id here
                        Logins: {
                            // Change the key below according to the specific region your user pool is in.
                            [`cognito-idp.us-west-2.amazonaws.com/${process.env.REACT_APP_COGNITO_USERPOOLID}`]: session.getIdToken().getJwtToken()
                        }
                    });

                    updatePasswordSession((passwordSession) => {
                        passwordSession.isValid = true;
                        passwordSession.sessionDetails = { companyName: payload['namespace'], email: payload.email, name: payload.name, namespace: payload['custom:namespace'], jwtToken };
                    });
                    resolve(session);
                },

                onFailure: (err) => {
                    reject(err);
                }
            });
        });
    };

    /**
     * Signs out the current user.
     */
    const signOut = async () => {
        const currentUser = userPool.getCurrentUser();
        if (currentUser) {
            currentUser.signOut();
            updatePasswordSession((passwordSession) => {
                passwordSession.isValid = false;
                passwordSession.sessionDetails = {};
            });
        }
    };

    /**
     * Creates a user in the Cognito User Pool.
     * @param {Object} newUser contains details for the new user.
     * @return result object
     */
    const signUp = async (newUser) => {
        const attributeList = [
            new CognitoUserAttribute(newUser.accId),
            new CognitoUserAttribute(newUser.companyName),
            new CognitoUserAttribute(newUser.email),
            new CognitoUserAttribute(newUser.name),
            new CognitoUserAttribute(newUser.namespace)
        ];
        return new Promise((resolve, reject) => {
            userPool.signUp(
                newUser.username,
                newUser.password,
                attributeList,
                null,
                function (err, result) {
                    if (err) {
                        let message = 'Unable to create Account.';
                        switch (err.code) {
                            case 'UsernameExistsException':
                                message = 'An account already exists with this email.';
                                break;
                            default:
                                break;
                        }
                        reject({ type: 'Error', body: message });
                    } else {
                        const cognitoUser = result.user;
                        resolve({ type: 'Success', body: cognitoUser });
                    }
                },
                { baseURL: window.location.origin }
            );
        });
    };

    /**
     * Calls API to update user information
     * @param {Object } userDetails key value pairs of user attributes to update
     * @param {String} response
     */
    const updateUserInfo = async (userDetails) => {
        const currentUser = userPool.getCurrentUser();

        const name = new CognitoUserAttribute({
            Name: 'name',
            Value: userDetails.name
        });
        const email = new CognitoUserAttribute({
            Name: 'email',
            Value: userDetails.email
        });
        const attributes = [name, email];
        return new Promise((resolve, reject) => {
            try {
                currentUser.getSession(() => {
                    currentUser.updateAttributes(attributes, function (err, result) {
                        if (err) {
                            reject({ type: 'Error', body: err.message || JSON.stringify(err) });
                        } else {
                            resolve({ type: 'Success', body: result });
                        }
                    });
                });
            } catch (err) {
                reject(err.message);
            }
        });
    };

    /**
     * Verifies a user's email address when redirected with a verification code.
     * @param {String} code confirmation code for the unregistered user.
     * @return result object
     */
    const verifyEmail = (code) => {
        const currentUser = userPool.getCurrentUser();
        return new Promise((resolve, reject) => {
            currentUser.verifyAttribute('email', code, {
                onSuccess: function (result) {
                    resolve({ type: 'Success', body: result });
                },
                onFailure: function (err) {
                    reject({ type: 'Error', body: err.message });
                }
            });
        });
    };

    return {
        confirmPasswordCode,
        confirmRegistration,
        createPasswordlessUser,
        forgotPassword,
        getCurrentAuthStates,
        getUserData,
        getSession,
        initiatePasswordlessAuth,
        resendConfirmationCode,
        sendCustomChallengeAnswer,
        signIn,
        signOut,
        signUp,
        updateUserInfo,
        verifyEmail
    };
};
