// React Imports
import React, { useMemo } from 'react';
// MUI Imports
import { DataGrid } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
// Component Imports
import ApprovalOptions from './ApprovalOptions';

const STATUS_WIDTH = 160;

function ManagerApprovalsSystemTable({ approvals, useReport }) {
    const { report } = useReport;

    const columns = useMemo(
        () => [
            {
                field: 'label',
                flex: 1,
                headerName: 'Name'
            },
            {
                field: 'description',
                flex: 2,
                headerName: 'Description'
            },
            {
                field: 'value',
                align: 'center',
                flex: 0,
                headerName: 'Access',
                renderCell: (cellValues) => (cellValues.row.value ? <CheckIcon sx={{ color: 'text.success' }} /> : <CloseIcon sx={{ color: 'text.error' }} />)
            },
            {
                field: 'status',
                align: 'center',
                headerName: 'Approval Status',
                minWidth: STATUS_WIDTH,
                renderCell: (cellValue) => {
                    return <ApprovalOptions approval={cellValue.row} useReport={useReport}></ApprovalOptions>;
                }
            }
        ],
        [report.dueDate]
    );

    return <DataGrid rows={approvals} getRowId={(row) => row._id} columns={columns} getRowHeight={() => 'auto'} />;
}

export default ManagerApprovalsSystemTable;
