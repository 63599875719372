// React Imports
import React, { useEffect, useRef, useState } from 'react';
// MUI Imports
import { Box, IconButton, Tooltip, Button } from '@mui/material';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import InfoIcon from '@mui/icons-material/Info';

import { reportStore } from 'stores';

function SystemSettingList({ setDefaultSettingsOn, inSettings }) {
    const [allSystemSettings] = reportStore.useState('allSystemSettings');
    const [currentOrg] = reportStore.useState('currentOrg');
    const [displayedSettings, setDisplayedSettings] = useState(allSystemSettings);
    const [buttonLabel, setButtonLabel] = useState('Show selected');
    const isMounted = useRef(true);
    const [selectedObjects] = reportStore.useState('selectedObjects');
    const [selectedSystemSettings, setSelectedSystemSettings] = reportStore.useState('selectedSystemSettings');

    const [defaultsSelected] = useState(JSON.stringify([...selectedSystemSettings].sort()) === JSON.stringify([...currentOrg.defaultSystemSettings].sort()));
    const [settingsFirstRender] = useState(inSettings && selectedSystemSettings.length === 0);
    const [uarStepperFirstRender] = useState(selectedSystemSettings.length === 0 && selectedObjects.length === 0);

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            valueGetter: (systemSetting) => systemSetting.row.label,
            renderCell: (systemSetting) => (
                <>
                    {systemSetting.row.label}
                    <Tooltip title={systemSetting.row.description}>
                        <IconButton>
                            <InfoIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }
    ];

    /*
     * useEffect that runs once per render to check whether to use saved keySystemSettings or defaults saved to the account
     */
    useEffect(() => {
        //compare currently selected with defaults (if currently selected has values). if they're the same, toggle standard defaults like normal
        const defaultsOn = settingsFirstRender || uarStepperFirstRender || defaultsSelected ? true : false;
        if (defaultsOn) {
            if (isMounted.current) setSelectedSystemSettings(currentOrg.defaultSystemSettings);
        } else {
            if (isMounted.current && !inSettings) {
                setDefaultSettingsOn(false);
            } else if (isMounted.current) {
                setSelectedSystemSettings(currentOrg.defaultSystemSettings);
            }
        }
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleSelectRow = (selectedRows) => {
        setSelectedSystemSettings(selectedRows);
        // Clear default toggle off since they're customizing selection
        if (setDefaultSettingsOn) {
            setDefaultSettingsOn(false);
        }
    };

    function Toolbar() {
        return (
            <Box
                sx={{
                    p: 5,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <GridToolbarQuickFilter />
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                        toggleSelected();
                    }}
                >
                    {buttonLabel}
                </Button>
            </Box>
        );
    }

    function toggleSelected() {
        if (buttonLabel === 'Show selected') {
            const display = selectedSystemSettings.reduce((obj, key) => {
                obj[key] = allSystemSettings[key];
                return obj;
            }, {});
            setDisplayedSettings(display);
            setButtonLabel('Show all');
        } else if (buttonLabel === 'Show all') {
            setDisplayedSettings(allSystemSettings);
            setButtonLabel('Show selected');
        }
    }

    return (
        <DataGrid
            getRowId={(row) => row.name}
            rows={Object.values(displayedSettings)}
            columns={columns}
            sortingOrder={['asc', 'desc']}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 5
                    }
                },
                sorting: {
                    sortModel: [{ field: 'name', sort: 'asc' }]
                }
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            onSelectionModelChange={handleSelectRow}
            selectionModel={selectedSystemSettings}
            components={{ Toolbar: Toolbar }}
        />
    );
}

export default SystemSettingList;
