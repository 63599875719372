import { authStore } from 'stores';

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';

/**
 * Function to export a UAR Report to the user requesting it
 * @param {Object} reportData Report data containing the UAR Report and the users Email Address
 * @returns UAR Report Data
 */
export const exportUARReport = async (reportData) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    return await axios({
        method: 'POST', //Should probably be a GET request but I couldnt get the Axios GET to propagate the necessary information
        url: API_URL + `/uarReport/export`,
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        responseType: 'arraybuffer',
        data: {
            reportData: JSON.stringify(reportData)
        }
    });
};
