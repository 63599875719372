// React Imports
import React, { useEffect, useState, useRef } from 'react';
// MUI Imports
import { Accordion, AccordionSummary, AccordionDetails, Typography, Card, CardContent, Collapse, Box } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';

const ExpandMore = styled((props) => {
    const { ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
    })
}));

function UARSystemCards({ report, rows }) {
    const [columns, setColumns] = useState([
        { field: 'managerName', headerName: 'Manager' },
        { field: 'userName', headerName: 'Employee' },
        { field: 'permissionType', headerName: 'Permission Type' },
        { field: 'permissionName', headerName: 'Permission Name' },
        { field: 'comments', headerName: 'Comments' },
        { field: 'approveState', headerName: 'Status' }
    ]);
    const isMounted = useRef(true);
    const [keySettingColumns, setKeySettingColumns] = useState([]);
    const [mobileRows, setMobileRows] = useState({});
    const theme = useTheme();
    const statusColors = {
        Approved: theme.palette.text.success,
        Rejected: theme.palette.text.error,
        Pending: theme.palette.primary.main
    };

    useEffect(() => {
        // Retrieve the Report.
        const prepData = async () => {
            // Assign Key Settings to columns
            generateKeySettingsColumns(report.keySystemSettings);
            if (isMounted.current) {
                setColumns([...columns]);
                setMobileRows(generateMobileRows());
            }
        };
        isMounted.current = true;
        prepData();
        return () => {
            isMounted.current = false;
        };
    }, [rows]);

    /* We have to configure the rows to work for mobile by grouping the permissions by manager. This allows
    us to easily group the cards in the accordian elements.*/
    const generateMobileRows = () => {
        return rows.reduce((acc, row) => {
            row.expanded = false;
            if (Object.prototype.hasOwnProperty.call(acc, row.managerId)) {
                acc[row.managerId].assignments.push(row);
            } else {
                acc[row.managerId] = {
                    name: row.managerName,
                    assignments: [row]
                };
            }
            return acc;
        }, {});
    };

    /**
     * Generates the key settings columns and inserts them into the columns array.
     * @param {Array} keyObjects list of key settings included in the uar report
     */
    const generateKeySettingsColumns = (keySettings) => {
        const keyColumns = keySettings.map((setting) => {
            return {
                field: setting.name,
                headerName: setting.label
            };
        });
        if (isMounted.current) {
            setKeySettingColumns(keyColumns);
            columns.splice(4, 0, ...keyColumns);
        }
    };

    /**
     * @param {String} managerId Id of the manager
     * @param {Integer} index index of the assignment row to update.
     */
    const handleExpandClick = (managerId, index) => {
        setMobileRows((prevRows) => {
            const updatedRows = { ...prevRows };
            updatedRows[managerId].assignments[index].expanded = !updatedRows[managerId].assignments[index].expanded;
            return updatedRows;
        });
    };

    return (
        <React.Fragment>
            {Object.keys(mobileRows).map((managerId) => (
                <Accordion key={`Manager${managerId}`}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>{mobileRows[managerId].name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ display: 'flex', gap: 10, p: 10, flexWrap: 'wrap', alignItems: 'flex-start' }}>
                        {mobileRows[managerId].assignments.map((assignment, index) => (
                            <Card key={assignment._id} elevation={6}>
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography sx={{ fontWeight: 'bold' }} color="primary" variant="h5" component="div">
                                            {assignment.userName}
                                        </Typography>
                                        <ExpandMore expand={assignment.expanded} onClick={() => handleExpandClick(managerId, index)} aria-expanded={assignment.expanded} aria-label="show more">
                                            <ExpandMoreIcon />
                                        </ExpandMore>
                                    </Box>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        <b>{'Status:'}</b> <span style={{ color: statusColors[assignment.approveState] }}>{assignment.approveState}</span>
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        <b>{`${assignment.permissionType}:`}</b> {assignment.permissionName}
                                    </Typography>
                                    <Collapse in={assignment.expanded} timeout="auto" unmountOnExit>
                                        {keySettingColumns.map((keySetting) => (
                                            <Typography key={`${assignment._id}${keySetting.field}`} variant="body2" sx={{ display: 'flex' }}>
                                                {`${keySetting.headerName}:`}&nbsp;
                                                <span>
                                                    {assignment[keySetting.field] ? (
                                                        <CheckIcon fontSize="small" sx={{ color: theme.palette.text.success }} />
                                                    ) : (
                                                        <CloseIcon fontSize="small" sx={{ color: theme.palette.text.error }} />
                                                    )}
                                                </span>
                                            </Typography>
                                        ))}
                                    </Collapse>
                                </CardContent>
                            </Card>
                        ))}
                    </AccordionDetails>
                </Accordion>
            ))}
        </React.Fragment>
    );
}
export default UARSystemCards;
