// React Imports
import * as React from 'react';
// MUI Imports
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

/**
 * Multi-select input with checboxes used for selection.
 * @param {String} inputLabel label for the multi-select
 * @param {[Object]} options available options for the multi-select. Ex: {label: '', value: ''}
 * @param {Function} onSelection callback for handling the select event
 */
export default function MultiSelectCheckboxes({ inputLabel, options, onSelection, selectedValues }) {
    /**
     * Checks if an option is selected.
     * @param {String} value value of an option
     * @returns {Boolean}
     */
    const getSelectState = (value) => {
        return selectedValues.has(value);
    };

    /**
     * Handles a selection or deselection of an option in the multi-select.
     * @param {Object} event contains the selected values
     */
    const handleChange = (event) => {
        const newValues = event.target.value;

        onSelection(newValues);
    };

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel>{inputLabel}</InputLabel>
                <Select
                    multiple
                    value={Array.from(selectedValues)}
                    onChange={handleChange}
                    input={<OutlinedInput label={inputLabel} />}
                    renderValue={(selected) => selected.map((value) => options.find((option) => option.value === value)?.label).join(',')}
                    MenuProps={MenuProps}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            <Checkbox checked={getSelectState(option.value)} />
                            <ListItemText primary={option.label} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
