import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to update an org.
 * @param {Object} updatedOrg Details of the org to update.
 * @returns updated org.
 */
export const updateOrg = async (updatedOrg) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;
    const httpOptions = {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedOrg),
        withCredentials: true
    };

    const requestPath = `/organization`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
