import { createStore } from 'state-pool';

const reportStore = createStore();

reportStore.setState('allObjects', {});
reportStore.setState('allSystemSettings', {});
reportStore.setState('currentOrg', {});
reportStore.setState('diffReportRedirectInfo', {});
reportStore.setState('selectedFieldTypes', []);
reportStore.setState('selectedObjects', []);
reportStore.setState('selectedSystemSettings', []);
reportStore.setState('managerInfo', { managerCSVName: '', managerData: [], managerField: { name: '', label: '' }, managerNameApi: '', managerReady: false, managerSource: '' });
reportStore.setState('newReport', { name: '', dueDate: null });
reportStore.setState('report', null);
reportStore.setState('reportReady', false);
reportStore.setState('systemSettingsByPermSet', {});
reportStore.setState('systemSettingsByProfile', {});

export default reportStore;
