import React, { useEffect, useRef } from 'react';

import { Box } from '@mui/material';

import { AccountDetails, getAccountDetails, PricingCards, SettingsSideBar } from 'features/settings';

import { billingStore } from 'stores';

function AccountManagement() {
    const [cognito] = billingStore.useState('cognito');
    const [stripe] = billingStore.useState('stripe');
    const componentIsMounted = useRef(true);
    useEffect(() => {
        return () => {
            componentIsMounted.current = false;
        };
    }, []);
    useEffect(() => {
        const getData = async () => {
            const acctData = await getAccountDetails(cognito.sub);
            if (acctData) {
                billingStore.getState('stripe').updateValue(() => {
                    return acctData;
                });
            }
        };

        if (componentIsMounted.current && cognito?.sub) {
            getData();
        }
    }, [cognito]);

    function isEmpty(obj) {
        if (obj) {
            return Object.keys(obj).length === 0;
        } else {
            return true;
        }
    }

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex' }}>
                <SettingsSideBar></SettingsSideBar>
                <Box
                    sx={{
                        flexGrow: 1,
                        p: 8
                    }}
                >
                    {isEmpty(stripe) && <PricingCards />}
                    {!isEmpty(stripe) && <AccountDetails />}
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default AccountManagement;
