// React import
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
// MUI Imports
import { useTheme } from '@mui/material/styles';
import { Box, Button, Divider, Modal, Stack, Typography, TextField } from '@mui/material';
// Component Imports
import MultiSelectCheckboxes from 'components/form/MultiSelectCheckboxes';

const OBJECT_ACCESS_OPTIONS = [
    { label: 'Create', value: 'create' },
    { label: 'Read', value: 'read' },
    { label: 'Edit', value: 'edit' },
    { label: 'Delete', value: 'delete' },
    { label: 'View All', value: 'viewAll' },
    { label: 'Modify All', value: 'modifyAll' }
];

export default function RemediationTaskModal({ approval, cancelReject, open, reject }) {
    const theme = useTheme();
    const form = useForm({ values: { rejectionReason: approval.remediationTask?.rejectionReason } });
    const { register, handleSubmit, formState } = form;
    const { errors } = formState;
    const isObjectAccessApproval = approval.approvalType === 'ObjectAccess';

    const [selectedObjectAccess, setSelectedObjectAccess] = useState(approval.remediationTask?.relevantObjectAccess ?? []);
    const [selectedValues, setSelectedValues] = useState(new Set(approval.remediationTask?.relevantObjectAccess.map((access) => access.value)) ?? new Set());

    const submitDisabled = useMemo(() => (isObjectAccessApproval ? selectedObjectAccess.length === 0 : false), [selectedObjectAccess]);

    /**
     * Handles the close action for the modal.
     */
    const handleClose = () => cancelReject();

    /**
     * Handles selection event for the Object Access multi-select
     * @param {[Object]} selectedValues the selected values
     */
    const handleSelection = (selectedValues) => {
        setSelectedObjectAccess(
            selectedValues.map((value) => {
                return OBJECT_ACCESS_OPTIONS.find((option) => option.value === value);
            })
        );

        setSelectedValues(new Set(selectedValues));
    };

    /**
     * Handles the submit action for the form.
     * @param {Object} data form values
     */
    const onSubmit = (data) => {
        const updatedApproval = { ...approval, remediationTask: { rejectionReason: data.rejectionReason } };

        // Track relavant object access for object access approvals.
        if (isObjectAccessApproval) updatedApproval.remediationTask.relevantObjectAccess = selectedObjectAccess;

        reject(updatedApproval);
    };

    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, borderRadius: 2, p: 15 }}>
                    <Typography id="modal-modal-title" variant="h6" color="primary" sx={{ mb: 5 }}>
                        Permission Rejection
                    </Typography>
                    <Divider sx={{ mb: 10 }} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={10}>
                            {isObjectAccessApproval && (
                                <>
                                    <Typography id="modal-modal-description" color={theme.palette.text.secondary}>
                                        Select Relevant Access:
                                    </Typography>
                                    <MultiSelectCheckboxes inputLabel={'Object Access'} options={OBJECT_ACCESS_OPTIONS} onSelection={handleSelection} selectedValues={selectedValues} />
                                </>
                            )}
                            <Typography id="modal-modal-description" color={theme.palette.text.secondary}>
                                Reason for rejection:
                            </Typography>
                            <TextField
                                id="rejection-reason"
                                multiline
                                rows={4}
                                {...register('rejectionReason', { required: 'Rejection reason is required.' })}
                                error={Boolean(errors.rejectionReason)}
                                helperText={errors.rejectionReason?.message}
                            />
                            <Divider sx={{ mt: 10, mb: 10 }} />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button variant="outlined" onClick={() => cancelReject()}>
                                    Cancel
                                </Button>
                                <Button disabled={submitDisabled} type="submit" variant="outlined">
                                    Submit
                                </Button>
                            </Box>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}
