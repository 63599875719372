import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to retrieve a UAR Report by Id.
 * @param {String} id Id of the UAR Report to retrieve.
 * @param {Boolean} isPasswordless disctates whether a call is passwordless or passwword
 * @returns report
 */
export const getUARReport = async (id, isPasswordless = false) => {
    const { jwtToken } = authStore.getState(isPasswordless ? 'passwordlessSession' : 'passwordSession').value.sessionDetails;

    const httpOptions = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        method: 'GET',
        withCredentials: true
    };

    const requestPath = `/uarReport?id=${id}&isPasswordless=${isPasswordless}`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
