// React Imports
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// MUI Imports
import { Autocomplete, Box, Button, CircularProgress, Divider, Grid, List, ListItem, ListItemText, Modal, TextField, Typography } from '@mui/material';

import { reportStore } from 'stores';

function AuditorModal({ auditors, handleNo, handleYes, isLoading, isOpen, reports }) {
    const [searchParams] = useSearchParams();
    const redirectInfo = JSON.parse(searchParams.get('redirectInfo'));

    const [inputValue, setInputValue] = useState('');
    const [selection, setSelection] = useState(redirectInfo?.selectedReport ?? null);

    const projectHasAuditors = auditors.length > 0;
    const isSendDisabled = !selection || !projectHasAuditors;

    const reportOptions = reports.map((report, index) => {
        return { key: index, label: report.name };
    });

    //Functions
    /**
     * Function to handle pressing Cancel on the modal
     */
    const handleCancel = () => {
        //Close Modal
        handleNo();
    };

    /**
     * Function to to handle a selection from either pre or post selection
     * @param {Object} event Event Object from markup
     */
    const handleSelection = (newValue) => {
        setSelection(newValue);
        reportStore.setState('diffReportRedirectInfo', { selectedReport: newValue });
    };

    return (
        <React.Fragment>
            <Modal open={isOpen}>
                <Box
                    sx={{
                        textAlign: 'center',
                        color: 'text.primary',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 500,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4
                    }}
                >
                    <Typography sx={{ p: 6 }} color="primary" id="modal-modal-title" variant="h6" component="h2">
                        Select Report to Compare
                    </Typography>
                    <Divider />
                    {!isLoading && (
                        <React.Fragment>
                            <Grid container alignContent="center" spacing={2} sx={{ minHeight: 200 }}>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Autocomplete
                                        clearOnEscape
                                        id="InitialReport"
                                        inputValue={inputValue}
                                        isOptionEqualToValue={(option, value) => option.key === value.key}
                                        options={reportOptions}
                                        onChange={(event, newValue) => handleSelection(newValue)}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Report" variant="standard" />}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={option.key}>
                                                    {option.label}
                                                </li>
                                            );
                                        }}
                                        sx={{ width: 200, mb: 10 }}
                                        value={selection}
                                    />
                                </Grid>
                            </Grid>
                            <Divider />
                            <Typography sx={{ p: 6 }} color="primary" id="modal-modal-title" variant="h6" component="h2">
                                Send to these Auditors
                            </Typography>
                            <Divider />
                            <Grid container sx={{ textAlign: 'center', width: '100%', minHeight: 100 }} justifyContent="center">
                                <List>
                                    {auditors.map((auditor, index) => (
                                        <ListItem sx={{ textAlign: 'center' }} key={index}>
                                            <ListItemText key={index}>{auditor.email}</ListItemText>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                            <Divider />
                            <Box sx={{ pt: 8, pb: 4 }}>
                                <Button sx={{ mr: 20 }} variant="contained" onClick={handleCancel}>
                                    Cancel
                                </Button>
                                <Button sx={{ pl: 10, pr: 10 }} variant="contained" onClick={() => handleYes(selection)} disabled={isSendDisabled}>
                                    Send
                                </Button>
                            </Box>
                        </React.Fragment>
                    )}
                    {isLoading && (
                        <Box sx={{ minHeight: 150, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                            <CircularProgress />
                        </Box>
                    )}
                </Box>
            </Modal>
        </React.Fragment>
    );
}

export default AuditorModal;
