// React Imports
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
// MUI Imports
import { Alert, Box, Grid, IconButton, Paper, Stack, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CircleIcon from '@mui/icons-material/Circle';
import { DataGrid } from '@mui/x-data-grid';
// Component Imports
import { RenderExpandableCell } from 'components';
import RemediationTaskCard from './RemediationTaskCard';
import { useReport } from 'features/reports';
// Utils
import { authStore } from 'stores';

const COLUMNS_TO_HIDE_ON_CARD_OPEN = { approvalType: false, permissionName: false };

function RemediationTaskTable({ reportId }) {
    const [passwordSession] = authStore.useState('passwordSession');
    //const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    //const [remediationTasks, setRemediationTasks] = useState({});
    const [selectedRow, setSelectedRow] = useState(null);

    const theme = useTheme();
    const reportHook = useReport(reportId, null, 'approval', true);
    const { report, isLoading } = reportHook;
    const [columnVisibilityModel, setColumnVisibilityModel] = useState();

    const rejectedApprovals = report
        ? report.approvalRows.reduce((allRejectedApprovals, row, approvalRowIndex) => {
              const rejectedApprovalsGroup = row.approvals.reduce((acc, approval, approvalIndex) => {
                  if (approval.status === 'Rejected') {
                      acc.push({
                          ...approval,
                          approvalIndex,
                          approvalRowIndex,
                          managerEmail: row.managerEmail,
                          managerName: row.managerName,
                          managerRole: row.managerRole,
                          userEmail: row.userEmail,
                          userName: row.name,
                          userRole: row.userRole
                      });
                  }
                  return acc;
              }, []);
              allRejectedApprovals = [...allRejectedApprovals, ...rejectedApprovalsGroup];
              return allRejectedApprovals;
          }, [])
        : [];
    console.log('rejectedApprovals = ', rejectedApprovals);

    const [columns] = useState([
        {
            field: 'managerName',
            flex: 1,
            headerName: 'Manager Name',
            renderCell: (cellValues) => {
                return <RenderExpandableCell {...cellValues} />;
            }
        },
        {
            field: 'userName',
            flex: 1,
            headerName: 'Employee',
            renderCell: (cellValues) => {
                return <RenderExpandableCell {...cellValues} />;
            }
        },
        {
            field: 'approvalType',
            flex: 1,
            headerName: 'Type',
            renderCell: (cellValues) => {
                return <RenderExpandableCell {...cellValues} />;
            },
            valueGetter: (params) => (params.row.approvalType === 'ObjectAccess' ? 'Object Access' : 'System Permission')
        },
        {
            field: 'permissionName',
            flex: 1,
            headerName: 'Permission Name',
            renderCell: (cellValues) => {
                return <RenderExpandableCell {...cellValues} />;
            },
            valueGetter: (params) => (params.row.approvalType === 'ObjectAccess' ? params.row.name : params.row.label)
        },
        {
            field: 'remediationTask.status',
            flex: 1,
            headerName: 'Status',
            renderCell: (cellValues) => {
                const statusColor = cellValues.row.remediationTask.status === 'Pending' || cellValues.row.remediationTask.status === 'Cancelled' ? 'orange' : theme.palette.text.success;
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                        <CircleIcon
                            fontSize="small"
                            sx={{
                                color: statusColor,
                                filter: `drop-shadow(0 0 4px ${statusColor})`
                            }}
                        ></CircleIcon>
                        <Typography display="inline" variant="body1" color={theme.palette.text.secondary}>
                            {cellValues.row.remediationTask.status}
                        </Typography>
                    </Box>
                );
            }
        }
    ]);

    /**
     * When a row is selected, display a card with additional details
     * @param {Object} event contains details for the selected row
     */
    const handleRowSelection = (event) => {
        console.log('selected row');
        const row = event.row;
        if (row.remediationTask._id === selectedRow?.remediationTask._id) {
            // Shrink the remediation task card if the same row is clicked again.
            setSelectedRow(null);
            setColumnVisibilityModel({});
        } else {
            setSelectedRow(row);
            setColumnVisibilityModel(COLUMNS_TO_HIDE_ON_CARD_OPEN);
        }
    };

    /**
     * Updates the status of a task to complete
     * @param {Object} updatedTask updated task with the status of complete.
     */
    const updateSelectedRowStatus = async (updatedTask) => {
        setSelectedRow((prevRow) => ({ ...prevRow, ...updatedTask }));
    };

    return (
        <Paper elevation={6} sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Toolbar
                sx={{
                    ml: { sm: -4 },
                    pr: { xs: 1, sm: 1 }
                }}
            >
                <React.Fragment>
                    <Grid container>
                        <Grid item xs={3}>
                            <Stack sx={{ pb: 8, textAlign: 'left' }}>
                                <Link to={`/${passwordSession.sessionDetails.namespace}/project/${id}`}>
                                    <IconButton aria-label="open" color="primary">
                                        <KeyboardArrowLeftIcon />
                                        <Typography variant="body2">Project</Typography>
                                    </IconButton>
                                </Link>
                                <Typography sx={{ flex: '1 1 100%', textAlign: 'left', paddingLeft: '1rem' }} variant="h6" id="tableTitle">
                                    Remediation Tasks
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={9} sx={{ alignSelf: 'center' }}>
                            {rejectedApprovals.reduce((retVal, v) => {
                                return retVal || v.status === 'Cancelled';
                            }, false) && (
                                <Alert severity="warning" sx={{ m: 5 }}>
                                    You have at least 1 remediation task that has been cancelled. Please review your Salesforce and revert/make the appropriate changes.
                                </Alert>
                            )}
                        </Grid>
                    </Grid>
                </React.Fragment>
            </Toolbar>
            <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
                <Box
                    sx={{
                        flex: selectedRow ? '0 0 40%' : '1 1 100%',
                        transition: 'flex 0.5s',
                        overflow: 'hidden'
                    }}
                >
                    <DataGrid
                        sx={{ flex: 1 }}
                        rows={rejectedApprovals}
                        getRowId={(row) => row.remediationTask._id}
                        columnVisibilityModel={columnVisibilityModel}
                        columns={columns}
                        rowHeight={50}
                        loading={isLoading}
                        onRowClick={handleRowSelection}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flex: selectedRow ? '0 0 60%' : '0 0 0%',
                        transition: 'flex 0.5s',
                        overflow: 'hidden'
                    }}
                >
                    {selectedRow && <RemediationTaskCard rejectedApproval={selectedRow} reportHook={reportHook} updateSelectedRowStatus={updateSelectedRowStatus}></RemediationTaskCard>}
                </Box>
            </Box>
        </Paper>
    );
}

export default RemediationTaskTable;
