// React Imports
import React, { useState } from 'react';
// MUI Imports
import { Grid, FormControlLabel, Switch, Skeleton, Stack } from '@mui/material';
//Component Imports
import { ObjectSettingList, SystemSettingList } from 'features/salesforce';
import { reportStore } from 'stores';

function KeyPermissions() {
    const [currentOrg] = reportStore.useState('currentOrg');
    const [defaultSettingsOn, setDefaultSettingsOn] = useState(true);
    const [defaultObjectsOn, setDefaultObjectsOn] = useState(true);
    const [isLoading] = useState(false);
    const [, setSelectedObjects] = reportStore.useState('selectedObjects');
    const [, setSelectedSystemSettings] = reportStore.useState('selectedSystemSettings');

    const selectReccomendedSettings = async (event, userToggled) => {
        if (event.target.checked) {
            setDefaultSettingsOn(true);
            if (userToggled) {
                setSelectedSystemSettings(currentOrg.defaultSystemSettings);
            }
        } else {
            setDefaultSettingsOn(false);
            if (userToggled) {
                setSelectedSystemSettings([]);
            }
        }
    };

    const selectReccomendedObjects = async (event) => {
        if (event.target.checked) {
            setSelectedObjects(currentOrg.defaultObjects);
            setDefaultObjectsOn(true);
        } else {
            setSelectedObjects([]);
            setDefaultObjectsOn(false);
        }
    };

    return (
        <React.Fragment>
            <Grid container spacing={30} sx={{ flexWrap: 'nowrap' }}>
                <Grid item sx={{ flex: 1 }}>
                    {isLoading ? (
                        <Stack spacing={5} sx={{ width: '40vw' }}>
                            <Skeleton variant="rounded" height={20} />
                            <Skeleton variant="rounded" height={60} />
                            <Skeleton variant="rounded" height={400} />
                        </Stack>
                    ) : (
                        <Grid container spacing={10} flexDirection={'column'}>
                            <React.Fragment>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Switch checked={defaultSettingsOn} onChange={(event) => selectReccomendedSettings(event, true)} />}
                                        label="Use Default System Settings"
                                    />
                                </Grid>
                                <Grid item sx={{ height: 500, width: '40vw' }}>
                                    <SystemSettingList setDefaultSettingsOn={setDefaultSettingsOn}></SystemSettingList>
                                </Grid>
                            </React.Fragment>
                        </Grid>
                    )}
                </Grid>
                <Grid item sx={{ flex: 1 }}>
                    {isLoading ? (
                        <Stack spacing={5} sx={{ width: '40vw' }}>
                            <Skeleton variant="rounded" height={20} />
                            <Skeleton variant="rounded" height={60} />
                            <Skeleton variant="rounded" height={400} />
                        </Stack>
                    ) : (
                        <Grid container spacing={10} flexDirection={'column'}>
                            <React.Fragment>
                                <Grid item>
                                    <FormControlLabel control={<Switch checked={defaultObjectsOn} onChange={selectReccomendedObjects} />} label="Use Default Objects" />
                                </Grid>
                                <Grid item sx={{ height: 500, width: '40vw' }}>
                                    <ObjectSettingList defaultsOptionEnabled setDefaultObjectsOn={setDefaultObjectsOn}></ObjectSettingList>
                                </Grid>
                            </React.Fragment>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default KeyPermissions;
