import React from 'react';
// MUI Imports
import { Stack, Chip } from '@mui/material';

const ChipList = ({ list }) => {
    return (
        <Stack sx={{ flexWrap: 'wrap', gap: 5 }} flex={1} direction={'row'}>
            {list.map((value) => (
                <Chip key={`chipList:${value}`} label={value} p={2} />
            ))}
        </Stack>
    );
};
export default ChipList;
