// React Imports
import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
// MUI Imports
import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
// Component Imports
import OrgDialog from './OrgDialog';
// API Imports
import { getOrgsByAccount } from '../services/getOrgsByAccount';
//File Imports
import { alertMessages } from 'data/alerts';
import { miscStore, reportStore } from 'stores';

function OrgCombobox() {
    //useStates
    const [, , updateAlert] = miscStore.useState('alert');
    const [currentOrg, setCurrentOrg] = reportStore.useState('currentOrg');
    const isMounted = useRef(true);
    const [openOrgDialog, setOpenOrgDialog] = useState(false);
    const [orgs, setOrgs] = useState({});
    const [searchParams] = useSearchParams();
    const [selectIsOpen, setSelectIsOpen] = useState(false);

    //useEffects
    /**
     * Retrieves the Salesforce Orgs for the current user session. Runs on first render only.
     */
    useEffect(() => {
        const initializeComponentData = async () => {
            // Get orgs for the combox
            let _orgs = await getOrgsByAccount();
            _orgs = _orgs.reduce((result, val) => {
                result[val._id] = val;
                return result;
            }, {});
            if (isMounted.current) {
                setOrgs(_orgs);
            }

            // Check if redirected to this page after auth
            const authResult = searchParams.get('authResult');
            if (authResult === 'success') {
                postAlert('success', alertMessages.success.auth);
            } else if (authResult === 'failure') {
                const errorPayload = JSON.parse(searchParams.get('errorPayload'));
                postAlert('error', errorPayload.message);
            }
        };
        isMounted.current = true;
        initializeComponentData();

        return () => {
            isMounted.current = false;
        };
    }, [searchParams]);

    //Functions
    /**
     * @description Handles the closing of the Org dialog.
     */
    const handleAddOrgClose = () => {
        setOpenOrgDialog(false);
    };

    /**
     * @description Handles the opening of the Org dialog.
     */
    const handleAddOrgOpen = () => {
        setOpenOrgDialog(true);
    };

    /**
     * Function to close the toast notification
     */
    const handleAlertClose = () => {
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    /**
     * Triggers a global alert.
     * @param {String} severity severity of the alert
     * @param {String} message message to display
     */
    const postAlert = (severity, message) => {
        updateAlert((alert) => {
            alert.autoHideDuration = 3000;
            alert.message = message;
            alert.open = true;
            alert.severity = severity;
            alert.onClose = handleAlertClose;
        });
    };

    /**
     * Kicks off the uaht process if no access token is detected and sets the new org.
     * @param {Object} event the newly selected org
     */
    const startAuthorizeProcess = (event) => {
        const selectedOrg = orgs[event.target.value];
        setCurrentOrg(selectedOrg);
    };

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex' }}>
                <Grid container spacing={8} sx={{ paddingTop: 4, width: '25vw' }}>
                    <Grid item xs={12}>
                        {Object.values(orgs).length > 0 && (
                            <FormControl size="small" fullWidth required>
                                <InputLabel>Salesforce Org</InputLabel>
                                <Select
                                    value={currentOrg._id || 'none'}
                                    label="Salesforce Org"
                                    onChange={startAuthorizeProcess}
                                    open={selectIsOpen}
                                    onOpen={() => setSelectIsOpen(true)}
                                    onClose={() => setSelectIsOpen(false)}
                                >
                                    <MenuItem value="none" disabled>
                                        <em>Select an Org...</em>
                                    </MenuItem>
                                    <MenuItem onClick={handleAddOrgOpen}>-- Add org --</MenuItem>
                                    {Object.values(orgs).map((org) => (
                                        <MenuItem key={org._id} value={org._id}>
                                            {org.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                </Grid>
                <OrgDialog open={openOrgDialog} onClose={handleAddOrgClose} orgs={orgs}></OrgDialog>
            </Box>
        </React.Fragment>
    );
}

export default OrgCombobox;
