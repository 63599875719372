import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { get } from 'node-emoji';

function StripeCallback() {
    let navigate = useNavigate();
    const { status } = useParams();
    useEffect(() => {
        if (status === 'success') {
            navigate('/', { replace: true });
        }
    }, [status]);

    return (
        <div className="App">
            <header className="App-header">
                <h1 className="App-title">Stripe Callback</h1>
                {status === 'canceled' && <h4>{get('grimacing')}</h4>}
            </header>
        </div>
    );
}

export default StripeCallback;
