import { Button, Stack, Typography } from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

/**
 * TryAgain component renders a message and a button to retry an action.
 * This component is a visual representation of an error state with an actionable response.
 * @param {Function} tryAgain - Callback function that is called when the 'Try Again' button is clicked.
 */
function TryAgain({ tryAgain }) {
    // Displays an icon, error message, and a 'Try Again' button
    /**
     * Renders a stack container with an icon, a text message indicating an
     * error, and button to retry the action that caused the error.
     */
    return (
        <Stack sx={{ alignItems: 'center' }} spacing={8}>
            <SentimentVeryDissatisfiedIcon color="primary" sx={{ fontSize: 40 }} />
            <Typography color={'text.primary'}>Opps! Something went wrong.</Typography>
            <Button variant="contained" onClick={tryAgain}>
                Try Again
            </Button>
        </Stack>
    );
}

// Exports the TryAgain component for use in other parts of the app
export default TryAgain;
