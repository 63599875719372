import { CognitoUser } from 'amazon-cognito-identity-js';

import { userPoolPasswordless } from '../data/constants';

/**
 * Helper method to generate the Cognito User Object.
 * @param {String} email username of the Cognito User
 * @returns the Cognito User Object for the provided username.
 */
export const getCognitoUserPasswordless = (email) => {
    const userData = {
        Username: email,
        Pool: userPoolPasswordless
    };
    const cognitoUser = new CognitoUser(userData);

    return cognitoUser;
};
