import { Box, Alert } from '@mui/material';

const FreeTrialAlert = () => {
    return (
        <Box>
            <Alert variant="outlined" severity="warning">
                You are currently using a free trial.
            </Alert>
        </Box>
    );
};

export default FreeTrialAlert;
