// React Imports
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';

// MUI Imports
import { Paper, IconButton, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import SummarizeIcon from '@mui/icons-material/Summarize';

// Component Imports
import NoRowsAlert from 'components/ui/NoRowsAlert';
import TableHeader from 'components/ui/TableHeader';

// API imports
import { getUARReportsByAcc } from '../services/getUARReportsByAcc';
import { authStore } from 'stores';

function RecentlyViewedReports() {
    const [passwordSession] = authStore.useState('passwordSession');
    const isMounted = useRef(true);
    const [isLoading, setIsLoading] = useState(true);
    const [pageSize, setPageSize] = useState();
    const [recentReports, setRecentReports] = useState([]);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const columns = [
        { field: 'name', headerName: 'Report Title', flex: 1 },
        {
            field: 'status',
            headerName: 'Status',
            renderCell: (cellValues) => {
                return isSmallScreen ? getStatusIcon(cellValues.value) : cellValues.value;
            }
        },
        {
            field: 'dueDate',
            headerName: 'Due Date',
            type: 'date',
            renderCell: (cellValues) => new Date(cellValues.value).toLocaleDateString()
        },
        {
            headerName: '',
            field: 'reportNav',
            align: 'center',
            sortable: false,
            filterable: false,
            renderCell: (cellValues) => {
                return (
                    <Link to={`/${passwordSession.sessionDetails.namespace}/project/${cellValues.row.projId}/uar-report/${cellValues.row._id}`}>
                        <IconButton aria-label="open" color="primary">
                            <KeyboardArrowRightIcon />
                        </IconButton>
                    </Link>
                );
            }
        }
    ];

    useEffect(() => {
        isMounted.current = true;
        //Call uar_reports API to get all reports related to the account
        const getReports = async () => {
            const reports = await getUARReportsByAcc({ status: { $ne: 'Deleted' } });
            if (isMounted.current) {
                setRecentReports(reports);
                setIsLoading(false);
            }
        };
        getReports();

        return () => {
            isMounted.current = false;
        };
    }, []);

    const getStatusIcon = (status) => {
        if (status === 'Completed') {
            return <CheckCircleOutlineIcon sx={{ color: 'lightgreen' }} />;
        } else if (status === 'Ready to Send') {
            return <MoreHorizIcon color="primary" />;
        } else if (status === 'Pending Approvals') {
            return <PendingActionsIcon sx={{ color: 'orange' }} />;
        }
    };

    /**
     * When resizing the window to a small screen, the autoPageSize prop on DataGrid kicks in
     * and calulates the num of rows to show to prevent scrolling. We store that value in
     * pageSize and pass it to the pageSize prop.
     * @param {Number} numOfRows the number of rows to show in the table.
     */
    const handlePageSizeChange = (numOfRows) => {
        setPageSize(numOfRows);
    };

    return (
        <React.Fragment>
            <Paper elevation={6} sx={{ display: 'flex', flexDirection: 'column', height: 400 }}>
                <TableHeader icon={<SummarizeIcon color="primary" />} title="Recently Viewed Reports" />
                <DataGrid
                    autoPageSize={isSmallScreen ? true : false}
                    columns={columns}
                    components={{ NoRowsOverlay: () => <NoRowsAlert title="Recently Viewed Reports" message="No available Report history."></NoRowsAlert> }}
                    disableSelectionOnClick
                    getRowId={(row) => row._id}
                    loading={isLoading}
                    onPageSizeChange={handlePageSizeChange}
                    pageSize={isSmallScreen ? pageSize : 100}
                    rows={recentReports}
                    rowHeight={50}
                    rowsPerPageOptions={[100]}
                ></DataGrid>
            </Paper>
        </React.Fragment>
    );
}

export default RecentlyViewedReports;
