// React Imports
import React, { useState } from 'react';
// MUI Imports
import { DataGrid } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
// Component Imports
import ApprovalOptions from './ApprovalOptions';

const STATUS_WIDTH = 160;

function ManagerApprovalsCrudTable({ approvals, useReport }) {
    const [columns] = useState([
        {
            field: 'name',
            flex: 1,
            headerName: 'SObject'
        },
        {
            field: 'create',
            align: 'center',
            flex: 0,
            headerName: 'Create',
            renderCell: (cellValue) => (cellValue.row.create.value ? <CheckIcon sx={{ color: 'text.success' }} /> : <CloseIcon sx={{ color: 'text.error' }} />)
        },
        {
            field: 'read',
            align: 'center',
            flex: 0,
            headerName: 'Read',
            renderCell: (cellValue) => (cellValue.row.read.value ? <CheckIcon sx={{ color: 'text.success' }} /> : <CloseIcon sx={{ color: 'text.error' }} />)
        },
        {
            field: 'edit',
            align: 'center',
            flex: 0,
            headerName: 'Edit',
            renderCell: (cellValue) => (cellValue.row.edit.value ? <CheckIcon sx={{ color: 'text.success' }} /> : <CloseIcon sx={{ color: 'text.error' }} />)
        },
        {
            field: 'delete',
            align: 'center',
            flex: 0,
            headerName: 'Delete',
            renderCell: (cellValue) => (cellValue.row.delete.value ? <CheckIcon sx={{ color: 'text.success' }} /> : <CloseIcon sx={{ color: 'text.error' }} />)
        },
        {
            field: 'viewAll',
            align: 'center',
            flex: 0,
            headerName: 'View All',
            renderCell: (cellValue) => (cellValue.row.viewAll.value ? <CheckIcon sx={{ color: 'text.success' }} /> : <CloseIcon sx={{ color: 'text.error' }} />)
        },
        {
            field: 'modifyAll',
            align: 'center',
            flex: 0,
            headerName: 'Modify All',
            renderCell: (cellValue) => (cellValue.row.modifyAll.value ? <CheckIcon sx={{ color: 'text.success' }} /> : <CloseIcon sx={{ color: 'text.error' }} />)
        },
        {
            field: 'status',
            align: 'center',
            flex: 1,
            minWidth: STATUS_WIDTH,
            headerName: 'Approval Status',
            renderCell: (cellValue) => {
                return <ApprovalOptions approval={cellValue.row} useReport={useReport}></ApprovalOptions>;
            }
        }
    ]);

    return <DataGrid rows={approvals} getRowId={(row) => row._id} columns={columns} getRowHeight={() => 'auto'} />;
}

export default ManagerApprovalsCrudTable;
