import { useTheme } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import GrantedByInfo from './GrantedByInfo';

export default function ObjectAccessInfo({ rejectedApproval }) {
    const theme = useTheme();
    return (
        <Stack sx={{ pl: 8, overflow: 'scroll' }} spacing={4}>
            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                <Typography color="primary" variant="body1" component="div">
                    <b>Object:</b>{' '}
                    <Typography display="inline" variant="body1" color={theme.palette.text.secondary}>
                        {rejectedApproval.name}
                    </Typography>
                </Typography>
                <Typography color="primary" variant="body1" component="div">
                    <b>Permission Type:</b>{' '}
                    <Typography display="inline" variant="body1" color={theme.palette.text.secondary}>
                        {rejectedApproval.approvalType.replace(/([A-Z])/g, ' $1').trim()}
                    </Typography>
                </Typography>
            </Stack>
            <Typography sx={{ textAlign: 'left' }} color="primary" variant="body1" component="div">
                <b>Reason for Rejection:</b>{' '}
                <Typography display="inline" variant="body1" color={theme.palette.text.secondary}>
                    {rejectedApproval.remediationTask.rejectionReason}
                </Typography>
            </Typography>
            <Typography sx={{ textAlign: 'left' }} color="primary" variant="body1" component="div">
                <b>Access Type:</b>
                {rejectedApproval.remediationTask.relevantObjectAccess.length === 0 && (
                    <Typography display="inline" variant="body1" color={theme.palette.text.secondary}>
                        {' '}
                        None Selected
                    </Typography>
                )}
            </Typography>
            <Box>
                {rejectedApproval.remediationTask.relevantObjectAccess.map((accessType) => (
                    <Accordion key={accessType.value}>
                        <AccordionSummary
                            sx={{ backgroundColor: theme.palette.background.default, pl: 4 }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel-${accessType.value}-content`}
                            id={`panel-${accessType.value}-header`}
                        >
                            <Stack direction="row">
                                <Typography sx={{ textAlign: 'left' }} variant="body1" component="div">
                                    {`${accessType.label} - `}
                                </Typography>
                                <Typography sx={{ display: 'flex', alignItems: 'center' }} color="primary" variant="body2">
                                    <PersonIcon />
                                    {`(${rejectedApproval[accessType.value].grantedBy.profile ? 1 : 0})`}
                                    <GroupIcon sx={{ pl: 5 }} />
                                    {`(${rejectedApproval[accessType.value].grantedBy.permissionSets.length})`}
                                </Typography>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails sx={{ backgroundColor: theme.palette.background.default, p: 5 }}>
                            <GrantedByInfo grantedBy={rejectedApproval[accessType.value].grantedBy} />
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Stack>
    );
}
