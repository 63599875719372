import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to set a profile picture for the user.
 * @param {Object} fileData An object containing the base64 rawData and cognitoSubId key properties.
 * @returns the signed url for the new profile picture
 */
export const setProfilePicture = async (fileData) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        body: JSON.stringify(fileData)
    };
    const requestPath = `/account/profilePicture`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
