import { authStore } from 'stores';
import { v4 as uuidv4 } from 'uuid';

const BASE_API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';

export const fetchData = async (path, options) => {
    const requestPath = BASE_API_URL + path;
    const response = await fetch(requestPath, options);
    if (!response.ok) {
        switch (response.status) {
            case 401:
                authStore.setState('sessionError', uuidv4());
                break;
            case 400:
                break;
            default: {
                const err = JSON.parse(await response.text());
                console.error('err = ', err);
                throw { statusCode: response.status, ...err };
            }
        }
    }

    switch (response.status) {
        case 204:
            return { response };
        default: {
            const data = await response.json();
            return { data, response };
        }
    }
};
