import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import GrantedByInfo from './GrantedByInfo';

export default function SystemSettingInfo({ rejectedApproval }) {
    const theme = useTheme();
    const hasAccess = rejectedApproval.access.grantedBy.profile || rejectedApproval.access.grantedBy.permissionSets.length > 0;

    return (
        <Stack sx={{ pl: 8 }} spacing={4}>
            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                <Typography color="primary" variant="body1" component="div">
                    <b>{rejectedApproval.approvalType === 'ObjectAccess' ? 'Salesforce Object' : 'System Setting'}:</b>{' '}
                    <Typography display="inline" variant="body1" color={theme.palette.text.secondary}>
                        {rejectedApproval.approvalType === 'ObjectAccess' ? rejectedApproval.name : rejectedApproval.label}
                    </Typography>
                </Typography>
                <Typography color="primary" variant="body1" component="div">
                    <b>Permission Type:</b>{' '}
                    <Typography display="inline" variant="body1" color={theme.palette.text.secondary}>
                        {rejectedApproval.approvalType.replace(/([A-Z])/g, ' $1').trim()}
                    </Typography>
                </Typography>
            </Stack>
            <Typography sx={{ textAlign: 'left' }} color="primary" variant="body1" component="div">
                <b>Description:</b>{' '}
                <Typography display="inline" variant="body1" color={theme.palette.text.secondary}>
                    {rejectedApproval.description}
                </Typography>
            </Typography>
            <Typography sx={{ textAlign: 'left' }} color="primary" variant="body1" component="div">
                <b>Reason for Rejection:</b>{' '}
                <Typography display="inline" variant="body1" color={theme.palette.text.secondary}>
                    {rejectedApproval.remediationTask.rejectionReason}
                </Typography>
            </Typography>
            <Typography sx={{ textAlign: 'left' }} color="primary" variant="body1" component="div">
                <b>Granted By:</b>{' '}
                {!hasAccess && (
                    <Typography display="inline" variant="body1" color={theme.palette.text.secondary}>
                        None
                    </Typography>
                )}
            </Typography>
            {hasAccess && (
                <Box sx={{ display: 'flex', backgroundColor: theme.palette.background.default, p: 6 }}>
                    <GrantedByInfo grantedBy={rejectedApproval.access.grantedBy} />
                </Box>
            )}
        </Stack>
    );
}
