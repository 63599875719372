//React Imports
import { useEffect, useState } from 'react';
//MUI Imports
import { Box } from '@mui/material';
//File Imports
import { getProjectsByAccount, HistoricalDataTable } from 'features/projects';
import { getUARReportsByAcc } from 'features/reports';

function ProjectHistory() {
    //Use States
    const [projects, setProjects] = useState({});

    //Use Effects
    useEffect(() => {
        //Call project API to retrieve all projects associated with the account
        const callGetProjects = async () => {
            try {
                const projectData = await getProjectsByAccount({ status: { $ne: 'Open' } });
                const tempObj = projectData.reduce((projects, proj) => {
                    projects[proj._id] = proj;
                    projects[proj._id].reports = [];
                    return projects;
                }, {});

                //Call uar_report API to retrieve all uar_reports associated with the account
                const reportData = await getUARReportsByAcc({});
                reportData.forEach((report) => {
                    tempObj[report.projId]?.reports.push(report);
                });

                setProjects(tempObj);
            } catch (err) {
                console.log('Error retrieving projects: ', err);
            }
        };
        callGetProjects();
    }, []);

    return (
        <>
            <Box sx={{ display: 'flex', flex: 1 }}>
                <HistoricalDataTable projects={projects}></HistoricalDataTable>
            </Box>
        </>
    );
}
export default ProjectHistory;
