import React from 'react';
import { Alert, Snackbar } from '@mui/material';
import { miscStore } from 'stores';

/**
 * Displays a global alert message using Snackbar and Alert from MUI.
 * Alert visibility and properties are derived from the miscStore's state.
 */
function GlobalAlert() {
    const [alert] = miscStore.useState('alert');

    return (
        <Snackbar anchorOrigin={{ vertical: alert.vertical, horizontal: alert.horizontal }} open={alert.open} autoHideDuration={alert.autoHideDuration} onClose={alert.onClose}>
            <Alert onClose={alert.onClose} severity={alert.severity} sx={{ width: alert.width }} variant={alert.variant}>
                {alert.message}
            </Alert>
        </Snackbar>
    );
}

export default GlobalAlert;
