// React Imports
import React, { useCallback } from 'react';
// MUI Imports
import { Box, Paper, TableContainer } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
// Component Imports
import RenderExpandableCell from 'components/ui/RenderExpandableCell';
// External Imports
import { miscStore } from 'stores';
import { alertMessages } from 'data/alerts';

// Constants
const COLUMN_WIDTH = 170;
const COLUMN_WIDTH_2 = 250;

function UARSystemTable({ isAdmin, useReport }) {
    const { report, systemRows, updateUarRow } = useReport;

    // Constants
    const columnWidth = COLUMN_WIDTH;
    const columnWidth2 = COLUMN_WIDTH_2;
    const theme = useTheme();

    let columns = [
        {
            field: 'managerName',
            headerName: 'Manager',
            minWidth: columnWidth,
            editable: isAdmin ? true : false,
            renderCell: (cellValues) => {
                return <RenderExpandableCell {...cellValues} />;
            }
        },
        {
            field: 'managerEmail',
            headerName: 'Manager Email',
            minWidth: columnWidth2,
            editable: isAdmin ? true : false,
            renderCell: (cellValues) => {
                return <RenderExpandableCell {...cellValues} />;
            }
        },
        { field: 'userName', headerName: 'Employee', minWidth: columnWidth },
        { field: 'permissionType', headerName: 'Permission Type', minWidth: columnWidth },
        {
            field: 'permissionName',
            headerName: 'Permission Name',
            minWidth: columnWidth2,
            flex: 1,
            renderCell: (cellValues) => {
                return <RenderExpandableCell {...cellValues} />;
            }
        }
    ];

    // States
    const [, , updateAlert] = miscStore.useState('alert');

    /**
     * Generates the key settings columns and inserts them into the base columns array.
     */
    const generateColumns = () => {
        const keyColumns = report.keySystemSettings.map((setting) => {
            return {
                field: setting.name,
                headerName: setting.label,
                minWidth: columnWidth,
                renderCell: (cellValue) => (cellValue.value ? <CheckIcon sx={{ color: 'text.success' }} /> : <CloseIcon sx={{ color: 'text.error' }} />)
            };
        });
        columns.splice(5, 0, ...keyColumns);
        return columns;
    };

    //Functions

    /**
     * Function to close the toast notification
     */
    const handleAlertClose = () => {
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    /**
     * Handles any error during the process row update action.
     */
    const handleProcessRowUpdateError = useCallback((err) => {
        console.log(err);
        // Error popup message
        postAlert('error', alertMessages.error.uar_row_update);
    }, []);

    /**
     * Triggers a global alert.
     * @param {String} severity severity of the alert
     * @param {String} message message to display
     */
    const postAlert = (severity, message) => {
        updateAlert((alert) => {
            alert.autoHideDuration = 3000;
            alert.message = message;
            alert.open = true;
            alert.severity = severity;
            alert.onClose = handleAlertClose;
        });
    };

    /**
     * Takes the updated row and makes a call to the database to update.
     * @param {Object} updateInfo contains the details for the update.
     * @returns Updated document or error
     */
    const processRowUpdate = async (updateInfo, oldVal) => {
        const response = await updateUarRow(updateInfo, oldVal);

        postAlert('success', alertMessages.success.uar_row_update);
        return response;
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
                <Box sx={{ height: 600, width: '100%' }}>
                    <DataGrid
                        sx={{
                            '& .MuiDataGrid-columnHeader': {
                                backgroundColor: theme.palette.mode === 'light' ? '#f3b040' : 'f7f7f8'
                            }
                        }}
                        test="noHeader"
                        rows={systemRows}
                        getRowId={(row) => row._id}
                        columns={generateColumns()}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'managerName', sort: 'asc' }]
                            }
                        }}
                        rowHeight={50}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                        processRowUpdate={(val, oldVal) => processRowUpdate(val, oldVal)}
                        onProcessRowUpdateError={(err) => handleProcessRowUpdateError(err)}
                    />
                </Box>
            </TableContainer>
        </Paper>
    );
}

export default UARSystemTable;
