import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to retrieve an Org.
 * @param {String} id Id of the org to retrieve.
 * @returns org
 */
export const getOrg = async (id) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        method: 'GET',
        withCredentials: true
    };

    const requestPath = `/organization?Id=${id}`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
