import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { authStore, miscStore } from 'stores';

export const ProtectedLayout = () => {
    const location = useLocation();
    const isPasswordlessAuth = location?.pathname.includes('/uar-report-approval') ? true : false;

    if (isPasswordlessAuth) {
        const isAuthed = authStore.getState('passwordlessSession').value.isValid;
        if (!isAuthed) {
            return <Navigate to={`${location.pathname}/code-verification`} />;
        }
    } else {
        const isAuthed = authStore.getState('passwordSession').value.isValid;
        if (!isAuthed) {
            miscStore.setState('redirectPath', location.pathname);
            return <Navigate to={`login`} />;
        }
    }

    return <Outlet />;
};
