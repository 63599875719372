import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to get UAR Rows for an Account.
 * @param {Object} filterCriteria filters the rows to return
 * @returns UAR Rows
 */
export const getUARRowsByAcc = async (filterCriteria = {}) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        method: 'GET',
        withCredentials: true
    };

    const requestPath = `/uarRow/byAcc?filterCriteria=${encodeURIComponent(JSON.stringify(filterCriteria))}`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
