// ReactI mports
import React, { useState } from 'react';
// MUI Imports
import { Box, Backdrop, Button, Card, CardActions, CardContent, CircularProgress, Divider, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { useTheme } from '@mui/material/styles';
import ObjectAccessInfo from './ObjectAccessInfo';
import SystemSettingInfo from './SystemSettingInfo';
import ManagerInfo from './ManagerInfo';
import UserInfo from './UserInfo';
// API Imports

export default function RemediationTaskCard({ rejectedApproval, reportHook, updateSelectedRowStatus }) {
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const { completeRemediationTask } = reportHook;
    const statusColor = rejectedApproval.remediationTask.status === 'Pending' || rejectedApproval.remediationTask.status === 'Cancelled' ? 'orange' : theme.palette.text.success;

    /**
     * Marks a Remediation Task as complete.
     */
    const markComplete = async () => {
        setIsLoading(true);
        const result = await completeRemediationTask(rejectedApproval.approvalIndex, rejectedApproval.approvalRowIndex, { ...rejectedApproval.remediationTask });
        updateSelectedRowStatus(result);
        setIsLoading(false);
    };

    return (
        <Card sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', flex: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                    <CircleIcon
                        fontSize="small"
                        sx={{
                            color: statusColor,
                            filter: `drop-shadow(0 0 4px ${statusColor})`
                        }}
                    ></CircleIcon>
                    <Typography display="inline" variant="body1" color={theme.palette.text.secondary}>
                        {rejectedApproval.remediationTask.status}
                    </Typography>
                </Box>
                <Divider variant="middle" sx={{ width: '100%', mt: 6, mb: 6 }} />
                <Box sx={{ display: 'flex' }}>
                    <UserInfo rejectedApproval={rejectedApproval} />
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <ManagerInfo rejectedApproval={rejectedApproval} />
                </Box>
                <Divider variant="middle" sx={{ width: '100%', mt: 6, mb: 6 }} />
                {rejectedApproval.approvalType === 'ObjectAccess' ? <ObjectAccessInfo rejectedApproval={rejectedApproval} /> : <SystemSettingInfo rejectedApproval={rejectedApproval} />}
            </CardContent>
            <Divider variant="middle" sx={{ width: '100%', mt: 6, mb: 6 }} />
            <CardActions sx={{ justifyContent: 'center' }}>
                {(rejectedApproval.remediationTask.status === 'Pending' || rejectedApproval.remediationTask.status === 'Cancelled') && (
                    <Button variant="contained" onClick={markComplete}>
                        Mark Completed
                    </Button>
                )}
            </CardActions>
        </Card>
    );
}
