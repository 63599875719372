import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Function to upload Manager Data to S3
 * @param {String} fileName name of the file
 * @param {Object} newManagerData Data for new Manager Data in S3
 * @returns Response Object from API
 */
export const uploadManagerData = async (fileName, newManagerData) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        body: JSON.stringify({ fileName: fileName, csvContents: newManagerData })
    };

    const requestPath = `/managerData`;
    const { response } = await fetchData(requestPath, httpOptions);
    return response;
};
