// React Imports
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// MUI Imports
import { Box, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// Component Imports
import NoRowsAlert from 'components/ui/NoRowsAlert';
// Data
import { styles } from 'data/theme';
import { authStore } from 'stores';

function HistoricalDataTable({ projects }) {
    const [passwordSession] = authStore.useState('passwordSession');
    const PROJECT_COLUMNS = [
        { id: 'name', label: 'Project Title', minWidth: 170, align: 'left', rowAlign: 'left' },
        { id: 'status', label: 'Status', minWidth: 170, align: 'left', rowAlign: 'left' },
        { id: 'createdAt', label: 'Created Date', minWidth: 170, align: 'left', rowAlign: 'left', type: 'date' },
        { id: 'dueDate', label: 'Close Date', minWidth: 170, align: 'left', rowAlign: 'left', type: 'date' }
    ];
    const REPORT_COLUMNS = [
        { id: 'name', label: 'Name', minWidth: 170, align: 'left', rowAlign: 'left' },
        { id: 'status', label: 'Status', minWidth: 170, align: 'left', rowAlign: 'left' },
        { id: 'createdAt', label: 'Created Date', minWidth: 170, align: 'left', rowAlign: 'left', type: 'date' },
        { id: 'dueDate', label: 'Close Date', minWidth: 170, align: 'left', rowAlign: 'left', type: 'date' }
    ];
    const theme = useTheme();

    /**
     * Represents a row in the Projects table. Each row contains a nested table of child Reports for a Project.
     * @param {Object} props properties needed for generating the rows.
     * @returns row element containing project and report information.
     */
    function Row(props) {
        const { row } = props;
        const [open, setOpen] = useState(false);
        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                        {row.reports.length > 0 && (
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        )}
                    </TableCell>
                    {PROJECT_COLUMNS.map((column) => {
                        const value = column.type === 'date' && row[column.id] ? new Date(row[column.id]).toLocaleDateString() : row[column.id];
                        return column.id === 'name' ? (
                            <TableCell key={column.id}>
                                <Typography
                                    component={Link}
                                    to={`/${passwordSession.sessionDetails.namespace}/project/${row._id}`}
                                    sx={{ '&:hover': { color: theme.palette.primary.main, textDecoration: 'underline' }, textDecoration: 'none', color: theme.palette.text.primary }}
                                    variant="body2"
                                >
                                    {value}
                                </Typography>
                            </TableCell>
                        ) : (
                            <TableCell key={column.id}>
                                <Typography variant="body2">{value}</Typography>
                            </TableCell>
                        );
                    })}
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" gutterBottom component="div" sx={{ fontWeight: 'bold' }}>
                                    Reports
                                </Typography>
                                <Table size="small" aria-label="reports">
                                    <TableHead>
                                        <TableRow>
                                            {REPORT_COLUMNS.map((column) => (
                                                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                                        {column.label}
                                                    </Typography>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.reports.map((report) => (
                                            <TableRow key={report._id}>
                                                {REPORT_COLUMNS.map((column) => {
                                                    // Format Date values
                                                    const value = column.type === 'date' && report[column.id] ? new Date(report[column.id]).toLocaleDateString() : report[column.id];
                                                    return column.id === 'name' ? (
                                                        <TableCell key={column.id}>
                                                            <Typography
                                                                component={Link}
                                                                to={`/${passwordSession.sessionDetails.namespace}/project/${report.projId}/uar-report/${report._id}`}
                                                                sx={{
                                                                    '&:hover': { color: theme.palette.primary.main, textDecoration: 'underline' },
                                                                    textDecoration: 'none',
                                                                    color: theme.palette.text.primary
                                                                }}
                                                                variant="body2"
                                                            >
                                                                {value}
                                                            </Typography>
                                                        </TableCell>
                                                    ) : (
                                                        <TableCell key={column.id}>
                                                            <Typography variant="body2">{value}</Typography>
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    // States
    const [noProjectHistory, setNoProjectHistory] = useState(Object.values(projects).length === 0);
    const [rows, setRows] = useState([]);

    //useEffects
    /**
     * useEffect to set the rows of the project history table
     */
    useEffect(() => {
        setRows(Object.values(projects));
        setNoProjectHistory(Object.values(projects).length === 0);
    }, [projects]);

    return (
        <React.Fragment>
            <Paper sx={{ flex: 1 }}>
                <Toolbar
                    sx={{
                        pl: { sm: 6 },
                        pr: { xs: 1, sm: 1 }
                    }}
                >
                    {!noProjectHistory && (
                        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
                            Project History
                        </Typography>
                    )}
                </Toolbar>
                <TableContainer>
                    {noProjectHistory ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 18 }}>
                            <NoRowsAlert title="Project History" message="No Project history available."></NoRowsAlert>
                        </Box>
                    ) : (
                        <Table stickyHeader sx={{ ...styles.table }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" />
                                    {PROJECT_COLUMNS.map((column) => (
                                        <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                                {column.label}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <Row key={row._id} row={row} />
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </TableContainer>
            </Paper>
        </React.Fragment>
    );
}

export default HistoricalDataTable;
