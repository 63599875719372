// React Imports
import React, { useCallback, useState } from 'react';
// MUI Imports
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
// Component Imports
import LoadingIndicator from 'components/ui/LoadingIndicator';
// API Imports
import { getSObjectFields } from 'features/salesforce';
// Hooks
import { useSalesforceData } from 'features/salesforce';
// Helpers Imports
import { reportStore } from 'stores';

function SalesforceManagerFieldSelector() {
    const salesforceRequests = useCallback(() => [getSObjectFields(["'User'"], ['Hierarchy'])], []);
    const { data, isLoading } = useSalesforceData(salesforceRequests);
    const options = data[0];

    const [loadingSteps] = useState([
        {
            key: 0,
            label: 'Retrieving Salesforce Fields'
        }
    ]);
    const [managerInfo, , updateManagerInfo] = reportStore.useState('managerInfo');
    const [stepperIndex] = useState(0);

    /**
     * Resets the data stored in ManagerInfo
     */
    const clearManagerInfo = () => {
        updateManagerInfo((managerInfo) => {
            managerInfo.managerNameApi = '';
            managerInfo.managerField = {};
            managerInfo.managerReady = false;
            managerInfo.managerSource = '';
        });
    };

    /**
     * Lookup fields in Salesforce return an Id. To get the name of the record, we need to
     * get the path to Name field which is different depending on the type of field. This
     * method returns the correct path to access the Name.
     * @param {String} fieldType type of Salesforce field
     * @param {String} fieldName API name of the field
     * @returns
     */
    const getApiNameForManagerNameField = (fieldType, fieldName) => {
        if (fieldType === 'Hierarchy' && fieldName.endsWith('__c')) {
            return fieldName.substring(0, fieldName.length - 1) + 'r.Name';
        } else if (fieldType === 'Text' || fieldType === 'Text Area') {
            return fieldName;
        }
        return 'Manager.Name';
    };

    /**
     * Handles changes to the selected Manager field. Updates selected Manager and fieldAPIName
     * @param {Object} selectedField manager field selected.
     */
    const handleChange = (selectedField) => {
        // Set manager field states
        if (selectedField === null) {
            clearManagerInfo();
        } else {
            const managerNameApi = getApiNameForManagerNameField(selectedField.DataType, selectedField.QualifiedApiName);
            updateManagerInfo((managerInfo) => {
                managerInfo.managerNameApi = managerNameApi;
                managerInfo.managerField = selectedField;
                managerInfo.managerReady = true;
                managerInfo.managerSource = 'salesforce';
            });
        }
    };

    return (
        <React.Fragment>
            <Grid container spacing={0} direction="column" alignItems="center">
                {isLoading ? (
                    <LoadingIndicator loadActiveStep={stepperIndex} steps={loadingSteps}></LoadingIndicator>
                ) : (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ color: 'text.primary', mt: 10 }}>
                                Select Salesforce Manager Field
                            </Typography>
                        </Grid>
                        <Grid item xs={12} alignItems="center">
                            <Autocomplete
                                disablePortal
                                getOptionLabel={(option) => option.Label}
                                isOptionEqualToValue={(option, value) => option.QualifiedApiName === value.QualifiedApiName}
                                id="manager-field"
                                onChange={(event, value) => handleChange(value)}
                                options={options ?? []}
                                renderInput={(params) => <TextField {...params} label="Fields" />}
                                sx={{ width: 300, mt: 20, mb: 38 }}
                                value={managerInfo.managerField.QualifiedApiName ? { QualifiedApiName: managerInfo.managerField.QualifiedApiName, Label: managerInfo.managerField.Label } : null}
                            />
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </React.Fragment>
    );
}

export default SalesforceManagerFieldSelector;
