//React Imports
import React, { useState } from 'react';
//MUI Imports
import { Box, Button, Divider, Modal, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTheme } from '@mui/material/styles';
// Libraries
import * as dayjs from 'dayjs';
// Data Imports
import { alertMessages } from 'data/alerts';
import { miscStore } from 'stores';

function SendReportModal({ isLoading, isOpen, useReport, setIsOpen }) {
    const { managersByEmail, report, sendReport } = useReport;
    const columns = [
        { id: 'managerName', field: 'managerName', headerName: 'Name', headerAlign: 'center', minWidth: 80, align: 'left', flex: 1 },
        { id: 'managerEmail', field: 'managerEmail', headerName: 'Email', headerAlign: 'center', minWidth: 80, align: 'left', flex: 1 }
    ];
    const isFirstSend = report.status === 'Ready to Send' ? true : false;
    const [, , updateAlert] = miscStore.useState('alert');
    const [dueDate, setDueDate] = useState(() => {
        return isFirstSend ? null : dayjs(report.dueDate).endOf('day');
    });
    const [selectionModel, setSelectionModel] = useState([]);
    const theme = useTheme();

    /**
     * Function to close the toast notification
     */
    const handleAlertClose = () => {
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    /**
     * Function to close the Send Report Modal
     */
    const handleCancel = () => {
        setIsOpen(false);
    };

    /**
     * Handles the change in value for the Due Date field.
     * @param {Object} newValue new Date Value
     */
    const handleDateChange = (date) => {
        if (date) {
            // Roudn all entries down to the nearest hour
            date = dayjs(date).endOf('day');
        }
        setDueDate(date);
    };

    /**
     * Function to set the checkboxes in the datatable
     * @param {Array} selections Array of ids which are selected
     */
    const handleSelect = (selections) => {
        setSelectionModel(selections);
    };

    /**
     * Sends the report to the Managers for review.
     */
    const handleSendReport = async () => {
        try {
            await sendReport(selectionModel, dueDate);
            setSelectionModel([]);
            postAlert('success', alertMessages.success.uar_report_sent);
        } catch (err) {
            postAlert('error', alertMessages.error.uar_report_sent);
        } finally {
            setIsOpen(false);
        }
    };

    /**
     * Triggers a global alert.
     * @param {String} severity severity of the alert
     * @param {String} message message to display
     */
    const postAlert = (severity, message) => {
        updateAlert((alert) => {
            alert.autoHideDuration = 3000;
            alert.message = message;
            alert.open = true;
            alert.severity = severity;
            alert.onClose = handleAlertClose;
        });
    };

    return (
        <React.Fragment>
            <Modal open={isOpen}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                        color: 'text.primary',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        height: 700,
                        width: 600,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4
                    }}
                >
                    <Typography sx={{ p: 6 }} color="primary" id="modal-modal-title" variant="h6" component="h2">
                        Select Manager Recipients
                    </Typography>
                    <Divider sx={{ mb: 10 }} />
                    {report.dueDate === null && (
                        <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Due Date"
                                    value={dueDate}
                                    onChange={(val) => handleDateChange(val)}
                                    disablePast={true}
                                    renderInput={(params) => {
                                        return <TextField {...params} />;
                                    }}
                                    views={['year', 'month', 'day']}
                                />
                            </LocalizationProvider>
                        </Box>
                    )}
                    <DataGrid
                        sx={{
                            height: '77%',
                            mt: 10,
                            mb: 10,
                            '& .MuiDataGrid-columnHeader': {
                                backgroundColor: theme.palette.mode === 'light' ? theme.palette.secondary.main : theme.palette.background.default
                            }
                        }}
                        checkboxSelection={true}
                        columns={columns}
                        getRowId={(row) => row.managerEmail}
                        initialState={{
                            sorting: {
                                sortModel: [{ fields: 'Name', sort: 'asc' }]
                            }
                        }}
                        loading={isLoading}
                        pageSize={10}
                        rows={Object.values(managersByEmail)}
                        rowsPerPageOptions={[10]}
                        selectionModel={selectionModel}
                        onSelectionModelChange={handleSelect}
                    ></DataGrid>
                    <Divider />
                    <Box sx={{ pt: 8, pb: 4 }}>
                        <Button sx={{ mr: 20 }} variant="contained" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button sx={{ pl: 10, pr: 10 }} variant="contained" onClick={handleSendReport} disabled={selectionModel.length === 0 || (isFirstSend && dueDate === null ? true : false)}>
                            Send
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </React.Fragment>
    );
}

export default SendReportModal;
