import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to restore an orgs default objects/settings
 * @param {Object} currentOrg Details of the org to update.
 * @param {Object} type Type of defaults to restore, either setting or object.
 * @returns updated org.
 */
export const restoreOrgDefaults = async (currentOrg, type) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ currentOrg: currentOrg, type: type }),
        withCredentials: true
    };

    const requestPath = `/organization/restoreDefaults`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
