import { useMemo, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
// MUI Imports
import { Box, Button, Card, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
// Component Imports
import { CompletionIndicator, SplitButton, SureDialog } from '../../../components';
import { DueDateEditorDialog, exportUARReport } from '..';
// Helper Imports
import { downloadFile } from 'utils/downloadFile';
import { authStore, miscStore } from 'stores';
import { alertMessages } from 'data/alerts';
// Libraries
import * as dayjs from 'dayjs';

function ReportCard({ isAdminView, setSendReportIsOpen, useReport }) {
    const { closeApprovals, completeReport, deleteReport, managersByEmail, report, updateDueDate } = useReport;

    const pendingRemediationTasks = useMemo(
        () =>
            report?.approvalRows?.reduce((acc, approvalRow) => {
                const pendingTasks = approvalRow.approvals.filter((approval) => {
                    if (approval.status === 'Rejected') {
                        return approval.remediationTask.status === 'Pending';
                    }
                    return false;
                });
                acc = [...acc, ...pendingTasks];
                return acc;
            }, []),
        [report.approvalRows]
    );

    // TODO: Instead of stringifying we could store a count value on the report.
    const reviewedRows = useMemo(() => report.approvalRows.filter((approvalRow) => approvalRow.status === 'Completed'), [JSON.stringify(report.approvalRows)]);

    const [, , updateAlert] = miscStore.useState('alert');
    const dueDate = new Date(report.dueDate);
    const [extendDueDateOpen, setExtendDueDateOpen] = useState(false);
    const navigate = useNavigate();
    const [passwordSession] = authStore.useState('passwordSession');
    const sendReportDisabled = !isAdminView || managersByEmail.length <= 0;
    const [sureCloseApprovalsOpen, setSureCloseApprovalsOpen] = useState(false);
    const [sureCompleteReportOpen, setSureCompleteReportOpen] = useState(false);
    const [sureDeleteReportOpen, setSureDeleteReportOpen] = useState(false);
    const theme = useTheme();

    /**
     * Handles the on click event for the cancel button in the change due date dialog.
     */
    const closeChangeDueDate = () => {
        setExtendDueDateOpen(false);
    };

    /**
     * Closes the active approval process and updates the state to Approval Review.
     */
    const handleCloseApprovals = async () => {
        try {
            await closeApprovals();
            postAlert('success', alertMessages.success.uar_report_approvals_closed);
        } catch (error) {
            postAlert('error', alertMessages.error.uar_report_approvals_closed);
        } finally {
            setSureCloseApprovalsOpen(false);
        }
    };

    /**
     * Completes the report
     */
    const handleConfirmCompleteReport = async () => {
        await completeReport(true);
        setSureCompleteReportOpen(false);
    };

    /**
     * Deletes a report and navigates to project page
     */
    const handleConfirmDeleteReport = async () => {
        try {
            await deleteReport();
            navigate(`/${passwordSession.sessionDetails.namespace}/project/${report.projId}`, {
                replace: true,
                detail: {
                    alert: true,
                    alertPayload: {
                        message: alertMessages.success.uar_report_deleted,
                        severity: 'success'
                    }
                }
            });
        } catch (err) {
            console.log(err);
        } finally {
            setSureDeleteReportOpen(false);
        }
    };

    /**
     * Function to close toast notification
     */
    const handleAlertClose = () => {
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    /**
     * Handles the cancellation of the delete report operation
     */
    const handleCancelDeleteReport = () => {
        setSureDeleteReportOpen(false);
    };

    /**
     * Handle the on click event for the change due date button.
     */
    const handleChangeDueDate = () => {
        setExtendDueDateOpen(true);
    };

    /**
     * Handles the cancel event for the cancel close approvals sure modal.
     */
    const handleCancelCloseApprovals = () => {
        setSureCloseApprovalsOpen(false);
    };

    /**
     * Handles the cancel event for the cancel complete report sure modal.
     */
    const handleCancelCompleteReport = () => {
        setSureCompleteReportOpen(false);
    };

    /**
     * Handles the on click event for Complete Report
     */
    const handleCompleteReport = async () => {
        if (pendingRemediationTasks.length > 0) {
            setSureCompleteReportOpen(true);
        } else {
            await completeReport(false);
        }
    };

    /**
     * Handles the click event on the delete button
     */
    const handleDeleteReport = () => {
        setSureDeleteReportOpen(true);
    };

    /**
     * Function to handle Export Click
     */
    const handleExportReport = async () => {
        try {
            const data = await exportUARReport({ email: passwordSession.sessionDetails.email, report });
            downloadFile(new Blob([data.data]), report.name + '.xlsx');
        } catch (e) {
            console.error('export report error: ', e);
        }
    };

    /**
     * Triggers a global alert.
     * @param {String} severity severity of the alert
     * @param {String} message message to display
     */
    const postAlert = (severity, message) => {
        updateAlert((alert) => {
            alert.autoHideDuration = 3000;
            alert.message = message;
            alert.open = true;
            alert.severity = severity;
            alert.onClose = handleAlertClose;
        });
    };

    /**
     * Updates the due date on the Report.
     * @param {Object} data options for updating the due date.
     */
    const handleUpdateDueDate = async (data) => {
        try {
            await updateDueDate(data.dueDate, data.resendEmails);
            postAlert('success', alertMessages.success.uar_report_due_date_changed);
        } catch (err) {
            postAlert('error', alertMessages.error.uar_report_due_date_changed);
        } finally {
            setExtendDueDateOpen(false);
        }
    };

    const buttonOptions = [
        {
            label: 'Send Report',
            disabled: sendReportDisabled,
            handler: () => setSendReportIsOpen(true),
            hidden: report.status === 'Completed' || report.status === 'Approval Review' ? true : false
        },
        {
            label: report.status === 'Pending Approvals' ? 'Change Due Date' : 'Reopen Approvals',
            handler: handleChangeDueDate,
            hidden: report.status === 'Pending Approvals' || report.status === 'Approval Review' ? false : true
        },
        { label: 'Export Report', handler: handleExportReport },
        { label: 'Delete', handler: handleDeleteReport }
    ];

    return (
        <Card sx={{ overflow: 'visible' }}>
            <Grid container>
                {isAdminView && (
                    <Grid item xs={12}>
                        <Link to={`/${passwordSession.sessionDetails.namespace}/project/${report.projId}`}>
                            <IconButton aria-label="open" color="primary">
                                <KeyboardArrowLeftIcon />
                                <Typography variant="body2">Project</Typography>
                            </IconButton>
                        </Link>
                    </Grid>
                )}
                <Grid container item xs={12} sx={{ pl: 10, pr: 10, pb: 10, pt: isAdminView ? 0 : 10 }} spacing={10}>
                    <Grid item xs={12} md={2}>
                        <Typography>
                            <span style={{ fontSize: '1.3rem', fontWeight: 'bold', color: theme.palette.primary.main }}>Report Name:</span>
                            <br />
                            {report.name}
                        </Typography>
                    </Grid>
                    {isAdminView && (
                        <Grid item xs={12} md={2}>
                            <Typography>
                                <span style={{ fontSize: '1.3rem', fontWeight: 'bold', color: theme.palette.primary.main }}>Status:</span> <br />
                                {report.status}
                            </Typography>
                        </Grid>
                    )}
                    {report.dueDate && report.status === 'Pending Approvals' && (
                        <Grid item xs={12} md={2}>
                            <Typography>
                                <span style={{ fontSize: '1.3rem', fontWeight: 'bold', color: theme.palette.primary.main }}>Due Date:</span> <br />
                                {`${dayjs(dueDate).format('h:mm A')} on ${dayjs(dueDate).format('MM/DD/YYYY')}`}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12} md={4}>
                        <Grid container item spacing={5}>
                            {report.status !== 'Ready to Send' && (
                                <Grid item xs={12} md={6}>
                                    <Stack>
                                        <Typography>
                                            <span style={{ fontSize: '1.3rem', fontWeight: 'bold', color: theme.palette.primary.main }}>Approvals Completion:</span> <br />
                                        </Typography>
                                        <Box sx={{ width: { xs: '12rem', md: '100%' } }}>
                                            <CompletionIndicator
                                                completed={reviewedRows.length}
                                                total={report.approvalRows.length}
                                                speed={4}
                                                size={50}
                                                fontSize="1rem"
                                                type="linear"
                                            ></CompletionIndicator>
                                        </Box>
                                    </Stack>
                                </Grid>
                            )}

                            {isAdminView && report.status === 'Pending Approvals' && (
                                <Grid item xs={12} md={6}>
                                    <Button
                                        sx={{ alignSelf: { xs: 'baseline', md: 'center' }, height: 'fit-content', width: 'fit-content' }}
                                        onClick={() => setSureCloseApprovalsOpen(true)}
                                        variant="contained"
                                    >
                                        Close Approvals
                                    </Button>
                                </Grid>
                            )}
                            {isAdminView && report.status === 'Approval Review' && (
                                <Grid item xs={12} md={6}>
                                    <Button sx={{ alignSelf: { xs: 'baseline', md: 'center' }, height: 'fit-content', width: 'fit-content' }} onClick={handleCompleteReport} variant="contained">
                                        Complete Report
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={true} sx={{ textAlign: { md: 'right' } }}>
                        {isAdminView && buttonOptions && (
                            <SplitButton sx={{ justifyContent: 'flex-end' }} buttonOptions={buttonOptions} defaultIndex={buttonOptions.findIndex((option) => !option.hidden)}></SplitButton>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <DueDateEditorDialog
                handleNeg={closeChangeDueDate}
                handlePos={handleUpdateDueDate}
                open={extendDueDateOpen}
                title={report.status === 'Pending Approvals' ? 'Change Due Date' : 'Reopen Approvals'}
            ></DueDateEditorDialog>
            <SureDialog
                title="Delete Report"
                content="Are you sure you want to delete this report? This operation is not reversable"
                open={sureDeleteReportOpen}
                handleNeg={handleCancelDeleteReport}
                handlePos={handleConfirmDeleteReport}
            ></SureDialog>
            <SureDialog
                title="Close Approvals"
                content="Are you sure you want to close this report early? Managers will no longer be able to make any edits unless you reopen the approvals."
                open={sureCloseApprovalsOpen}
                handleNeg={handleCancelCloseApprovals}
                handlePos={handleCloseApprovals}
            ></SureDialog>
            <SureDialog
                title="Complete Report"
                content={`Are you sure you want to complete this report? You still have ${pendingRemediationTasks.length} pending Remediation Task(s). All tasks will be marked as complete upon completing the report.`}
                open={sureCompleteReportOpen}
                handleNeg={handleCancelCompleteReport}
                handlePos={handleConfirmCompleteReport}
            ></SureDialog>
        </Card>
    );
}

export default ReportCard;
