import { useEffect, useRef, useState } from 'react';

import { authorizeAnOrg } from 'features/organizations';

import { reportStore } from 'stores';

/**
 * Hook for retrieving data from salesforce.
 */
export const useSalesforceData = (requests, options, tryAgain, isImperative = false) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const isMounted = useRef(false);
    const [isError, setIsError] = useState(false);
    const [currentOrg] = reportStore.useState('currentOrg');

    const getSalesforceData = async () => {
        try {
            setIsLoading(true);
            // Make Salesforce Requests
            const results = await Promise.all(requests());
            //console.log('Salesforce results = ', results);
            // Check a response to see if access token is still valid
            const accessTokenHasExpired = results[0].response.status === 400;
            if (accessTokenHasExpired) {
                authorizeAnOrg(currentOrg._id, options);
            } else {
                if (isMounted.current) {
                    setData(results.map((result) => result.data));
                }
            }
        } catch (error) {
            console.log(error);
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        //console.log('currentOrg = ', currentOrg);
        isMounted.current = true;
        if (Object.keys(currentOrg).length > 0 && !isImperative) {
            setIsError(false);
            getSalesforceData();
        }

        return () => {
            isMounted.current = false;
        };
    }, [currentOrg, requests, tryAgain]);

    return { data, isError, isLoading, refresh: getSalesforceData };
};
