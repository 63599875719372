// React Imports
import { useCallback } from 'react';
// MUI Imports
import { Box, Paper, TableContainer } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
// File Imports
import DividerList from 'components/ui/DividerList';
import RenderExpandableCell from 'components/ui/RenderExpandableCell';
import { alertMessages } from 'data/alerts';
import { miscStore } from 'stores';

// Constants
const COLUMN_WIDTH = 170;
const COLUMN_WIDTH_2 = 250;

function UARCrudTable({ isAdmin, useReport }) {
    const { objectRows, report, updateUarRow } = useReport;
    const columnWidth = COLUMN_WIDTH;
    const columnWidth2 = COLUMN_WIDTH_2;
    const theme = useTheme();

    let columns = [
        {
            field: 'managerName',
            headerName: 'Manager',
            minWidth: columnWidth,
            editable: isAdmin ? true : false,
            renderCell: (cellValues) => {
                return <RenderExpandableCell {...cellValues} />;
            }
        },
        {
            field: 'managerEmail',
            headerName: 'Manager Email',
            minWidth: columnWidth2,
            editable: isAdmin ? true : false,
            renderCell: (cellValues) => {
                return <RenderExpandableCell {...cellValues} />;
            }
        },
        { field: 'userName', headerName: 'Employee', minWidth: columnWidth },
        { field: 'permissionType', headerName: 'Permission Type', minWidth: columnWidth },
        {
            field: 'permissionName',
            headerName: 'Permission Name',
            minWidth: columnWidth2,
            renderCell: (cellValues) => {
                return <RenderExpandableCell {...cellValues} />;
            }
        }
    ];

    // States
    const [, , updateAlert] = miscStore.useState('alert');

    /**
     * Generates the key object columns and inserts them into the columns array.
     */
    const generateColumns = () => {
        const keyObjectColumns = report.keyObjects.map((object) => {
            return {
                field: object,
                headerName: object,
                minWidth: 250,
                renderCell: (cellValues) => {
                    const listValues = ['C', 'R', 'E', 'D', 'V', 'M'].map((objectAccess) => (cellValues.value.includes(objectAccess) ? objectAccess : ''));
                    return <DividerList uniqueKey={cellValues.id + cellValues.field} listValues={listValues}></DividerList>;
                }
            };
        });
        columns.splice(5, 0, ...keyObjectColumns);
        return columns;
    };

    //Functions

    /**
     * Function to close the toast notification
     */
    const handleAlertClose = () => {
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    /**
     * Handles any error during the process row update action.
     */
    const handleProcessRowUpdateError = useCallback((error) => {
        console.log('Error:', error);
        // Error popup message
        postAlert('error', alertMessages.error.uar_row_update);
    }, []);

    /**
     * Triggers a global alert.
     * @param {String} severity severity of the alert
     * @param {String} message message to display
     */
    const postAlert = (severity, message) => {
        updateAlert((alert) => {
            alert.autoHideDuration = 3000;
            alert.message = message;
            alert.open = true;
            alert.severity = severity;
            alert.onClose = handleAlertClose;
        });
    };

    /**
     * Takes the updated row and makes a call to the database to update.
     * @param {Object} newRow updated row
     * @returns Updated document or error
     */
    const processRowUpdate = async (newRow, oldVal) => {
        const response = await updateUarRow(newRow, oldVal);
        postAlert('success', alertMessages.success.uar_row_update);
        return response;
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
                <Box sx={{ height: 600, width: '100%' }}>
                    <DataGrid
                        sx={{
                            '& .MuiDataGrid-columnHeader': {
                                backgroundColor: theme.palette.mode === 'light' ? '#f3b040' : 'f7f7f8'
                            }
                        }}
                        rows={objectRows}
                        getRowId={(row) => row._id}
                        columns={generateColumns()}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'managerName', sort: 'asc' }]
                            }
                        }}
                        rowHeight={50}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                        processRowUpdate={(val, oldVal) => processRowUpdate(val, oldVal)}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                    />
                </Box>
            </TableContainer>
        </Paper>
    );
}

export default UARCrudTable;
