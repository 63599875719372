// React Imports
import React, { useCallback } from 'react';
// MUI Imports
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter, GridToolbar } from '@mui/x-data-grid';
// Component Imports
import LoadingIndicator from 'components/ui/LoadingIndicator';
import RenderExpandableCell from 'components/ui/RenderExpandableCell';
import { reportStore } from 'stores';
// API Imports
import { getSObjectFields, useSalesforceData } from 'features/salesforce';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarQuickFilter />
            <GridToolbar />
        </GridToolbarContainer>
    );
}

function ObjectFieldReport() {
    const columns = [
        {
            field: 'EntityDefinition.Label',
            headerName: 'Object Name',
            flex: 1,
            valueGetter: (params) => params.row.EntityDefinition.Label,
            renderCell: (cellValues) => {
                return <RenderExpandableCell {...cellValues} />;
            }
        },
        {
            field: 'EntityDefinition.QualifiedApiName',
            headerName: 'Object API Name',
            flex: 1,
            valueGetter: (params) => params.row.EntityDefinition.QualifiedApiName,
            renderCell: (cellValues) => {
                return <RenderExpandableCell {...cellValues} />;
            }
        },
        {
            field: 'QualifiedApiName',
            headerName: 'Field Name',
            flex: 1,
            renderCell: (cellValues) => {
                return <RenderExpandableCell {...cellValues} />;
            }
        },
        {
            field: 'DataType',
            headerName: 'Field Type',
            flex: 1,
            renderCell: (cellValues) => {
                return <RenderExpandableCell {...cellValues} />;
            }
        },
        {
            field: 'NamespacePrefix',
            headerName: 'Managed Package',
            flex: 1,
            renderCell: (cellValues) => {
                return <RenderExpandableCell {...cellValues} />;
            }
        }
    ];
    const loadingSteps = [
        {
            key: 0,
            label: 'Generating report...'
        }
    ];
    const selectedFieldTypes = reportStore.getState('selectedFieldTypes').value;
    const selectedObjects = reportStore.getState('selectedObjects').value;

    // Get Salesforce Objects
    const sObjects = selectedObjects.map((sObject) => `'${sObject}'`);
    const salesforceRequests = useCallback(() => [getSObjectFields(sObjects, selectedFieldTypes)], []);
    const { data, isLoading } = useSalesforceData(salesforceRequests);

    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingIndicator steps={loadingSteps}></LoadingIndicator>
            ) : (
                <DataGrid
                    getRowId={(row) => `${row.EntityDefinition.QualifiedApiName}${row.QualifiedApiName}`}
                    rows={data[0] ?? []}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5
                            }
                        }
                    }}
                    pageSizeOptions={[5]}
                    components={{ Toolbar: CustomToolbar }}
                />
            )}
        </React.Fragment>
    );
}

export default ObjectFieldReport;
