// React Imports
import React from 'react';
// MUI Imports
import { Box, Grid, Paper } from '@mui/material';
// Component Imports
import { SettingsSideBar } from 'features/settings';
import { ManagerCSVs } from 'features/managers';

function ManagerData() {
    return (
        <Box sx={{ p: 8, display: 'flex', flex: 1 }}>
            <SettingsSideBar></SettingsSideBar>
            <Box sx={{ flex: 1 }}>
                <Grid container alignContent="center" justifyContent="center">
                    <Grid item xs={12}>
                        <Paper>
                            <ManagerCSVs
                                tableOptions={{ checkbox: false, name: { display: true, minWidth: 150 }, download: { display: true, minWidth: 60 }, delete: { display: true, minWidth: 60 } }}
                            ></ManagerCSVs>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default ManagerData;
