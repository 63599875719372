// React Imports
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// MUI Imports
import { Paper, Box, Typography, Container } from '@mui/material';
// File Imports
import nemosFoxLogoDark from 'assets/NemosFoxLogoDarkMode.png';

function Error() {
    const [logoSize] = useState(150);
    return (
        <div className="App">
            <Container component="main" maxWidth="xs">
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="70vh">
                    <Paper elevation={0} sx={{ p: 10, background: 'transparent' }}>
                        <Link to={`/`}>
                            <img alt="404 Not Found" height={logoSize} width={logoSize} src={nemosFoxLogoDark}></img>
                        </Link>
                        <Typography align="center">Oops! The page you&apos;re attemping to access is not available.</Typography>
                    </Paper>
                </Box>
            </Container>
        </div>
    );
}

export default Error;
