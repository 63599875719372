import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to create a new Project.
 * @param {Object} newProject details of the new Project.
 * @returns the newly created Project.
 */
export const createProject = async (newProject) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        method: 'POST',
        withCredentials: true,
        body: JSON.stringify(newProject)
    };

    const requestPath = `/project`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
