/**
 * MongoDB only returns an array from find. This converts the results into a map.
 * @param {Array} array array of mongoDB Documents
 * @returns Map of Documents with their _id as the key.
 */
export const createMapFromMongoDBArray = (array) => {
    return array.reduce((map, task) => {
        map[task._id] = task;
        return map;
    }, {});
};
