// React Imports
import { useState } from 'react';
// MUI Imports
import { Container, Box, Typography } from '@mui/material';
// Files Imports
import { CodeVerification as CodeVerificationComponent, CodeVerificationEmail } from 'features/auth';

function CodeVerification() {
    // States
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [mainText, setMainText] = useState('In order to access the report we need to verify your email. Please enter your email to receive a verification code.');

    return (
        <Container component="main" maxWidth="xs">
            <Box className="App" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ textAlign: 'center', pt: 15 }}>
                    <Typography variant="h6" sx={{ color: 'text.primary' }}>
                        <b>Authenticate Your Account</b>
                    </Typography>
                </Box>
                <Box sx={{ textAlign: 'center', pt: 10, width: 340 }}>
                    <Typography variant="body2" sx={{ color: 'text.primary' }}>
                        {mainText}
                    </Typography>
                </Box>
                <Box sx={{ mt: 10 }}>
                    {!emailSent && <CodeVerificationEmail setEmailSent={setEmailSent} setEmail={setEmail} setMainText={setMainText}></CodeVerificationEmail>}
                    {emailSent && <CodeVerificationComponent codeLength={6} email={email}></CodeVerificationComponent>}
                </Box>
            </Box>
        </Container>
    );
}

export default CodeVerification;
