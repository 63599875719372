import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to retrieve all Remediation Tasks for a group of reports.
 * @param {[String]} uarReportIds Ids of the Reports to retrieve remediation tasks from
 * @returns array of reports
 */
export const getPendingRemediationTaskCounts = async (uarReportIds) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        method: 'GET',
        withCredentials: true
    };

    const requestPath = `/remediationTask/counts?${uarReportIds}`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
