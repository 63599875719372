import CodeVerification from 'pages/CodeVerification';
import ExpiredEmailVerification from 'pages/ExpiredEmailVerification';
import ForgotPassword from 'pages/ForgotPassword';
import Login from 'pages/Login';
import ResetPassword from 'pages/ResetPassword';
import SignUp from 'pages/SignUp';
import SignUpSuccess from 'pages/SignUpSuccess';

import { PublicLayout } from 'layouts/PublicLayout';
import { Navigate } from 'react-router-dom';

export const publicPasswordRoutes = [
    {
        path: '/',
        element: <PublicLayout />,
        children: [
            { index: true, element: <Navigate to="/login" /> },
            { path: '/expired-email-verification', element: <ExpiredEmailVerification /> },
            { path: '/forgot-password', element: <ForgotPassword /> },
            { path: '/login', element: <Login /> },
            { path: '/login/:namespace', element: <Login /> },
            { path: '/reset-password', element: <ResetPassword /> },
            { path: '/sign-up', element: <SignUp /> },
            { path: '/sign-up-success', element: <SignUpSuccess /> },
            { path: 'uar-report-approval/:reportId/code-verification', element: <CodeVerification /> }
        ]
    }
];
