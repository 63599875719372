import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to delete an Auditor.
 * @param {String} projectId Id of the project to remove the auditor from
 * @param {String} auditorId Id of the Auditor to Delete.
 * @returns the deleted Auditor.
 */
export const deleteAuditor = async (projectId, auditorId) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;
    console.log(`In delete auditor with proj id ${projectId} and auditorId ${auditorId}`);
    const httpOptions = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        method: 'DELETE',
        withCredentials: true
    };

    const requestPath = `/auditor?auditorId=${auditorId}&projectId=${projectId}`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
