import { CognitoUser } from 'amazon-cognito-identity-js';

import { userPool } from '../data/constants';

/**
 * Helper method to generate the Cognito User Object.
 * @param {String} username username of the Cognito User
 * @returns the Cognito User Object for the provided username.
 */
export const getCognitoUser = (username) => {
    const userData = {
        Username: username,
        Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);

    return cognitoUser;
};
