import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to reject an approval.
 * @param {String} oldStatus old status of the approval
 * @param {Object} approval contains info for the updated approval document
 * @returns the newly rejected Approval.
 */
export const apiRejectApproval = async (oldStatus, approval) => {
    const { jwtToken } = authStore.getState('passwordlessSession').value.sessionDetails;

    const httpOptions = {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        body: JSON.stringify({
            approval,
            oldStatus
        })
    };

    const requestPath = `/approval/reject?isPasswordless=${true}`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
