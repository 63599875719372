// React Imports
import { useCallback, useEffect, useMemo, useState } from 'react';
// Components
import { apiApproveApproval, apiRejectApproval, createApprovalRows } from 'features/approvals';
import { updateRemediationTask } from 'features/remediationTasks';
import { apiCompleteReport, apiDeleteReport, apiUpdateUARRow, getReportWithApprovals, getReportWithUARRowsAndApprovals, getUARReport, sendReportsToManagers, updateUARReport } from 'features/reports';
import { useCognito } from 'features/auth';
import { authStore, reportStore } from 'stores';
// External
import * as dayjs from 'dayjs';

export const useReport = (reportId, filterCriteria, type, isAdminView = false) => {
    const [isLoading, setIsLoading] = useState();
    const [passwordSession] = authStore.useState('passwordSession');
    const [report, setReport, updateReport] = reportStore.useState('report');

    const { createPasswordlessUser } = useCognito();

    const managersByEmail = useMemo(
        () =>
            report?.uarRows?.reduce((acc, row) => {
                if (row.managerEmail && !(row.managerEmail in acc)) {
                    acc[row.managerEmail] = row;
                }
                return acc;
            }, {}) ?? {},
        [report?.uarRows]
    );
    const systemRows = useMemo(() => report?.uarRows?.filter((row) => row.reportType === 'System Setting' ?? []), [report?.uarRows]);
    const objectRows = useMemo(() => report?.uarRows?.filter((row) => row.reportType === 'Object Access' ?? []), [report?.uarRows]);

    /**
     * Get a Report based on the type passed to the hook.
     */
    const getReport = useCallback(async () => {
        setIsLoading(true);
        try {
            if (reportId) {
                let result;
                switch (type) {
                    case 'approval':
                        result = await getReportWithApprovals(reportId, filterCriteria, !isAdminView);
                        break;
                    case 'uar-approvals':
                        result = await getReportWithUARRowsAndApprovals(reportId, filterCriteria, !isAdminView);
                        break;
                    default:
                        result = await getUARReport(reportId);
                        break;
                }
                console.log('Report after = ', result);
                setReport(result);
            }
        } catch (e) {
            console.log(`Error: ${e}`);
        } finally {
            setIsLoading(false);
        }
    }, [reportId, filterCriteria, type]);

    useEffect(() => {
        getReport();
    }, [getReport]);

    /**
     * Approves an Approval
     * @param {String} oldStatus the old status value.
     * @param {Approval} updatedApproval the approval with the new status.
     * @param {Integer} selectedApprovalRowIndex index of the Approval Row on global report store.
     * @param {Integer} approvalIndex index of the Approval on global report store.
     */
    const approveApproval = useCallback(async (oldStatus, updatedApproval, selectedApprovalRowIndex, approvalIndex) => {
        const response = await apiApproveApproval(oldStatus, updatedApproval);

        updateReport((report) => {
            report.approvalRows[selectedApprovalRowIndex].approvals[approvalIndex].status = 'Approved';
            if (response.approvalRow) {
                report.approvalRows[selectedApprovalRowIndex].status = response.approvalRow.status;
                report.approvalRows[selectedApprovalRowIndex].approvalsCount = response.approvalRow.approvalsCount;
            }
        });
    }, []);

    /**
     * Closes the active approval process and updates the state to Approval Review.
     */
    const closeApprovals = useCallback(async () => {
        const updates = {
            dueDate: dayjs().startOf('hour'),
            managersWithAccess: [],
            status: 'Approval Review'
        };

        await updateUARReport({ ...report, ...updates });

        updateReport((uarReport) => {
            uarReport.dueDate = updates.dueDate;
            uarReport.managersWithAccess = updates.managersWithAccess;
            uarReport.status = updates.status;
        });
    });

    /**
     * Updates a Remediation Task for an Approval
     * @param approvalIndex Index of the approval in the global report
     * @param approvalRowIndex Index of the approval row in the global report
     * @param remediationTaskId updated remdiation task
     */
    const completeRemediationTask = useCallback(async (approvalIndex, approvalRowIndex, updatedRemediationTask) => {
        updatedRemediationTask.status = 'Complete';
        const response = await updateRemediationTask(updatedRemediationTask);

        updateReport((uarReport) => {
            uarReport.approvalRows[approvalRowIndex].approvals[approvalIndex].remediationTask.status = 'Complete';
        });

        return response;
    });

    /**
     * Completes a UAR Report
     * @param {Boolean} completeAllTasks flag to complete all related remediation tasks.
     */
    const completeReport = useCallback(async (completeAllTasks) => {
        const updates = {
            status: 'Complete'
        };

        await apiCompleteReport(report._id, completeAllTasks);

        updateReport((uarReport) => {
            uarReport.status = updates.status;

            uarReport.approvalRows.forEach((approvalRow) => {
                approvalRow.approvals.forEach((approval) => {
                    if (approval.status === 'Rejected') {
                        approval.remediationTask.status = 'Complete';
                    }
                });
            });
        });
    });

    /**
     * Deletes a UAR Report
     */
    const deleteReport = useCallback(async () => {
        await apiDeleteReport(report._id);

        setReport(null);
    });

    /**
     * Rejects and Approval
     * @param {String} oldStatus the old status value.
     * @param {Approval} updatedApproval the approval with the updated status.
     * @param {Integer} selectedApprovalRowIndex index of the Approval Row on global report store.
     * @param {Integer} approvalIndex index of the Approval on global report store.
     */
    const rejectApproval = useCallback(async (oldStatus, updatedApproval, selectedApprovalRowIndex, approvalIndex) => {
        const response = await apiRejectApproval(oldStatus, updatedApproval);

        updateReport((report) => {
            report.approvalRows[selectedApprovalRowIndex].approvals[approvalIndex] = updatedApproval;
            if (response.approvalRow) {
                report.approvalRows[selectedApprovalRowIndex].status = response.approvalRow.status;
                report.approvalRows[selectedApprovalRowIndex].approvalsCount = response.approvalRow.approvalsCount;
            }
        });
    }, []);

    /**
     * Sends a Report to the provided emails and sets the due date
     * @param {[String]} emails list of emails
     * @param {String} dueDate date the report is due
     */
    const sendReport = useCallback(async (emails, dueDate) => {
        // Build the approval rows
        await createApprovalRows(report._id);

        /* Attempts to create a cognitio user for each manager to ensure that they exist as a 
         cognito user before sending the email.*/
        const createPasswordlessUserRequests = emails.map((managerEmail) => createPasswordlessUser(managersByEmail[managerEmail].managerName, managerEmail));
        await Promise.allSettled(createPasswordlessUserRequests);

        await sendReportsToManagers(emails, report._id, dueDate, passwordSession.sessionDetails.email);

        // Refresh the report to populate approval rows
        await getReport();
    });

    /**
     * Updates the due date of a report
     * @param {Date} dueDate new due date
     * @param {Boolean} resendEmails flag determining whether to resend emails or not.
     */
    const updateDueDate = useCallback(async (dueDate, resendEmails) => {
        const updates = {
            dueDate,
            status: report.status !== 'Pending Approvals' ? 'Pending Approvals' : report.status
        };

        await updateUARReport({ ...report, ...updates });

        if (resendEmails) {
            /* Attempts to create a cognitio user for each manager to ensure that they exist as a 
            cognito user before sending the email.*/
            const createPasswordlessUserRequests = Object.entries(managersByEmail).map(([managerEmail, manager]) => createPasswordlessUser(manager.managerName, managerEmail));
            await Promise.allSettled(createPasswordlessUserRequests);

            const managerEmails = Object.keys(managersByEmail);
            sendReportsToManagers(managerEmails, report._id, dueDate, passwordSession.sessionDetails.email);
        }

        updateReport((report) => {
            report.dueDate = updates.dueDate;
            report.status = updates.status;
        });
    });

    /**
     * Updates a UAR Row
     */
    const updateUarRow = useCallback(async (updateInfo, oldVal) => {
        await apiUpdateUARRow(updateInfo, oldVal);

        const index = report.uarRows.findIndex((uarRow) => uarRow._id === updateInfo._id);

        updateReport((report) => {
            report.uarRows[index] = updateInfo;
        });
    });

    return {
        approveApproval,
        closeApprovals,
        completeRemediationTask,
        completeReport,
        deleteReport,
        isAdminView,
        isLoading,
        managersByEmail,
        objectRows,
        refresh: getReport,
        rejectApproval,
        report,
        sendReport,
        systemRows,
        updateDueDate,
        updateUarRow
    };
};
