import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to get a profile picture for the user.
 * @returns the signed url of the profile picture for the user.
 */
export const getProfilePicture = async () => {
    try {
        const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

        const httpOptions = {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json'
            },
            method: 'GET',
            withCredentials: true
        };

        const requestPath = `/account/profilePicture`;
        const { data } = await fetchData(requestPath, httpOptions);
        return data;
    } catch (err) {
        return err.message;
    }
};
