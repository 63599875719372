// React Imports
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
// MUI Imports
import { Grid } from '@mui/material';
// File Imports
import { alertMessages } from 'data/alerts';
import { getProjectsByAccount, ProjectsTable } from 'features/projects';
import { getUARReportsByAcc, getUARRowsByAcc, RecentlyViewedReports } from 'features/reports';
import { getPendingRemediationTaskCounts } from 'features/remediationTasks';
import StatCard from 'components/ui/StatCard';
// Data Imports
import { authStore, miscStore, reportStore } from 'stores';

function Home() {
    //Constants
    const location = useLocation();

    //useStates
    const [, , updateAlert] = miscStore.useState('alert');
    const [, setCurrentOrg] = reportStore.useState('currentOrg');
    const [passwordSession] = authStore.useState('passwordSession');
    const isMounted = useRef(true);
    const [numOfCompletedProjects, setNumOfCompletedProjects] = useState(0);
    const [numOfReportsCreated, setNumOfReportsCreated] = useState(0);
    const [numOfUARRowsReviewed, setNumOfUARRowsReviewed] = useState(0);
    const [numOfPendingRemediationTasks, setNumOfPendingRemediationTasks] = useState(0);
    const [searchParams] = useSearchParams();

    //useEffects
    useEffect(async () => {
        const getTotalPendingRemediationTasks = async (createdReports) => {
            const reportsInApprovalReview = createdReports.reduce((ids, row) => {
                if (row.status === 'Approval Review') ids.push(`uarReportIds=${row._id}`);
                return ids;
            }, []);

            if (reportsInApprovalReview.length > 0) {
                const remediationTaskCountsByReport = await getPendingRemediationTaskCounts(reportsInApprovalReview.join('&'));
                return remediationTaskCountsByReport.reduce((totalCount, result) => (totalCount += result.count), 0);
            }
            return 0;
        };

        const getCompletedProjects = getProjectsByAccount({ status: 'Completed' });
        const getCreatedReports = getUARReportsByAcc({ status: { $ne: 'Deleted' } });
        const getReviewedUARRows = getUARRowsByAcc({ approveState: ['Approved', 'Rejected'] });
        const [completedProjects, createdReports, reviewedUARRows] = await Promise.all([getCompletedProjects, getCreatedReports, getReviewedUARRows]);

        const totalPendingRemediationTasks = await getTotalPendingRemediationTasks(createdReports);

        if (isMounted.current) {
            setNumOfCompletedProjects(completedProjects.length);
            setNumOfReportsCreated(createdReports.length);
            setNumOfUARRowsReviewed(reviewedUARRows.length);
            setNumOfPendingRemediationTasks(totalPendingRemediationTasks);
        }
    }, []);

    useEffect(() => {
        if (location.state?.alert) {
            postAlert(location.state.alertPayload.severity, location.state.alertPayload.message);
            window.history.replaceState({}, document.title);
        }
    }, [location]);

    /**
     * useEFfect to take the searchParams and create a newOrg and display notifications
     */
    useEffect(() => {
        const checkSearchParams = async () => {
            const authResult = searchParams.get('authResult');
            if (authResult === 'success') {
                const orgId = searchParams.get('orgId');
                setCurrentOrg({ _id: orgId });
                postAlert('success', alertMessages.success.auth);
            } else if (authResult === 'failure') {
                const errorPayload = JSON.parse(searchParams.get('errorPayload'));
                postAlert('error', errorPayload.message);
            }
        };

        isMounted.current = true;
        checkSearchParams();

        return () => {
            isMounted.current = false;
        };
    }, [searchParams]);

    //Functions
    /**
     * Function to close the toast notification
     */
    const handleAlertClose = () => {
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    /**
     * Triggers a global alert.
     * @param {String} severity severity of the alert
     * @param {String} message message to display
     */
    const postAlert = (severity, message) => {
        updateAlert((alert) => {
            alert.autoHideDuration = 3000;
            alert.message = message;
            alert.open = true;
            alert.severity = severity;
            alert.onClose = handleAlertClose;
        });
    };

    return (
        <React.Fragment>
            <Grid container spacing={10} alignContent="flex-start">
                <Grid item xs={12} lg={8}>
                    <ProjectsTable></ProjectsTable>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <RecentlyViewedReports></RecentlyViewedReports>
                </Grid>
                <Grid item container spacing={10} xs={12} lg={8}>
                    <Grid item xs={6}>
                        <StatCard
                            title="Projects Completed"
                            stat={numOfCompletedProjects}
                            navEnabled="true"
                            link={`/${passwordSession.sessionDetails.namespace}/project-history`}
                            color="lightgreen"
                        ></StatCard>
                    </Grid>
                    <Grid item xs={6}>
                        <StatCard title="Reports Created" stat={numOfReportsCreated} navEnabled="false" color="lightblue"></StatCard>
                    </Grid>
                    <Grid item xs={6}>
                        <StatCard title="Permissions Reviewed" stat={numOfUARRowsReviewed} navEnabled="false" color="lightblue"></StatCard>
                    </Grid>
                    <Grid item xs={6}>
                        <StatCard title="Pending Tasks" stat={numOfPendingRemediationTasks} navEnabled="false" color="orange"></StatCard>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default Home;
