// React Imports
import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// MUI Imports
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
// Component Imports
import { OrgDialog } from 'features/organizations';
// Service Imports
import { createProject } from '../services/createProject';
import { getOrgsByAccount } from 'features/organizations';

import { miscStore, orgStore } from 'stores';
import { alertMessages } from 'data/alerts';

function ProjectDialog({ onClose, open }) {
    const [searchParams] = useSearchParams();
    const redirectInfo = JSON.parse(searchParams.get('redirectInfo'));
    const returnedFromOrgAuth = searchParams.has('authResult');

    // States
    const [, , updateAlert] = miscStore.useState('alert');
    const isMounted = useRef(true);
    const [openOrgDialog, setOpenOrgDialog] = useState(false);
    const [orgs, setOrgs] = useState([]);
    const [projectName, setProjectName] = useState('');
    const [selectedOrg, setSelectedOrg] = useState('');
    const [selectIsOpen, setSelectIsOpen] = useState(false);
    const [, , updateNewOrgRedirectInfo] = orgStore.useState('newOrgRedirectInfo');

    // UseEffects
    /**
     * Retrieves the Salesforce Orgs for the current user session. Runs on first render only.
     */
    useEffect(() => {
        const initializeComponent = async () => {
            // Get orgs to populate the select options
            const _orgs = await getOrgsByAccount();
            if (isMounted.current) {
                setOrgs(
                    _orgs.map((val) => {
                        let retVal = val;
                        retVal.id = val._id;
                        return retVal;
                    })
                );

                // If the user was redirected here after authing a new org, select the new org and project name
                if (returnedFromOrgAuth) {
                    setProjectName(redirectInfo.projectName ?? '');
                    const relevantOrg = _orgs.filter((org) => {
                        return org._id === searchParams.get('orgId');
                    });
                    setSelectedOrg(relevantOrg[0]);
                }
            }
        };
        isMounted.current = true;
        initializeComponent();
        return () => {
            isMounted.current = false;
        };
    }, []);

    //Functions
    /**
     * @description closes the project dialog
     */
    const closeProjectDialog = () => {
        onClose();
    };

    /**
     * @description Sends a request to create a new project.
     */
    const createNewProject = async () => {
        try {
            await createProject({
                orgId: selectedOrg.id,
                name: projectName,
                orgName: selectedOrg.name,
                status: 'Open',
                createdDate: new Date().toLocaleDateString()
            });
            postAlert('success', alertMessages.success.project_created);
        } catch (err) {
            postAlert('error', alertMessages.error.project_created);
        } finally {
            closeProjectDialog();
        }
    };

    /**
     * @description Handles the closing of the Org dialog.
     */
    const handleAddOrgClose = () => {
        setOpenOrgDialog(false);
    };

    /**
     * @description Handles the opening of the Org dialog.
     */
    const handleAddOrgOpen = () => {
        setOpenOrgDialog(true);
    };

    /**
     * Function to close the toast notification
     */
    const handleAlertClose = () => {
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    /**
     * @description Handles the input of Name and assigns it to the name state.
     * @param {Object} event event details
     */
    const handleNameChange = (event) => {
        setProjectName(event.target.value);
        updateNewOrgRedirectInfo((redirectInfo) => {
            redirectInfo.projectName = event.target.value;
        });
    };

    /**
     * @description Handles the selection of the Org and assigns it to the org state.
     * @param {Object} event event details
     */
    const handleOrgChange = (event) => {
        setSelectedOrg(event.target.value);
    };

    /**
     * @description Checks if the form was filled out correctly.
     * @returns boolean for if the form is valid.
     */
    const isFormValid = () => {
        return projectName.length < 3 || selectedOrg === '';
    };

    /**
     * Triggers a global alert.
     * @param {String} severity severity of the alert
     * @param {String} message message to display
     */
    const postAlert = (severity, message) => {
        updateAlert((alert) => {
            alert.autoHideDuration = 3000;
            alert.message = message;
            alert.open = true;
            alert.severity = severity;
            alert.onClose = handleAlertClose;
        });
    };

    return (
        <React.Fragment>
            <Dialog onClose={closeProjectDialog} open={open}>
                <DialogTitle sx={{ alignSelf: 'center' }}>Project Details</DialogTitle>
                <DialogContent sx={{ overflow: 'hidden' }}>
                    <Grid container spacing={8} sx={{ paddingTop: 4, width: '25vw' }}>
                        <Grid item xs={12}>
                            <TextField id="title-basic" label="Title" variant="outlined" fullWidth required value={projectName} onChange={handleNameChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl size="small" fullWidth required>
                                <InputLabel>Salesforce Org</InputLabel>
                                <Select
                                    value={selectedOrg}
                                    label="Salesforce Org"
                                    onChange={handleOrgChange}
                                    open={selectIsOpen}
                                    onOpen={() => setSelectIsOpen(true)}
                                    onClose={() => setSelectIsOpen(false)}
                                >
                                    <MenuItem onClick={handleAddOrgOpen}>-- Add org --</MenuItem>
                                    {orgs.map((org) => (
                                        <MenuItem key={org.id} value={org}>
                                            {org.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', px: 10, py: 10 }}>
                    <Button onClick={closeProjectDialog} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={createNewProject} variant="contained" color="primary" disabled={isFormValid()}>
                        Create Project
                    </Button>
                </DialogActions>
            </Dialog>
            <OrgDialog open={openOrgDialog} onClose={handleAddOrgClose} orgs={orgs}></OrgDialog>
        </React.Fragment>
    );
}

export default ProjectDialog;
