import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to retrieve all Orgs for an Account.
 * @returns array of orgs
 */
export const getOrgsByAccount = async () => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        method: 'GET',
        withCredentials: true
    };

    const requestPath = `/organization/byAcc`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
