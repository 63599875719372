// React Imports
import React, { useState } from 'react';
// MUI Imports
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
// Component Imports
import FileUploader from 'components/ui/FileUploader';
// API Imports
import { uploadManagerData } from '../services/uploadManagerData';
import { miscStore } from 'stores';

import { alertMessages } from 'data/alerts';

const managerCSVLimit = 52428800; //50MB -- 1048576 //1MB for Testing

function ManagerUploadButton({ isMultiple }) {
    const [, , updateAlert] = miscStore.useState('alert');

    const [loading, setLoading] = useState(false);
    const [tableRefreshTrigger, setTableRefreshTrigger] = miscStore.useState('tableRefreshTrigger');

    /**
     * Function that takes CSV Information from an Upload and Processes it
     * @param {String} fileName Name of the CSV
     * @param {String} fileContents Contents of the CSV in String fromt
     * @returns Boolean on whether or not the CSV passed validation and processing
     */
    const onFileUploaded = async (fName, fileContents) => {
        setLoading(true);
        /* Results comes back as singular string. Rows are separated by 
            new lines so we use that as a delimiter to create a string of rows */
        const formattedFileName = fName.length > 50 ? fName.substring(0, 25) + '...' + fName.substring(fName.length - 25) : fName;

        try {
            await uploadManagerData(formattedFileName, fileContents);

            setTableRefreshTrigger(!tableRefreshTrigger);
            postAlert('success', alertMessages.success.csv_upload);
        } catch (err) {
            postAlert('error', `${alertMessages.error.csv_upload}: ${err.message}`);
        }
        setLoading(false);
    };

    /**
     * Function to close the toast notification
     */
    const handleAlertClose = () => {
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    /**
     * Triggers a global alert.
     * @param {String} severity severity of the alert
     * @param {String} message message to display
     */
    const postAlert = (severity, message) => {
        updateAlert((alert) => {
            alert.autoHideDuration = 3000;
            alert.message = message;
            alert.open = true;
            alert.severity = severity;
            alert.onClose = handleAlertClose;
        });
    };

    return (
        <React.Fragment>
            {loading ? (
                <Box sx={{ display: 'flex', p: 5 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <React.Fragment>
                    <FileUploader extensionsAccepted=".csv" fileSizeLimit={managerCSVLimit} isMultiple={isMultiple} onFileUploaded={onFileUploaded}></FileUploader>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default ManagerUploadButton;
