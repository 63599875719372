import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Function that takes an sends a Diff Report to a list of auditors
 * @param {Object} emailPayload Object containing payload and auditor emails
 * @returns Success or Error object
 */
export const sendDiffEmail = async (emailPayload) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`
        },
        withCredentials: true,
        body: JSON.stringify(emailPayload)
    };

    const requestPath = `/auditor/diffEmail`;
    const { data, response } = await fetchData(requestPath, httpOptions);
    return { data, response };
};
