import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

export const getAccountDetails = async (userId) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        method: 'GET',
        withCredentials: true
    };
    const { data } = await fetchData(`/stripe/search?userId=${userId}`, httpOptions);
    return data.body[0];
};
