import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to complete a UAR Report.
 * @param {String} uarReportId Id of the report to complete.
 * @param {Boolean} completeAllTasks boolean stating whether all related tasks need to be marked as complete.
 * @returns the completed UAR Report.
 */
export const apiCompleteReport = async (uarReportId, completeAllTasks) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        body: JSON.stringify({ uarReportId, completeAllTasks })
    };

    const requestPath = `/uarReport/complete`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
