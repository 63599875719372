import { Divider, Paper, Stack, Typography } from '@mui/material';

// This component renders an informational paper card with a header, body, and footer
function InfoPaper({ bodyText, footerButton, footerText, headerIcon, headerText }) {
    // bodyText: string - the main content of the card to display
    // footerButton: component - an optional button to be displayed in the footer of the card
    // footerText: string - optional additional text to be displayed in the footer, usually in a smaller font
    // headerIcon: component - an optional icon to be displayed next to the header text
    // headerText: string - the text to be displayed in the header of the card
    return (
        <Paper elevation={1} sx={{ borderRadius: 10 }}>
            <Stack sx={{ p: 10 }}>
                {headerIcon}
                <Typography sx={{ textAlign: 'center', pb: 10 }} variant="h4">
                    {headerText}
                </Typography>
                <Divider />
                <Typography sx={{ pt: 12, pb: 12 }} variant="body1">
                    {bodyText}
                </Typography>
                <Divider />
                {footerButton}
                <Typography sx={{ pt: 8, alignSelf: 'center' }} fontWeight="bold" fontStyle="italic" variant="caption">
                    {footerText}
                </Typography>
            </Stack>
        </Paper>
    );
}

export default InfoPaper;
