// React Imports
import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
// MUI Imports
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Toolbar } from '@mui/material';
//import useMediaQuery from '@mui/material/useMediaQuery';
// Component and Helper Imports
import Footer from 'layouts/Footer';
import Header from 'layouts/Header';

import { useCognito } from 'features/auth';
import { AppRoutes } from 'routes';
import GlobalAlert from 'components/ui/GlobalAlert';

import { authStore } from 'stores';
import { ColorModeContext, getTheme, styledComponents } from 'data/theme';
// File Imports
import 'assets/App.css';

const MainBox = styledComponents.mainBox;

// Main application component that sets up the theme and routing
// It utilizes states for theme-mode, handles theme toggling, and contains effects for document title and authentication states.
function App() {
    // Logs render statement to console
    //console.log('Render App');
    const [sessionError] = authStore.useState('sessionError');
    // Light mode has been removed until it gets improved
    //const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)'); // checks browser/OS for preferred color scheme.
    //const [mode, setMode] = useState(localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light');
    const [mode, setMode] = useState('dark');

    // Memoized callback function that manages the color mode toggling by updating 'mode' state and local storage
    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => {
                    if (prevMode === 'light') {
                        localStorage.setItem('theme', 'dark');
                        return 'dark';
                    } else {
                        localStorage.setItem('theme', 'light');
                        return 'light';
                    }
                });
            }
        }),
        []
    );

    // React hook that memoizes the theme object based on 'mode' to avoid unnecessary recalculations and re-renders
    const theme = React.useMemo(() => createTheme({ ...getTheme(mode) }), [mode]);

    const { getCurrentAuthStates } = useCognito();

    // useEffect hook to set the document title on component mount
    useEffect(() => {
        document.title = 'Nemos';
        document.body.style.backgroundColor = theme.palette.background.default;
    });

    // useEffect hook to check the current authentication session on mount or when sessionError changes, by invoking 'getCurrentAuthStates'
    useEffect(() => {
        const checkCurrentSession = async () => {
            // console log for checking current session statement
            // Check current session
            await getCurrentAuthStates();
        };
        console.log('session error set');
        checkCurrentSession();
    }, [sessionError]);

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <GlobalAlert />
                    <Header />
                    <MainBox>
                        <Toolbar />
                        <Box className="pageContainer">
                            <AppRoutes></AppRoutes>
                        </Box>
                    </MainBox>
                    <Footer />
                </BrowserRouter>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

// Exports the App component as default from this module
export default App;
