// React Imports
import React from 'react';
// Mui Imports
import { Button } from '@mui/material';
// Utils
import { downloadFile } from 'utils/downloadFile';

function DownloadTemplate() {
    const templateManagerCSV = 'Manager Name,Manager Email,Manager Id,Team Member Name,Team Member Email,Team Member Id\r\n';

    /**
     * Function that invokes the download process
     */
    const handleDownload = () => {
        downloadFile(new Blob([templateManagerCSV]), 'ManagerTemplate.csv');
    };

    return (
        <React.Fragment>
            <Button sx={{ minWidth: 170, height: 38 }} variant="outlined" onClick={handleDownload}>
                Download Template
            </Button>
        </React.Fragment>
    );
}

export default DownloadTemplate;
