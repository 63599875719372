// React Imports
import * as React from 'react';
// MUI Imports
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

/**
 *
 * @param {String} title title of the dialog. Default =  "Are you sure?"
 * @param {String} content content of the dialog
 * @param {String} negActionLabel label for the negative action
 * @param {String} posActionLabel label for the positive action
 * @param {Boolean} open designates whether the component is open or not
 * @param {Function} handleNeg function that handles the negative action
 * @param {Function} handlePos function that handles the positive action
 * @returns
 */
function SureDialog({ title = 'Are you sure?', content, negActionLabel = 'No', posActionLabel = 'Yes', open, handleNeg, handlePos }) {
    const theme = useTheme();

    return (
        <Dialog maxWidth="xs" fullWidth={true} open={open} onClose={handleNeg} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle sx={{ display: 'flex', justifyContent: 'center', color: theme.palette.primary.main }} id="alert-dialog-title">
                {title}
            </DialogTitle>
            {content && (
                <DialogContent sx={{ textAlign: 'center' }}>
                    <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                </DialogContent>
            )}
            <DialogActions>
                <Button onClick={handleNeg}>{negActionLabel}</Button>
                <Button onClick={handlePos} autoFocus>
                    {posActionLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SureDialog;
