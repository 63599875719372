import { createStore } from 'state-pool';

const billingStore = createStore();

billingStore.setState('stripe', {});
billingStore.setState('cognito', localStorage.getItem('cognito') ? localStorage.getItem('cognito') : {});
billingStore.setState('stripeProfile', {});
billingStore.setState('profiles', []);

export default billingStore;
