// React Imports
import React from 'react';
// MUI Imports
import { Box } from '@mui/material';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';

import { reportStore } from 'stores';

function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 5,
                pb: 0
            }}
        >
            <GridToolbarQuickFilter />
        </Box>
    );
}

export default function SFFieldTypeSelector() {
    const [selectedFieldTypes, setSelectedFieldTypes] = reportStore.useState('selectedFieldTypes');
    const fieldTypes = [
        { id: 'Address', fieldType: 'Address' },
        { id: 'Checkbox', fieldType: 'Checkbox' },
        { id: 'Currency', fieldType: 'Currency' },
        { id: 'Date', fieldType: 'Date' },
        { id: 'Date/Time', fieldType: 'Date/Time' },
        { id: 'Email', fieldType: 'Email' },
        { id: 'fax', fieldType: 'Fax' },
        { id: 'formula', fieldType: 'Formula' },
        { id: 'hierarchy', fieldType: 'Hierarchy' },
        { id: 'longTextArea', fieldType: 'Long Text Area' },
        { id: 'lookup', fieldType: 'Lookup' },
        { id: 'name', fieldType: 'Name' },
        { id: 'number', fieldType: 'Number' },
        { id: 'percent', fieldType: 'Percent' },
        { id: 'phone', fieldType: 'Phone' },
        { id: 'picklist', fieldType: 'Picklist' },
        { id: 'picklistMultiSelect', fieldType: 'Picklist (Multi-Select)' },
        { id: 'rollUpSummary', fieldType: 'Roll-Up Summary' },
        { id: 'text', fieldType: 'Text' },
        { id: 'textArea', fieldType: 'Text Area' },
        { id: 'url', fieldType: 'URL' }
    ];
    const columns = [
        {
            field: 'fieldType',
            headerName: 'Field Type',
            flex: 1
        }
    ];

    /**
     * Handles the row selection event for the fiel type datagrid.
     * @param {Array} selectedRows array of strings representing the currently selected field types.
     */
    const handleSelectRow = (selectedRows) => {
        setSelectedFieldTypes(selectedRows);
    };

    return (
        <DataGrid
            getRowId={(row) => row.fieldType}
            rows={fieldTypes}
            columns={columns}
            checkboxSelection
            onSelectionModelChange={handleSelectRow}
            selectionModel={selectedFieldTypes}
            components={{ Toolbar: QuickSearchToolbar }}
        />
    );
}
