// React Imports
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// MUI Imports
import { Avatar, Box, Button, Checkbox, Container, CssBaseline, FormControlLabel, Grid, Link, TextField, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// API Imports
import { createAccount } from 'features/accounts';
import { PasswordValidator } from 'features/auth';
import { useCognito } from 'features/auth';
// Component Imports
import { validateEmail } from 'utils/validateEmail';

import { miscStore } from 'stores';

const Copyright = (props) => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="http://www.nemos.io/">
                Nemos
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
};

export default function SignUp() {
    // Constants
    const navigate = useNavigate();

    // States
    const [, , updateAlert] = miscStore.useState('alert');
    const [company, setCompany] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState('');
    const [confirmPasswordVal, setConfirmPasswordVal] = useState('');
    const [confirmPasswordValid, setConfirmPasswordValid] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');
    const [firstName, setFirstName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [lastName, setLastName] = useState('');
    const [passwordVal, setPasswordVal] = useState('');
    const [passwordValid, setPasswordValid] = useState(false);

    const { signUp } = useCognito();

    // Local Methods
    /**
     * Navigates to the Login Page.
     */
    const navigateToLogin = () => {
        navigate('/login', { replace: true });
    };

    /**
     * Handles the close for the Alert Message.
     * @param {Object} event
     * @param {String} reason
     * @returns
     */
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    /**
     * Creates a new Cognito User
     * @param {Object} data new user details
     * @param {String} accId Id of the Account to create the user on.
     * @returns The result of the use creation.
     */
    const createCognitoUser = async (data, accId) => {
        // Create new User
        const namespace = data.get('companyName').replaceAll(' ', '');
        const userDetails = {
            accId: { Name: 'custom:accId', Value: accId },
            companyName: { Name: 'custom:companyName', Value: data.get('companyName') },
            email: { Name: 'email', Value: data.get('email') },
            name: { Name: 'name', Value: `${data.get('firstName')} ${data.get('lastName')}` },
            namespace: { Name: 'custom:namespace', Value: namespace },
            password: data.get('password'),
            username: data.get('email')
        };
        return userDetails;
    };

    /**
     * Handles the submission for the Sign Up form.
     * @param {Object} event Sign-up form data
     */
    const handleSignUp = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const data = new FormData(event.currentTarget);
        if (!emailError) {
            const accDetails = {
                name: data.get('companyName')
            };
            // Creates a new org
            const newAccResult = await createAccount(accDetails);
            const user = await createCognitoUser(data, newAccResult._id);
            try {
                await signUp(user);
                navigate('/sign-up-success', { state: { email: data.get('email') }, replace: true });
            } catch (err) {
                console.log('Err = ', err);
                updateAlert((alert) => {
                    alert.autoHideDuration = 3000;
                    alert.message = err.body;
                    alert.open = true;
                    alert.severity = 'error';
                    alert.onClose = handleAlertClose;
                });
            }
        }
        setIsLoading(false);
    };

    /**
     * Handles the change in value of the password
     * @param {Object} event details of the password change
     */
    const handlePasswordChange = (event) => {
        handlePasswordMatching(event.target.value, confirmPasswordVal);
        setPasswordVal(event.target.value);
    };

    /**
     * Handles the change in value of the Confirm Password field.
     * @param {Object} event
     */
    const handleConfirmPasswordChange = (event) => {
        handlePasswordMatching(event.target.value, passwordVal);
        setConfirmPasswordVal(event.target.value);
    };

    /**
     * Handles matching passowrds.
     * @param {String} password the current password being modified
     * @param {String} passwordToMatch the password to match against
     */
    const handlePasswordMatching = (password, passwordToMatch) => {
        if (password !== '' && passwordToMatch === password) {
            setConfirmPasswordError(false);
            setConfirmPasswordErrorText('');
            setConfirmPasswordValid(true);
        } else if (password !== '' && passwordToMatch !== password) {
            setConfirmPasswordError(true);
            setConfirmPasswordErrorText('Passwords do not match');
            setConfirmPasswordValid(false);
        } else {
            setConfirmPasswordError(false);
            setConfirmPasswordErrorText('');
            setConfirmPasswordValid(false);
        }
    };

    /**
     * Handles the change in value of the First Name
     * @param {Object} event details of the First Name change
     */
    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    /**
     * Handles the change in value of the Last Name
     * @param {Object} event details of the Last Name change
     */
    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    /**
     * Handles the change in input for the email field.
     * @param {Object} event event details for change on email field.
     */
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        if (!validateEmail(event.target.value)) {
            setEmailError(true);
            setEmailErrorText('Invalid format.');
        } else {
            setEmailError(false);
            setEmailErrorText('');
        }
    };

    /**
     * Handles the change in value of the Company
     * @param {Object} event details of the Company change
     */
    const handleCompanyChange = (event) => {
        setCompany(event.target.value);
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <AccountCircleIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <Box component="form" noValidate onSubmit={handleSignUp} sx={{ mt: 12 }}>
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                id="firstName"
                                label="First Name"
                                required
                                fullWidth
                                autoFocus
                                onChange={handleFirstNameChange}
                                InputLabelProps={{
                                    shrink: true,
                                    style: { fontWeight: 'bold', fontSize: 20 }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="family-name"
                                onChange={handleLastNameChange}
                                InputLabelProps={{
                                    shrink: true,
                                    style: { fontWeight: 'bold', fontSize: 20 }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                error={emailError}
                                helperText={emailErrorText}
                                onChange={handleEmailChange}
                                InputLabelProps={{
                                    shrink: true,
                                    style: { fontWeight: 'bold', fontSize: 20 }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="companyName"
                                label="Company"
                                id="companyName"
                                autoComplete="organization"
                                onChange={handleCompanyChange}
                                InputLabelProps={{
                                    shrink: true,
                                    style: { fontWeight: 'bold', fontSize: 20 }
                                }}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        onChange={handlePasswordChange}
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                        InputLabelProps={{
                                            shrink: true,
                                            style: { fontWeight: 'bold', fontSize: 20 }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        error={confirmPasswordError}
                                        onChange={handleConfirmPasswordChange}
                                        helperText={confirmPasswordErrorText}
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        type="password"
                                        id="confirmPassword"
                                        autoComplete="new-password"
                                        InputLabelProps={{
                                            shrink: true,
                                            style: { fontWeight: 'bold', fontSize: 20 }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <PasswordValidator passwordVal={passwordVal} setPasswordValid={(val) => setPasswordValid(val)}></PasswordValidator>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel control={<Checkbox value="allowExtraEmails" color="primary" />} label="I want to receive inspiration, marketing promotions and updates via email." />
                        </Grid>
                    </Grid>
                    {isLoading ? (
                        <LinearProgress></LinearProgress>
                    ) : (
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={!passwordValid || !confirmPasswordValid || !firstName || !lastName || !email || !company}>
                            Sign Up
                        </Button>
                    )}
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link onClick={navigateToLogin} component="button" variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
        </Container>
    );
}
