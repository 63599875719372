// React Imports
import React, { useEffect, useRef, useState, Suspense } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

// MUI Imports
import { Button, IconButton, Paper, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// Services
import { getProjectsByAccount } from '../services/getProjectsByAccount';

// Component Imports
import NoRowsAlert from 'components/ui/NoRowsAlert';
import ProjectDialog from './ProjectDialog';
import TableHeader from 'components/ui/TableHeader';

// Data Imports
import { authStore } from 'stores';

function ProjectsTable() {
    //Constants
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [searchParams] = useSearchParams();
    const returnedFromOrgAuth = searchParams.has('authResult');
    const columns = [
        { headerName: 'Name', field: 'name', flex: 1 },
        { headerName: 'Org Name', field: 'orgName', flex: 1 },
        {
            headerName: 'Created Date',
            field: 'createdAt',
            hidden: isSmallScreen ? true : false,
            flex: 1,
            renderCell: (cellValues) => new Date(cellValues.value).toLocaleDateString()
        },
        {
            headerName: 'Status',
            field: 'status',
            renderCell: (cellValues) => {
                return isSmallScreen ? cellValues.value === 'Completed' ? <CheckCircleOutlineIcon sx={{ color: 'lightgreen' }} /> : <MoreHorizIcon color="primary" /> : cellValues.value;
            }
        },
        {
            headerName: '',
            field: 'projectNav',
            align: 'center',
            sortable: false,
            filterable: false,
            renderCell: (cellValues) => {
                return (
                    <Link to={`/${passwordSession.sessionDetails.namespace}/project/${cellValues.row._id}`}>
                        <IconButton aria-label="open" color="primary">
                            <KeyboardArrowRightIcon />
                        </IconButton>
                    </Link>
                );
            }
        }
    ];

    //useStates
    const [passwordSession] = authStore.useState('passwordSession');
    const [isLoading, setIsLoading] = useState(true);
    const isMounted = useRef(true);
    const [open, setOpen] = useState(returnedFromOrgAuth);
    const [pageSize, setPageSize] = useState();
    const [projects, setProjects] = useState([]);
    const [triggerRefresh, setTriggerRefresh] = useState(false);

    const PROJECT_TABLE_ACTIONS = [
        <Button key="newProject" sx={{ whiteSpace: 'nowrap', mr: 5 }} id="salesforce" variant="contained" onClick={() => handleNewProject()}>
            New Project
        </Button>
    ];

    //useEffects
    /**
     * useEffect to get the accounds Projects
     */
    useEffect(() => {
        isMounted.current = true;
        // Retrieves all Projects for the currently logged in Account.
        const getProjects = async () => {
            const projects = await getProjectsByAccount({ status: 'Open' });
            if (isMounted.current) {
                setIsLoading(false);
                setProjects(projects);
            }
        };
        getProjects();
        return () => {
            isMounted.current = false;
        };
    }, [triggerRefresh]);

    //Functions
    /**
     * Function to handle the click of New Project
     */
    const handleNewProject = () => {
        setOpen(true);
    };

    /**
     * Function that handles the Closing of the
     */
    const handleNewProjectClose = () => {
        setTriggerRefresh(!triggerRefresh);
        setOpen(false);
    };

    /**
     * When resizing the window to a small screen, the autoPageSize prop on DataGrid kicks in
     * and calulates the num of rows to show to prevent scrolling. We store that value in
     * pageSize and pass it to the pageSize prop.
     * @param {Number} numOfRows the number of rows to show in the table.
     */
    const handlePageSizeChange = (numOfRows) => {
        setPageSize(numOfRows);
    };

    return (
        <React.Fragment>
            <Paper elevation={6} sx={{ display: 'flex', flexDirection: 'column', height: 400 }}>
                <TableHeader icon={<AssignmentIcon color="primary" />} title="Open Projects" showActions actions={PROJECT_TABLE_ACTIONS} />
                <Suspense fallback={<CircularProgress />}>
                    <DataGrid
                        autoPageSize={isSmallScreen ? true : false}
                        columns={columns}
                        columnVisibilityModel={columns.reduce((acc, column) => {
                            acc[column.field] = column.hidden ? false : true;
                            return acc;
                        }, {})}
                        components={{ NoRowsOverlay: () => <NoRowsAlert title="No Projects" message="Get started by adding a Project!"></NoRowsAlert> }}
                        disableSelectionOnClick
                        loading={isLoading}
                        getRowId={(row) => row._id}
                        onPageSizeChange={handlePageSizeChange}
                        pageSize={isSmallScreen ? pageSize : 100}
                        rowsPerPageOptions={[100]}
                        rowHeight={50}
                        rows={projects}
                    ></DataGrid>
                </Suspense>
            </Paper>
            <ProjectDialog open={open} onClose={handleNewProjectClose} />
        </React.Fragment>
    );
}

export default ProjectsTable;
