// React Imports
import React from 'react';
import { useParams } from 'react-router-dom';
// MUI Imports
import { Box } from '@mui/material';
// File Import
import { RemediationTaskTable } from 'features/remediationTasks';

function RemediationTasks() {
    //Constants
    const { reportId } = useParams();

    return (
        <Box sx={{ maxHeight: 'calc(100vh - 6rem)', boxSizing: 'border-box', flex: 1, p: 10, display: 'flex' }}>
            <RemediationTaskTable reportId={reportId}></RemediationTaskTable>
        </Box>
    );
}

export default RemediationTasks;
