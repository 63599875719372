import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to retrieve all Projects for an Account.
 * @param {Object} filterCriteria Filter object for getting Projects
 * @returns array of projects
 */
export const getProjectsByAccount = async (filterCriteria) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        withCredentials: true
    };

    const requestPath = `/project/byAcc?filterCriteria=${encodeURIComponent(JSON.stringify(filterCriteria))}`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
