// React Imports
import React, { useEffect, useCallback, useRef, useState } from 'react';
// MUI Imports
import { Paper, Toolbar, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
// API Imports
import { getManagerData } from '../services/getManagerData';
import { getManagerAssignments } from 'features/salesforce';
// Helpers
import { flattenObj } from 'utils/flattenObj';
import { reportStore } from 'stores';
// Hooks
import { useSalesforceData } from 'features/salesforce';

function ManagerDataTable({ percentComplete }) {
    const salesforceRequests = useCallback(() => [getManagerAssignments()], []);
    const { data } = useSalesforceData(salesforceRequests);

    // States
    const [isLoading, setIsLoading] = useState();
    const [managerInfo, , updateManagerInfo] = reportStore.useState('managerInfo');
    const isMounted = useRef(true);

    // Constants
    const columns = [
        { field: 'Name', headerName: 'Team Member', headerAlign: 'center', align: 'center', flex: 1 },
        { field: managerInfo.managerNameApi, headerName: 'Manager', headerAlign: 'center', align: 'center', flex: 1 }
    ];
    const theme = useTheme();

    /**
     * useEffect to get the managerData based on the input from managerInfo
     */
    useEffect(() => {
        if (managerInfo.managerSource === 'salesforce' && data.length > 0) {
            const flattenedData = data[0].reduce((accumulator, row) => {
                accumulator[row.Email] = flattenObj(row);
                return accumulator;
            }, {});
            console.log('manager info = ', flattenedData);
            updateManagerInfo((managerInfo) => {
                managerInfo.managerData = flattenedData;
            });
        } else if (managerInfo.managerSource === 'csv') {
            isMounted.current = true;
            const generateManagerDataByCSV = async () => {
                setIsLoading(true);
                //Get File from S3
                const managerData = await getManagerData(managerInfo.managerCSVName);

                //Setting Manager Info for the UAR Stepper Grandparent
                if (isMounted.current) {
                    updateManagerInfo((managerInfo) => {
                        managerInfo.managerData = managerData;
                        managerInfo.managerReady = true;
                        managerInfo.managerSource = 'csv';
                        managerInfo.managerNameApi = 'Manager.Name';
                    });
                }
                setIsLoading(false);
            };
            generateManagerDataByCSV();
            return () => {
                isMounted.current = false;
            };
        }
    }, [data]);

    /**
     * Function to determine color of the Percent Complete based on light v dark and percentage
     * @returns a String hexcode for the Percent Complete
     */
    const determineColor = () => {
        return percentComplete === 100
            ? theme.palette.text.success
            : percentComplete < 10
            ? theme.palette.text.error
            : theme.palette.mode === 'dark'
            ? theme.palette.primary.main
            : theme.palette.text.primary;
    };

    return (
        <React.Fragment>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <Toolbar sx={{ pl: { sm: 6 }, pr: { xs: 1, sm: 1 } }}>
                    <Typography sx={{ flex: '1 1 100%', textAlign: 'left' }} variant="h6" id="tableTitle">
                        Review your Manager Data
                    </Typography>
                    <Typography sx={{ flex: '1 1 100%', textAlign: 'right', mr: 5, color: determineColor }} variant="h6" id="tableAttribute">
                        {percentComplete}% Complete
                    </Typography>
                </Toolbar>
                {managerInfo.managerData && (
                    <DataGrid
                        autoHeight={true}
                        columns={columns}
                        disableSelectionOnClick
                        getRowId={(row) => row.Id}
                        loading={isLoading}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        rowHeight={50}
                        rows={Object.values(managerInfo.managerData)}
                    />
                )}
            </Paper>
        </React.Fragment>
    );
}

export default ManagerDataTable;
