// React
import React, { useCallback, useState } from 'react';
// Mui
import { Box, Button, Container, Grid, Paper, Tabs, Tab, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// Components
import LoadingIndicator from 'components/ui/LoadingIndicator';
import SureDialog from 'components/ui/SureDialog';
// Features
import { OrgCombobox } from 'features/organizations';
import { SettingsSideBar } from 'features/settings';
import { restoreOrgDefaults, updateOrg } from 'features/organizations';
import { getSObjects, getSystemSettings, ObjectSettingList, SystemSettingList, useSalesforceData } from 'features/salesforce';
// Data
import { miscStore, reportStore } from 'stores';
import { alertMessages } from 'data/alerts';

const LOADING_STEPS = [
    {
        key: 0,
        label: 'Retrieving Salesforce Data'
    }
];

/**
 * Panel component that contains the body of each Tab
 * @param {Object} props all of the properties needed for building the TabPanel.
 * @returns html for the TabPanel component.
 */
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

/**
 * Props to inject into each Tab element.
 * @param {Integer} index
 * @returns Object of the different properties to inject
 */
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

function DefaultKeyPermissions() {
    const theme = useTheme();

    const [, , updateAlert] = miscStore.useState('alert');
    const [currentOrg, setCurrentOrg] = reportStore.useState('currentOrg');
    const [currentTab, setCurrentTab] = useState(0);
    const salesforceRequests = useCallback(() => [getSObjects(), getSystemSettings()], []);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [selectedType] = useState();
    const [sureIsOpen, setSureIsOpen] = useState(false);

    // Set selected object global states
    reportStore.setState('selectedObjects', currentOrg.defaultSystemSettings);
    reportStore.setState('selectedSystemSettings', currentOrg.defaultObjects);

    // Set Salesforce global states
    const { data, isError, isLoading } = useSalesforceData(salesforceRequests);
    if (isError) {
        // Clear org selection
        setCurrentOrg({});
    } else if (data.length > 0) {
        const [sObjects, systemSettings] = data;
        reportStore.setState('allObjects', sObjects);
        reportStore.setState('allSystemSettings', systemSettings);
    }

    /**
     * @description handles saving the currently selected key settings
     */
    const handleSave = async (type) => {
        setSaveDisabled(true);
        //const selectedSystemSettings = reportStore.getState('selectedSystemSettings').value;
        const selectedSystemSettings = reportStore.getState('selectedSystemSettings').getValue();
        const selectedObjects = reportStore.getState('selectedObjects').getValue();
        try {
            const updatedOrg = type === 'settings' ? { ...currentOrg, defaultSystemSettings: selectedSystemSettings } : { ...currentOrg, defaultObjects: selectedObjects };

            setCurrentOrg(await updateOrg(updatedOrg));
            postAlert('success', alertMessages.success.saved);
        } catch (err) {
            postAlert('error', alertMessages.error.saved);
        } finally {
            setSaveDisabled(false);
        }
    };

    /**
     * Triggers a global alert.
     * @param {String} severity severity of the alert
     * @param {String} message message to display
     */
    const postAlert = (severity, message) => {
        updateAlert((alert) => {
            alert.autoHideDuration = 3000;
            alert.message = message;
            alert.open = true;
            alert.severity = severity;
            alert.onClose = handleAlertClose;
        });
    };

    /**
     *
     * @description updates the tab value
     * @param {Number} newValue either 0 or 1 for the new tab value
     */
    const handleTab = (e, newValue) => {
        setCurrentTab(newValue);
    };

    /**
     * Function to close the toast notification
     */
    const handleAlertClose = () => {
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    /*
     * Function to handle restoring default permissions/objects
     */
    const handleYes = async () => {
        try {
            setCurrentOrg(await restoreOrgDefaults(currentOrg, selectedType));
            setSureIsOpen(false);
            postAlert('success', alertMessages.success.success);
        } catch (err) {
            postAlert('error', alertMessages.error.success);
        } finally {
            setSaveDisabled(false);
        }
    };

    const handleSureClose = () => {
        setSureIsOpen(false);
    };

    return (
        <>
            <SettingsSideBar></SettingsSideBar>
            <Container maxWidth="xs" sx={{ pt: 20 }}>
                <Grid container spacing={15} sx={{ justifyContent: 'center' }}>
                    <Grid item sx={{ width: 300 }}>
                        <OrgCombobox />
                        {isError && (
                            <Typography variant="body2" color={theme.palette.text.error} textAlign="center" fontWeight="bold">
                                Unable to retrieve Salesforce data. Select another org or contact support.
                            </Typography>
                        )}
                    </Grid>
                    <Grid item>
                        {isLoading ? (
                            <LoadingIndicator steps={LOADING_STEPS}></LoadingIndicator>
                        ) : (
                            data.length > 0 && (
                                <Paper elevation={1} sx={{ borderRadius: 4, width: 800, p: 10 }}>
                                    <Tabs value={currentTab} onChange={handleTab} variant="fullWidth">
                                        <Tab label="Key Settings" {...a11yProps(0)}></Tab>
                                        <Tab label="Object Settings" {...a11yProps(1)}></Tab>
                                    </Tabs>
                                    <TabPanel value={currentTab} index={0}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 15, height: '60vh' }}>
                                            <SystemSettingList inSettings={true}></SystemSettingList>
                                            <Button
                                                sx={{ width: 'max-content', alignSelf: 'center' }}
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={() => handleSave('settings')}
                                                disabled={saveDisabled}
                                            >
                                                Save Default Settings
                                            </Button>
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={currentTab} index={1}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 15, height: '60vh' }}>
                                            <ObjectSettingList defaultsOptionEnabled={true} inSettings={true}></ObjectSettingList>
                                            <Button
                                                sx={{ width: 'max-content', alignSelf: 'center' }}
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={() => handleSave('objects')}
                                                disabled={saveDisabled}
                                            >
                                                Save Default Objects
                                            </Button>
                                        </Box>
                                    </TabPanel>
                                </Paper>
                            )
                        )}
                    </Grid>
                    <SureDialog
                        title="Restore Defaults"
                        content="Are you sure you want to restore PixelTag Reccommended Defaults?"
                        open={sureIsOpen}
                        handleNeg={handleSureClose}
                        handlePos={handleYes}
                    ></SureDialog>
                </Grid>
            </Container>
        </>
    );
}

export default DefaultKeyPermissions;
