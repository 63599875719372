// React Imports
import React from 'react';
// MUI Imports
import { Stack, Toolbar, Typography } from '@mui/material';

/**
 * TableHeader is a functional component that renders a table header with optional actions and an icon.
 *
 * @param {Array} actions - Optional array of action components to render.
 * @param {Element} icon - Optional icon to display alongside the title.
 * @param {boolean} showActions - Determines if action elements should be shown.
 * @param {string} title - The title of the table.
 * @returns React Element
 */
function TableHeader({ actions, icon, showActions, title }) {
    // Renders the table header with optional icon, title, and actions
    return (
        <Toolbar
            sx={{
                pl: 8,
                pr: 8
            }}
        >
            <React.Fragment>
                <Stack sx={{ flex: '1' }} alignItems="center" direction="row" spacing={4}>
                    {icon /* Optional icon to be displayed before the title */}
                    <Typography variant="h6" id="tableTitle" component="div" sx={{ pt: 1 }}>
                        {title /* The title of the table displayed as a heading */}
                    </Typography>
                </Stack>

                {showActions && actions.map((action, index) => <React.Fragment key={index}>{action /* Action components are rendered here if showActions is true */}</React.Fragment>)}
            </React.Fragment>
        </Toolbar>
    );
}

export default TableHeader;
