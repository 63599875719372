import { fetchData } from 'services/fetchData';

import { authStore, reportStore } from 'stores';

/**
 *
 * @param {[String]} sObjects sObjects to query for the report
 * @param {[String]} fieldTypes Salesforce field types to filter for
 * @returns Array of objects containing field information
 */
export const getSObjectFields = async (sObjects, fieldTypes) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;
    const organization = reportStore.getState('currentOrg').value;

    const httpOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`
        },
        withCredentials: true,
        body: JSON.stringify({
            orgId: organization._id,
            sObjects: sObjects,
            fieldTypes: fieldTypes
        })
    };
    const { data, response } = await fetchData(`/salesforce/sObjectFields`, httpOptions);
    return { data, response };
};
