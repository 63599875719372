// React Imports
import React, { useCallback, useMemo } from 'react';
// MUI Imports
import { Box, Button, Paper, Step, StepLabel, Stepper } from '@mui/material';
// Component Imports
import LoadingIndicator from 'components/ui/LoadingIndicator';
import { ObjectFieldReport, SFFieldTypeSelector } from 'features/salesforceScanner';
import { ObjectSettingList } from 'features/salesforce';
import { reportStore } from 'stores';
// API Imports
import { getSObjects, useSalesforceData } from 'features/salesforce';
import { OrgCombobox } from 'features/organizations';

function SalesforceScanner() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [currentOrg, setCurrentOrg] = reportStore.useState('currentOrg');
    const loadingSteps = [
        {
            key: 0,
            label: 'Retrieving Salesforce Data...'
        }
    ];
    const [selectedFieldTypes, setSelectedFieldTypes] = reportStore.useState('selectedFieldTypes');
    const [selectedObjects, setSelectedObjects] = reportStore.useState('selectedObjects');

    // Get Salesforce Objects
    const salesforceRequests = useCallback(() => [getSObjects()], []);
    const { data, isError, isLoading } = useSalesforceData(salesforceRequests);
    console.log('Data = ', data);
    if (isError) {
        // Clear org selection
        setCurrentOrg({});
    } else if (data.length > 0) {
        const [sObjects] = data;
        const objects = Object.values(sObjects).reduce((objects, data) => {
            objects[data.SobjectType] = { ...data, checked: false };
            return objects;
        }, {});
        reportStore.setState('allObjects', objects);
    }

    /**
     * Navigates to the next step in the Salesforce scanner flow
     */
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    /**
     * Navigates backwards in the Salesforce scanner flow
     */
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    /**
     * Resets the Salesforce scanner to the first step
     */
    const handleReset = () => {
        setSelectedFieldTypes([]);
        setSelectedObjects([]);
        setCurrentOrg({});
        setActiveStep(0);
    };

    const steps = useMemo(() => {
        return [
            { label: 'Choose an Org', content: <OrgCombobox /> },
            {
                label: 'Select Objects',
                content: (
                    <Paper sx={{ height: 500, width: '60vw' }}>
                        <ObjectSettingList defaultsOptionEnabled={false} inSettings={true}></ObjectSettingList>
                    </Paper>
                )
            },
            {
                label: 'Select Field Types',
                content: (
                    <Paper sx={{ height: 500, width: '60vw' }}>
                        <SFFieldTypeSelector></SFFieldTypeSelector>
                    </Paper>
                )
            }
        ];
    }, []);
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 15, flex: 1, p: 20 }}>
            <Stepper activeStep={activeStep}>
                {steps.map((step) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={step.label} {...stepProps}>
                            <StepLabel {...labelProps}>{step.label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <ObjectFieldReport></ObjectFieldReport>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 10 }}>
                        {isLoading && (
                            <Box sx={{ p: 10 }}>
                                <LoadingIndicator steps={loadingSteps} />
                            </Box>
                        )}

                        <Box sx={{ display: isLoading ? 'none' : 'initial' }}>{steps[activeStep].content}</Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button
                            onClick={handleNext}
                            disabled={activeStep === 0 ? !currentOrg._id : activeStep === 1 ? selectedObjects.length === 0 : activeStep === 2 ? selectedFieldTypes.length === 0 : false}
                        >
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
}

export default SalesforceScanner;
