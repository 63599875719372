import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to retrieve a Project by Id.
 * @param {String} projId Id of the Project to retrieve.
 * @returns project
 */
export const getProject = async (projId) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        method: 'GET',
        withCredentials: true
    };

    const requestPath = `/project?projId=${projId}`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
