// React Imports
import React, { useState } from 'react';
// Mui Imports
import { Box, Grid, Typography, Avatar, Button, Card, CardContent, CardHeader, Divider, Stack, TextField, CardActions } from '@mui/material';
// Component Imports
import { SettingsSideBar } from 'features/settings';
// API Imports
import { setProfilePicture } from 'features/accounts';
import { useCognito } from 'features/auth';
// Utils
import { validateEmail } from 'utils/validateEmail';
// Data
import nemosFoxLogoDark from 'assets/NemosFoxLogoDarkMode.png';
import { authStore, miscStore } from 'stores';
import { alertMessages } from 'data/alerts';

function PersonalProfile() {
    // States
    const [, , updateAlert] = miscStore.useState('alert');
    const [profilePictureUrl, setProfilePictureUrl] = miscStore.useState('profilePictureUrl');
    const [passwordSession] = authStore.useState('passwordSession');
    const { getSession, updateUserInfo } = useCognito();

    console.log('setting user info!');
    const [userInfo, setUserInfo] = useState({
        email: passwordSession.sessionDetails.email,
        name: passwordSession.sessionDetails.name
    });

    /**
     *
     * Handles changing the user info input changing
     */
    const handleChange = (event) => {
        setUserInfo((oldVal) => ({ ...oldVal, [event.target.name]: event.target.value }));
    };

    /**
     * @description Handles updating personal details in Cognito then refreshing the display name
     * @param {Object} event
     */
    const handleSave = async (event) => {
        event.preventDefault();
        try {
            // Update details in cognito
            await changePersonalDetails();

            // refresh the session
            await getSession();
        } catch (err) {
            console.log('Error: ', err);
        }
    };

    /**
     * Function to close the toast notification
     */
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    /**
     * Updates the users email and address in Cognito after validating email
     * @returns The name and email values if the update was successful
     */
    const changePersonalDetails = async () => {
        if (!validateEmail(userInfo.email)) {
            postAlert('error', alertMessages.error.email_format);
        } else {
            try {
                const response = await updateUserInfo(userInfo);
                if (response.type === 'Success') {
                    postAlert('success', alertMessages.success.profile_updated);
                    console.log('Users Details = ', userInfo);
                    return userInfo;
                }
            } catch (err) {
                postAlert('error', alertMessages.error.profile_updated);
                console.log('Error updating user info: ', err);
            }
        }
    };

    /**
     *
     * Handles uploading a new profile picture
     */
    const handlePictureChange = async (event) => {
        try {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = async () => {
                const fileData = {
                    rawData: reader.result,
                    jwtToken: passwordSession.sessionDetails.jwtToken
                };
                const response = await setProfilePicture(fileData);
                if (response.url) setProfilePictureUrl(response.url);
                postAlert('success', alertMessages.success.picture_uploaded);
            };
        } catch (err) {
            postAlert('error', alertMessages.error.picture_uploaded);
            console.log('Error uploading picture: ', err);
        } finally {
            event.target.value = null;
        }
    };

    /**
     * Triggers a global alert.
     * @param {String} severity severity of the alert
     * @param {String} message message to display
     */
    const postAlert = (severity, message) => {
        updateAlert((alert) => {
            alert.autoHideDuration = 3000;
            alert.message = message;
            alert.open = true;
            alert.severity = severity;
            alert.onClose = handleAlertClose;
        });
    };

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', flex: 1 }}>
                <SettingsSideBar></SettingsSideBar>
                <Box sx={{ flex: 1 }}>
                    <Grid container spacing={3}>
                        <Grid item lg={4} md={6} xs={12}>
                            <Card>
                                <CardContent>
                                    <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', padding: '5%' }}>
                                        <Avatar src={profilePictureUrl || nemosFoxLogoDark} sx={{ height: 85, mb: 2, width: 85 }} />
                                        <Typography color="textPrimary" gutterBottom variant="h5" sx={{ padding: '10%' }}>
                                            {userInfo.name}
                                        </Typography>
                                    </Box>
                                </CardContent>
                                <Divider />
                                <CardActions sx={{ maxHeight: '15%', p: 2 }}>
                                    <Button variant="contained" fullWidth component="label">
                                        Change Profile Picture
                                        <input type="file" accept="image/png, image/gif, image/jpeg" hidden onChange={handlePictureChange} />
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item lg={8} md={6} xs={12}>
                            <Box component="form" noValidate onSubmit={handleSave} sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Card>
                                    <CardHeader subheader="This information can be edited" title="Personal Profile" />
                                    <Divider />
                                    <CardContent>
                                        <Stack spacing={10}>
                                            <TextField label="Name" name="name" onChange={handleChange} value={userInfo.name} id="name" required variant="outlined" />
                                            <TextField label="Email Address" name="email" value={userInfo.email} key="email" required variant="outlined" InputProps={{ readOnly: true }} />
                                        </Stack>
                                    </CardContent>
                                    <Divider />
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                                        <Button type="submit" color="primary" variant="contained">
                                            Save details
                                        </Button>
                                    </Box>
                                </Card>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default PersonalProfile;
