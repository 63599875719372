/**
 * Function that takes an object and flattens it to one level
 * @param {!ob} obj Object to flatten to a single level
 * @returns Flattend Object
 */
export const flattenObj = (ob) => {
    let result = {};
    for (const i in ob) {
        if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
            const temp = flattenObj(ob[i]);
            for (const j in temp) {
                result[i + '.' + j] = temp[j];
            }
        } else {
            result[i] = ob[i];
        }
    }
    return result;
};
