import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 *
 * @param {Object} project Project Id to Update
 * @returns Updated Project or Error
 */
export const updateProject = async (project) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        body: JSON.stringify(project)
    };

    const requestPath = `/project?Id=${project._id}`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
