import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Function to get Manager Data from S3
 * @param {String} managerCSVName name of the CSV
 * @returns Response Object from API
 */
export const getManagerData = async (managerCSVName) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        method: 'GET',
        withCredentials: true
    };

    const requestPath = `/managerData/byName?managerCSVName=${managerCSVName}`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
