// React Imports
import React, { useState, useEffect } from 'react';
// MUI Imports
import PropTypes from 'prop-types';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { LinearProgress } from '@mui/material';

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" data-testid="linear-completion-indicator" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography sx={{ fontSize: props.fontSize }} variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                data-testid="circular-completion-indicator"
                variant="determinate"
                sx={{
                    color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
                }}
                size={40}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="determinate"
                sx={{
                    position: 'absolute',
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round'
                    }
                }}
                size={40}
                thickness={4}
                {...props}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography sx={{ fontSize: props.fontSize }} variant="caption" component="div" color="text.secondary">
                    {props.completed}/{props.total}
                </Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired
};

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired
};

/**
 * Radial indicator showing progress
 * @param {Integer} completed amount that is completed.
 * @param {Integer} total total to be completed.
 * @param {Integer} speed speed at which the bar fills.
 * @param {Integer} size size of the radial indicator.
 * @param {Integer} fontSize size of the font inside the indicator.
 * @param {String} type a type of indicator, either linear (default) or circular.
 * @returns radial indicator
 */
export default function CompletionIndicator({ completed, total, speed, size, fontSize, type = 'linear' }) {
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);
    const [color, setColor] = useState('error');
    const progressAmt = (completed / total) * 100;
    const loadingDuration = 3000; // 3 seconds

    // UseEffect that calculates the progress of the ring chart.
    useEffect(() => {
        let loadingTimeout = setTimeout(() => {
            if (!loading) return;
            const newProgress = progress + speed;
            setProgress(newProgress > progressAmt ? progress + (progressAmt - progress) : newProgress);
        }, loadingDuration / 100);
        if (progressAmt === progress) {
            setLoading(false);
        }
        return () => {
            clearTimeout(loadingTimeout);
        };
    }, [progress, loading, progressAmt, speed]);

    // On change of the progressAmt, the loading indicator is set back to true.
    useEffect(() => {
        setLoading(true);
        setColor(progressAmt < 25 ? 'error' : progressAmt < 100 ? 'warning' : 'success');
    }, [progressAmt]);

    return (
        <React.Fragment>
            {type === 'circular' ? (
                <CircularProgressWithLabel value={progress} size={size} fontSize={fontSize} completed={completed} total={total} />
            ) : (
                <LinearProgressWithLabel variant="determinate" value={progress} color={color} fontSize={fontSize} />
            )}
        </React.Fragment>
    );
}
