import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to delete an org.
 * @param {String} orgId Id of the org to delete.
 * @returns deleted org.
 */
export const deleteOrg = async (orgId) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;

    const httpOptions = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        method: 'DELETE',
        withCredentials: true
    };

    const requestPath = `/organization?Id=${orgId}`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
