// React Imports
import React, { useState } from 'react';
// MUI Imports
import { Box, CircularProgress, MenuItem, InputLabel, Select, TextField, Button, DialogTitle, Dialog, DialogContent, FormControl } from '@mui/material';
// File Imports
import { createOrg } from '../services/createOrg';
import { orgStore } from 'stores';

function OrgDialog(props) {
    const { onClose, open, orgs } = props;

    // States
    const [envName, setEnvName] = useState('');
    const [envNameError, setEnvNameError] = useState(false);
    const [envNameErrorMessage, setEnvNameErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [orgType, setOrgType] = useState('Sandbox');

    // Methods
    /**
     * @description Closes the dialog.
     */
    const closeDialog = () => {
        resetForm();
        onClose();
    };

    /**
     * @description Resets all fields on the creation form.
     */
    const resetForm = () => {
        setEnvName('');
        setOrgType('Sandbox');
        setEnvNameError(false);
        setEnvNameErrorMessage('');
    };

    /**
     * @description Handles the selection of Org Type and assigns it to the orgType state.
     * @param {Object} event event details
     */
    const handleOrgTypeChange = (event) => {
        setOrgType(event.target.value);
    };

    /**
     * @description Handles the input of Name and assigns it to the envName state.
     * @param {Object} event event details
     */
    const handleNameChange = (event) => {
        setEnvName(event.target.value);
    };

    /**
     * @description Sends a request to authorize a new org.
     */
    const addOrg = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        if (!isDuplicate()) {
            // Set source info in options to be used after authed
            const options = { redirectInfo: orgStore.getState('newOrgRedirectInfo').value };
            createOrg(orgType, envName, options);
        } else {
            setEnvNameError(true);
            setEnvNameErrorMessage('Name is already taken.');
        }
    };

    /**
     * @description Checks if the new org would be considered a dupe
     * @returns {Boolean} duplicate flag
     */
    const isDuplicate = () => {
        return Object.values(orgs).filter((org) => org.name === envName).length === 0 ? false : true;
    };

    /**
     * @description Checks if the form was filled out correctly.
     * @returns boolean for if the form is valid.
     */
    const isFormValid = () => {
        return envName.length < 3;
    };

    return (
        <Dialog onClose={closeDialog} open={open}>
            <DialogTitle sx={{ alignSelf: 'center' }}>Org Details</DialogTitle>
            <DialogContent>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '35ch' },
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10,
                        p: 2
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={addOrg}
                >
                    {!isLoading && (
                        <React.Fragment>
                            <TextField id="title-basic" label="Name" variant="outlined" required value={envName} error={envNameError} helperText={envNameErrorMessage} onChange={handleNameChange} />
                            <FormControl required>
                                <InputLabel>Org Type</InputLabel>
                                <Select value={orgType} label="Org Type" onChange={handleOrgTypeChange}>
                                    <MenuItem value={'Sandbox'}>Sandbox</MenuItem>
                                    <MenuItem value={'Production'}>Production</MenuItem>
                                </Select>
                            </FormControl>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button onClick={closeDialog} variant="outlined" color="primary">
                                    Cancel
                                </Button>
                                <Button type="submit" variant="contained" color="primary" disabled={isFormValid()}>
                                    Create Org
                                </Button>
                            </Box>
                        </React.Fragment>
                    )}
                    {isLoading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </Box>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default OrgDialog;
