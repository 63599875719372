// React
import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
// MUI
import { Container } from '@mui/material';
// Components
import LoadingIndicator from 'components/ui/LoadingIndicator';
import TryAgain from 'components/ui/TryAgain';
// Features
import { getProject } from 'features/projects';
import { UARStepper } from 'features/reports';
import { getSObjects, getSystemSettings, useSalesforceData } from 'features/salesforce';
import { getOrg } from 'features/organizations';
// Data
import { alertMessages } from 'data/alerts';
import { reportStore } from 'stores';
import { miscStore } from 'stores';

const LOADING_STEPS = [
    {
        key: 0,
        label: 'Retrieving Salesforce Data'
    }
];

function UARProcess() {
    const { id } = useParams();
    const [tryAgain, setTryAgain] = useState(false);
    const salesforceRequests = useCallback(() => [getSObjects(), getSystemSettings()], []);

    // Get Salesforce SObjects and System Settings
    const [, setCurrentOrg] = reportStore.useState('currentOrg');
    const options = { calledFromLocation: 'uar-process' };
    const { data, isError, isLoading } = useSalesforceData(salesforceRequests, options, tryAgain);
    const [sObjects, systemSettings] = data;
    reportStore.setState('allObjects', sObjects);
    reportStore.setState('allSystemSettings', systemSettings);

    const [searchParams] = useSearchParams();
    const [, , updateAlert] = miscStore.useState('alert');

    useEffect(async () => {
        // Retrieves the Project Details
        const project = await getProject(id);

        // Get Org Details
        const org = await getOrg(project.orgId);
        setCurrentOrg(org);
        console.log('Org = ', org);

        // Checks if alert should be posted for successful auth
        const authResult = searchParams.get('authResult');
        if (authResult === 'success') {
            updateAlert((alert) => {
                alert.autoHideDuration = 3000;
                alert.message = alertMessages.success.auth;
                alert.open = true;
                alert.severity = 'success';
                alert.onClose = handleAlertClose;
            });
        }
    }, []);

    /**
     * Function to close the toast notification
     */
    const handleAlertClose = () => {
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    return (
        <React.Fragment>
            {!isError ? (
                <Container sx={{ display: 'flex', justifyContent: 'center', flex: 1, p: 20, alignItems: !isLoading && data.length > 0 ? 'start' : 'center' }}>
                    {!isLoading && data.length > 0 ? <UARStepper></UARStepper> : <LoadingIndicator loadActiveStep={0} steps={LOADING_STEPS}></LoadingIndicator>}
                </Container>
            ) : (
                <Container sx={{ pt: 80 }}>
                    <TryAgain tryAgain={() => setTryAgain(!tryAgain)} />
                </Container>
            )}
        </React.Fragment>
    );
}

export default UARProcess;
