// React Imports
import React, { useState } from 'react';
// MUI Imports
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
// Component Imports
import ManagerCSVs from './ManagerCSVs';
import SalesforceManagerFieldSelector from './SalesforceManagerFieldSelector';
import SureDialog from 'components/ui/SureDialog';
import { reportStore } from 'stores';

function ManagerDataSelection() {
    const [csvButtonVariant, setCSVButtonVariant] = useState('outlined');
    const [managerInfo, , updateManagerInfo] = reportStore.useState('managerInfo');
    const [sfdcButtonVariant, setSFDCButtonVariant] = useState('outlined');
    const [sureOpen, setSureOpen] = useState(false);
    //Functions
    /**
     * Handles the click for the method of data retrieval.
     * @param {Object} event
     */
    const handleClick = (event) => {
        if (event.currentTarget.id === 'salesforce' && managerInfo.managerCSVName) {
            setSureOpen(true);
        } else if (event.currentTarget.id === 'csv' && managerInfo.managerField.QualifiedApiName) {
            setSureOpen(true);
        } else {
            updateManagerInfo((managerInfo) => {
                managerInfo.managerSource = event.currentTarget.id;
            });
            isButtonStyled(event.currentTarget.id);
        }
    };

    /**
     * Function that handles No selection from the Are You Sure Modal
     */
    const handleNo = () => {
        setSureOpen(false);
    };

    /**
     * Function that handles Yes selection from the Are You Sure Modal
     */
    const handleYes = async () => {
        setSureOpen(false);
        if (managerInfo.managerSource === 'salesforce') {
            updateManagerInfo((managerInfo) => {
                managerInfo.managerSource = 'csv';
                managerInfo.managerField = {};
                managerInfo.managerReady = false;
            });
            isButtonStyled('csv');
        } else if (managerInfo.managerSource === 'csv') {
            updateManagerInfo((managerInfo) => {
                managerInfo.managerSource = 'salesforce';
                managerInfo.managerCSVName = '';
                managerInfo.managerReady = false;
            });
            isButtonStyled('salesforce');
        }
    };

    /**
     * Funtion to handle the button styling when one is selected
     * @param {String} source of the button click
     */
    const isButtonStyled = (source) => {
        if (source === 'salesforce') {
            setSFDCButtonVariant('contained');
            setCSVButtonVariant('outlined');
        } else if (source === 'csv') {
            setSFDCButtonVariant('outlined');
            setCSVButtonVariant('contained');
        } else {
            console.log('invalid source: ', source);
        }
    };

    return (
        <React.Fragment>
            <Grid container spacing={10} sx={{ textAlign: 'center' }}>
                <Grid item xs={6}>
                    <Button sx={{ maxWidth: 275, minHeight: 160, maxHeight: 150 }} id="salesforce" variant={sfdcButtonVariant} onClick={handleClick}>
                        <Box sx={{ mb: 'auto', mt: 10 }}>
                            <Typography variant="h5" component="div">
                                Salesforce
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.primary" variant="subtitle1">
                                Select a Manager field on your User object from Salesforce and we&apos;ll handle the rest!
                            </Typography>
                        </Box>
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button sx={{ maxWidth: 275, minHeight: 160, maxHeight: 150 }} id="csv" variant={csvButtonVariant} onClick={handleClick}>
                        <Box sx={{ mb: 'auto', mt: 10 }}>
                            <Typography variant="h5" component="div">
                                CSV
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.primary">
                                Store your Manager data externally? Select or Upload a CSV file.
                            </Typography>
                        </Box>
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {managerInfo.managerSource === 'salesforce' && (
                        <Box>
                            <Divider sx={{ mt: 20, mb: 15 }}></Divider>
                            <SalesforceManagerFieldSelector></SalesforceManagerFieldSelector>
                        </Box>
                    )}
                    {managerInfo.managerSource === 'csv' && (
                        <React.Fragment>
                            <Divider sx={{ mt: 20, mb: 15 }} />
                            <ManagerCSVs
                                tableOptions={{
                                    checkbox: true,
                                    name: { display: true, minWidth: '100%' },
                                    download: { display: false, minWidth: 60 },
                                    delete: { display: false, minWidth: 60 }
                                }}
                            ></ManagerCSVs>
                        </React.Fragment>
                    )}
                </Grid>
            </Grid>
            <SureDialog content={'Changing Tabs will clear your current Selection'} open={sureOpen} handleNeg={handleNo} handlePos={handleYes}></SureDialog>
        </React.Fragment>
    );
}

export default ManagerDataSelection;
