import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to approve an approval.
 * @param {String} oldStatus old status of the approval
 * @param {Object} approval the updated approval
 * @returns the newly approved Approval.
 */
export const apiApproveApproval = async (oldStatus, approval) => {
    const { jwtToken } = authStore.getState('passwordlessSession').value.sessionDetails;

    const httpOptions = {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        body: JSON.stringify({
            oldStatus,
            approval
        })
    };

    const requestPath = `/approval/approve?isPasswordless=${true}`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
