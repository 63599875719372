import { fetchData } from 'services/fetchData';

import { authStore, reportStore } from 'stores';

/**
 * Send a request to the API to generate a UAR Report.
 * @param {Object} body body of the Post request
 * @returns report
 */
export const generateUARReport = async (body) => {
    const { jwtToken } = authStore.getState('passwordSession').value.sessionDetails;
    const organization = reportStore.getState('currentOrg').value;

    const httpOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`
        },
        withCredentials: true,
        body: JSON.stringify({
            orgId: organization._id,
            ...body
        })
    };

    const requestPath = `/uarReport/generate`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
