import { fetchData } from 'services/fetchData';

import { authStore } from 'stores';

/**
 * Send a request to the API to retrieve a UAR Report with its UAR Rows and Approvals
 * @param {String} uarReportId Id of the UAR Report to retrieve.
 * @param {Boolean} isPasswordless disctates whether a call is passwordless or passwword
 * @returns report
 */
export const getReportWithUARRowsAndApprovals = async (uarReportId, filterCriteria, isPasswordless = false) => {
    const { jwtToken } = authStore.getState(isPasswordless ? 'passwordlessSession' : 'passwordSession').value.sessionDetails;

    const httpOptions = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
        },
        method: 'GET',
        withCredentials: true
    };

    const requestPath = `/uarReport/withUARRowsAndApprovals/${uarReportId}?isPasswordless=${isPasswordless}`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
