// React Imports
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// MUI Imports
import { AppBar, Box, Button, Toolbar, Tabs, Tab } from '@mui/material';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
//import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useTheme } from '@mui/material/styles';

import { AccountButton } from 'features/settings';
import { FreeTrialAlert } from 'features/auth';

import { ReactComponent as SalesforceLogo } from 'assets/Salesforce_Logo_White.svg';
import nemosLogoWithTextLight from 'assets/NemosFoxLogoWithTextLightMode.svg';
import nemosLogoWithTextDark from 'assets/NemosFoxLogoWithTextDarkMode.svg';
import { authStore } from 'stores';

function Header() {
    const passwordSession = authStore.getState('passwordSession').value;
    const passwordlessSession = authStore.getState('passwordlessSession').value;
    const [currentTab, setCurrentTab] = useState('uarReporter');
    const currentTheme = useTheme();
    const search = useLocation();
    const navigate = useNavigate();

    const toLogin = () => {
        navigate(`/login?retUrl=${search.pathname}`, { replace: true });
    };

    const toHome = () => {
        if (passwordSession.isValid) {
            navigate(`/${passwordSession.sessionDetails.namespace}`, { replace: true });
        } else if (!passwordlessSession.isValid) {
            toLogin();
        }
    };

    const toSalesforceScanner = () => {
        navigate(`/${passwordSession.sessionDetails.namespace}/salesforce-scanner`, { replace: true });
    };

    /*const toAuditHistoryManagement = () => {
        navigate(`/${passwordSession.sessionDetails.namespace}/audit`, { replace: true });
    };*/
    const tabOptions = [
        {
            label: 'UAR Reporter',
            value: 'uarReporter',
            icon: <SupervisorAccountIcon color={currentTab === 'uarReporter' ? 'primary' : 'secondary'} />,
            nav: toHome
        },
        {
            label: 'Salesforce Scanner',
            value: 'salesforceScanner',
            icon: <SalesforceLogo fill={currentTab === 'salesforceScanner' ? currentTheme.palette.primary.main : currentTheme.palette.secondary.main} height={25} width={25} />,
            nav: toSalesforceScanner
        } /*,
        {
            label: 'Change Audit',
            value: 'auditHistoryManagement',
            icon: <VerifiedUserIcon fill={currentTab === 'auditHistoryManagement' ? currentTheme.palette.primary.main : currentTheme.palette.secondary.main} height={25} width={25} />,
            nav: toAuditHistoryManagement
        }*/
    ];

    /**
     * Updates the tab to match the current url path
     */
    useEffect(() => {
        if (search.pathname.includes('/salesforce-scanner')) {
            setCurrentTab('salesforceScanner');
        } else if (search.pathname.includes('/audit')) {
            setCurrentTab('auditHistoryManagement');
        } else {
            setCurrentTab('uarReporter');
        }
    }, [search.pathname]);

    return (
        <React.Fragment>
            <header>
                <AppBar position="fixed" color="text" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar>
                        <Button onClick={toHome}>
                            {currentTheme.palette.mode === 'dark' && <img src={nemosLogoWithTextDark} style={{ height: '3rem', width: '8rem' }} alt="Helios" />}
                            {currentTheme.palette.mode === 'light' && <img src={nemosLogoWithTextLight} style={{ height: '3rem', width: '8rem' }} alt="Helios" className="header-image" />}
                        </Button>
                        {passwordSession.isValid && (
                            <Tabs aria-label="tool tabs" sx={{ pl: 10 }} value={currentTab}>
                                {tabOptions.map((tab) => (
                                    <Tab
                                        sx={{ color: currentTab === tab.value ? currentTheme.palette.primary.main : currentTheme.palette.secondary.main }}
                                        key={tab.value}
                                        icon={tab.icon}
                                        label={tab.label}
                                        value={tab.value}
                                        onClick={tab.nav}
                                    />
                                ))}
                            </Tabs>
                        )}
                        <Box sx={{ flexGrow: 1 }} />
                        {/*<ThemeSwitch switchType="theme" sx={{ display: 'contents' }}></ThemeSwitch>*/}
                        <FreeTrialAlert></FreeTrialAlert>
                        <AccountButton></AccountButton>
                    </Toolbar>
                </AppBar>
            </header>
        </React.Fragment>
    );
}

export default Header;
