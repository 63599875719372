// React Imports
import React, { useEffect, useState } from 'react';
// MUI Imports
import { Box, Button, IconButton, Paper, TextField, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleIcon from '@mui/icons-material/People';
// Component Imports
import NoRowsAlert from 'components/ui/NoRowsAlert';
import SureDialog from 'components/ui/SureDialog';
import TableHeader from 'components/ui/TableHeader';
// API Imports
import { createAuditor } from '../services/createAuditor';
import { deleteAuditor } from '../services/deleteAuditor';
// Utils
import { validateEmail } from 'utils/validateEmail';
// File Imports
import { alertMessages } from 'data/alerts';
import { authStore, miscStore } from 'stores';

function AuditorSelector({ project, setProject }) {
    //Constants
    const columns = [
        { field: 'email', headerName: 'Email', flex: 1 },
        {
            field: 'Delete',
            headerName: '',
            align: 'center',
            sortable: false,
            filterable: false,
            renderCell: (cellValues) => {
                return (
                    <IconButton color="primary" onClick={() => handleDeleteOpen(cellValues.row)}>
                        <DeleteIcon />
                    </IconButton>
                );
            }
        }
    ];

    //States
    const [, , updateAlert] = miscStore.useState('alert');
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [currentValue, setCurrentValue] = useState('');
    const [passwordSession] = authStore.useState('passwordSession');
    const [deleteRowInfo, setDeleteRowInfo] = useState();
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [pageSize, setPageSize] = useState();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const AUDITOR_TABLE_ACTIONS = [
        <Box key="submitButton" sx={{ display: 'flex' }}>
            <TextField value={currentValue} error={emailInvalid} id="auditor-input" label="Email" variant="outlined" size="small" onChange={(event) => handleChange(event.target.value)} />
            <Button sx={{ minHeight: 40 }} variant="contained" disabled={buttonDisabled} onClick={() => handleSubmit()}>
                Submit
            </Button>
        </Box>
    ];

    //useEffects
    /**
     * useEffect to recheck the button disabling every change including submit
     */
    useEffect(() => {
        checkEmail();
    }, [currentValue]);

    //Functions
    /**
     * Function to handle the validation of the Email Address
     */
    const checkEmail = () => {
        if (currentValue.length > 0) {
            const valid = validateEmail(currentValue);
            setEmailInvalid(!valid);
            setButtonDisabled(!valid);
        } else {
            setButtonDisabled(true);
            setEmailInvalid(false);
        }
    };

    /**
     * Function to facilitate deleting an Auditor from MongoDB
     * @param {String} auditorId Id of the auditor to delete
     */
    const deleteRow = async (auditorId) => {
        try {
            const updatedProject = await deleteAuditor(project._id, auditorId);
            setProject(updatedProject);
            postAlert('success', alertMessages.success.auditor_deleted);
        } catch (err) {
            postAlert('error', alertMessages.error.auditor_deleted);
        }
    };

    /**
     * Function to capture the current value of the email input
     * @param {String} value current value in the email input
     */
    const handleChange = (value) => {
        setCurrentValue(value);
    };

    /**
     * Function to close the toast notification
     */
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    /**
     * Function to close the delete modal
     */
    const handleDeleteClose = () => {
        setDeleteRowInfo(null);
        setIsOpen(false);
    };

    /**
     * Function to open the delete modal
     * @param {Object} row Row that caused the Modal to open
     */
    const handleDeleteOpen = (row) => {
        setDeleteRowInfo(row._id);
        setIsOpen(true);
    };

    /**
     * Function to handle the Submit event
     */
    const handleSubmit = async () => {
        const acctEmail = passwordSession.sessionDetails.email;
        if (currentValue !== acctEmail) {
            const found = project.auditors.find((row) => row.email === currentValue);
            if (!found) {
                const newAuditor = {
                    email: currentValue,
                    projectId: project._id
                };
                const updatedProject = await createAuditor(newAuditor);
                setProject(updatedProject);
                setCurrentValue('');
                postAlert('success', alertMessages.success.auditor_created);
            } else {
                postAlert('error', alertMessages.error.auditor_duplicate);
            }
        } else {
            postAlert('error', alertMessages.error.auditor_account_email);
        }
    };

    /**
     * Function to handle calling Delete
     */
    const handleYes = () => {
        deleteRow(deleteRowInfo);
        handleDeleteClose();
    };

    /**
     * When resizing the window to a small screen, the autoPageSize prop on DataGrid kicks in
     * and calulates the num of rows to show to prevent scrolling. We store that value in
     * pageSize and pass it to the pageSize prop.
     * @param {Number} numOfRows the number of rows to show in the table.
     */
    const handlePageSizeChange = (numOfRows) => {
        setPageSize(numOfRows);
    };

    /**
     * Triggers a global alert.
     * @param {String} severity severity of the alert
     * @param {String} message message to display
     */
    const postAlert = (severity, message) => {
        updateAlert((alert) => {
            alert.autoHideDuration = 3000;
            alert.message = message;
            alert.open = true;
            alert.severity = severity;
            alert.onClose = handleAlertClose;
        });
    };

    return (
        <React.Fragment>
            <Paper elevaton={6} sx={{ display: 'flex', flexDirection: 'column', height: 400 }}>
                <TableHeader icon={<PeopleIcon color="primary" />} title="Auditors" showActions actions={AUDITOR_TABLE_ACTIONS} />
                <DataGrid
                    autoPageSize={isSmallScreen ? true : false}
                    columns={columns}
                    components={{ NoRowsOverlay: () => <NoRowsAlert title="No Auditors" message="Add an Auditor to send a report."></NoRowsAlert> }}
                    disableSelectionOnClick
                    getRowId={(row) => row._id}
                    onPageSizeChange={handlePageSizeChange}
                    pageSize={isSmallScreen ? pageSize : 100}
                    rows={project.auditors}
                    rowHeight={50}
                    rowsPerPageOptions={[100]}
                ></DataGrid>
            </Paper>
            <SureDialog title="Delete Auditor" content="Are you sure you want to delete the auditor?" open={isOpen} handleNeg={handleDeleteClose} handlePos={handleYes}></SureDialog>
        </React.Fragment>
    );
}

export default AuditorSelector;
