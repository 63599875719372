import { fetchData } from 'services/fetchData';

/**
 * Send a request to the API to check access to a UAR Report
 * @param {String} id Id of the UAR Report to retrieve.
 * @param {String} email email to check access for
 * @returns report access
 */
export const checkReportAccess = async (reportId, email) => {
    const httpOptions = {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'GET',
        withCredentials: true
    };

    const requestPath = `/uarReport/access?id=${reportId}&email=${email}`;
    const { data } = await fetchData(requestPath, httpOptions);
    return data;
};
