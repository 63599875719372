import { useRoutes } from 'react-router-dom';

import { Box, CircularProgress } from '@mui/material';

import Error from 'pages/Error';

import { getProtectedRoutes } from './getProtected';
import { publicPasswordRoutes } from './public';

import { authStore } from 'stores';

export const AppRoutes = () => {
    const [passwordSession] = authStore.useState('passwordSession');
    const [passwordlessSession] = authStore.useState('passwordlessSession');

    const protectedRoutes = getProtectedRoutes(passwordSession.sessionDetails.namespace ?? '*');
    const commonRoutes = [{ path: '*', element: <Error /> }];

    const routes = useRoutes([...protectedRoutes, ...publicPasswordRoutes, ...commonRoutes]);

    if (passwordSession.isLoading || passwordlessSession.isLoading) {
        return (
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignSelf: 'center' }}>
                <CircularProgress />
            </Box>
        );
    }

    return routes;
};
