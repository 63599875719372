// ReactI mports
import React, { useMemo, useState } from 'react';
// MUI Imports
import { Box, Backdrop, Card, CardActions, CardContent, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
// Component Imports
import CompletionIndicator from 'components/ui/CompletionIndicator';
import ManagerApprovalsCrudTable from './ManagerApprovalsCrudTable';
import ManagerApprovalsSystemTable from './ManagerApprovalsSystemTable';
import ProfileAndPermissionSetAssignments from './ProfileAndPermissionSetAssignments';
import TabSet from 'components/ui/TabSet';
// 3rd Party Imports
import * as dayjs from 'dayjs';

export default function ManagerApprovalsCard({ approvalRow, useReport }) {
    const groupedApprovals = useMemo(
        () =>
            approvalRow.approvals.reduce(
                (acc, approval, index) => {
                    if (approval.approvalType === 'SystemSetting') {
                        acc.systemApprovals.push({ ...approval, index });
                    } else {
                        acc.objectApprovals.push({ ...approval, index });
                    }
                    return acc;
                },
                { systemApprovals: [], objectApprovals: [] }
            ),
        approvalRow.approvals
    );

    const TABS = [
        {
            key: 'systemSettings',
            content: <ManagerApprovalsSystemTable approvals={groupedApprovals.systemApprovals} useReport={useReport} />,
            label: 'System Settings'
        },
        {
            key: 'objectAccess',
            content: <ManagerApprovalsCrudTable approvals={groupedApprovals.objectApprovals} useReport={useReport} />,
            label: 'Object Access'
        },
        { key: 'profileAndPermissions', content: <ProfileAndPermissionSetAssignments approvalRow={approvalRow} />, label: 'Profile And Permissions' }
    ];

    const theme = useTheme();
    const [isLoading] = useState(false);

    return (
        <Card sx={{ height: '100%' }}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', boxSizing: 'border-box' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                    <CircleIcon
                        fontSize="small"
                        sx={{
                            color: approvalRow.status === 'In Progress' || approvalRow.status === 'Cancelled' ? 'orange' : theme.palette.text.success
                        }}
                    ></CircleIcon>
                    <Typography sx={{ textWrap: 'nowrap' }} display="inline" variant="body1" color={theme.palette.text.secondary}>
                        {approvalRow.status}
                    </Typography>
                    <Box sx={{ width: { xs: '12rem', md: '100%' } }}>
                        <CompletionIndicator completed={approvalRow.approvalsCount} total={approvalRow.approvals.length} speed={4} size={50} fontSize="1rem" type="linear"></CompletionIndicator>
                    </Box>
                </Box>
                <Divider variant="middle" sx={{ width: '100%', mt: 4 }} />
                <Grid container spacing={5} sx={{ m: 0 }}>
                    <Grid item xs={6}>
                        <Typography sx={{ fontWeight: 'bold' }} color="primary" variant="body1" component="div">
                            Name:{' '}
                            <Typography display="inline" variant="body1" color={theme.palette.text.secondary}>
                                {approvalRow.name}
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="primary" variant="body1" component="div">
                            <b>Email:</b>{' '}
                            <Typography display="inline" variant="body1" color={theme.palette.text.secondary}>
                                {approvalRow.userEmail}
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{ display: 'flex' }} color="primary" variant="body1" component="div">
                            <b>Active:</b> {approvalRow.active ? <CheckIcon sx={{ color: 'text.success' }} /> : <CloseIcon sx={{ color: 'text.error' }} />}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="primary" variant="body1" component="div">
                            <b>Last Login:</b>{' '}
                            <Typography display="inline" variant="body1" color={theme.palette.text.secondary}>
                                {approvalRow.lastLogin ? `${dayjs(approvalRow.lastLogin).format('h:mm A')} on ${dayjs(approvalRow.lastLogin).format('MM/DD/YYYY')}` : 'No Login History'}
                            </Typography>
                        </Typography>
                    </Grid>
                </Grid>
                <Divider variant="middle" sx={{ width: '100%', mt: 8, mb: 10 }} />
                <Box sx={{ flex: 1 }}>
                    <TabSet tabs={TABS}></TabSet>
                </Box>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}></CardActions>
        </Card>
    );
}
