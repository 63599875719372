// React Imports
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
// MUI Imports
import { Box, Typography } from '@mui/material';
// Component Import
import { ManagerApprovalsTable } from 'features/approvals';
import { ReportCard, useReport } from 'features/reports';
import { authStore } from 'stores';

const CLOSED_REPORT_TEXT = 'This report has been closed and is no longer editable. For any questions, please reach out to your Salesforce Admin.';

function UARReportApproval() {
    const { reportId } = useParams();

    const [passwordlessSession] = authStore.useState('passwordlessSession');
    const filterCriteria = useMemo(() => ({ approvalRow: { 'approvalRows.managerEmail': passwordlessSession.sessionDetails.email } }), [passwordlessSession.sessionDetails.email]);

    const useReportHook = useReport(reportId, filterCriteria, 'approval', false);
    const { report } = useReportHook;
    console.log('top level report = ', report);

    const reportIsClosed = report?.status !== 'Pending Approvals' ?? true;

    return (
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            {report && report.approvalRows.length > 0 && (
                <>
                    <Box sx={{ flexShrink: 0 }}>
                        <ReportCard useReport={useReportHook} />
                    </Box>
                    {reportIsClosed && (
                        <Box sx={{ textAlign: 'center', mt: 18, flexShrink: 0 }}>
                            <Typography sx={{ fontSize: 20 }} color="primary">
                                {CLOSED_REPORT_TEXT}
                            </Typography>
                        </Box>
                    )}
                    <Box sx={{ flexGrow: 1 }}>
                        <ManagerApprovalsTable approvalRows={report.approvalRows} useReport={useReportHook} />
                    </Box>
                </>
            )}
        </Box>
    );
}

export default UARReportApproval;
