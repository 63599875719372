/**
 * Function to take file data and prompt a download in the Users Browser
 * @param {Blob} fileData Blob of File Data
 * @param {String} fileName File Name
 */
export const downloadFile = (fileData, fileName) => {
    const url = window.URL.createObjectURL(fileData);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
};
