// React Imports
import React from 'react';
// MUI Imports
import { Grid, TextField } from '@mui/material';
// File Imports
import { reportStore } from 'stores';

function NewReport() {
    // States
    const [newReport, , updateNewReport] = reportStore.useState('newReport');

    /**
     * Handles the change in value for the Name field.
     * @param {Object} event event details for the name field element.
     */
    const handleNameChange = (event) => {
        const newName = event.target.value;

        updateNewReport((report) => {
            report.name = newName;
        });
    };
    return (
        <React.Fragment>
            <Grid container spacing={5} direction="column" alignContent="center" justifyContent="center">
                <Grid item xs={12}>
                    <TextField id="title-basic" label="Name" variant="outlined" value={newReport.name} fullWidth onChange={handleNameChange} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default NewReport;
