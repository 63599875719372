// React Imports
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// MUI Imports
import { Avatar, Box, Button, CssBaseline, Container, Grid, Paper, TextField, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// Other Files
import { PasswordValidator } from 'features/auth';
import { useCognito } from 'features/auth';

import { miscStore } from 'stores';
import { alertMessages } from 'data/alerts';

export default function ResetPassword() {
    // Constants
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);

    // States
    const [, , updateAlert] = miscStore.useState('alert');
    const [confirmPasswordVal, setConfirmPasswordVal] = useState(false);
    const [confirmPasswordValid, setConfirmPasswordValid] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [passwordVal, setPasswordVal] = useState('');
    const [passwordValid, setPasswordValid] = useState(false);
    const [userConfirmed] = useState(true);

    const { confirmPasswordCode } = useCognito();

    // Local Methods
    /**
     * Navigates to the Login Page.
     */
    const navigateToLogin = () => {
        navigate('/login', { replace: true });
    };

    /**
     * Handles the reset password event
     * @param {Object} event details of the event
     */
    const handleResetPassword = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const userName = params.get('user_name');
        const confirmationCode = params.get('confirmation_code');
        const data = new FormData(event.currentTarget);
        try {
            await confirmPasswordCode(userName, data.get('password'), confirmationCode);
            navigateToLogin();
        } catch (e) {
            setIsLoading(false);
            if (e.code === 'ExpiredCodeException') {
                updateAlert((alert) => {
                    alert.autoHideDuration = 3000;
                    alert.message = alertMessages.error.expired_password_link;
                    alert.open = true;
                    alert.severity = 'error';
                    alert.onClose = handleAlertClose;
                });
            }
        }
    };

    /**
     * Function to close the toast notification
     */
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        updateAlert((alert) => {
            alert.open = false;
        });
    };

    /**
     * Handles the change in value of the password
     * @param {Object} event details of the password change
     */
    const handlePasswordChange = (event) => {
        handlePasswordMatching(event.target.value, confirmPasswordVal);
        setPasswordVal(event.target.value);
    };

    /**
     * Handles the change in value of the Confirm Password field.
     * @param {Object} event
     */
    const handleConfirmPasswordChange = (event) => {
        handlePasswordMatching(event.target.value, passwordVal);
        setConfirmPasswordVal(event.target.value);
    };

    /**
     * Handles matching passowrds.
     * @param {String} password the current password being modified
     * @param {String} passwordToMatch the password to match against
     */
    const handlePasswordMatching = (password, passwordToMatch) => {
        if (password !== '' && passwordToMatch === password) {
            setConfirmPasswordError(false);
            setConfirmPasswordErrorText('');
            setConfirmPasswordValid(true);
        } else if (password !== '' && passwordToMatch !== password) {
            setConfirmPasswordError(true);
            setConfirmPasswordErrorText('Passwords do not match');
            setConfirmPasswordValid(false);
        } else {
            setConfirmPasswordError(false);
            setConfirmPasswordErrorText('');
            setConfirmPasswordValid(false);
        }
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh" sx={{ flex: 1 }}>
            <Container component="main" maxWidth="xs">
                <Paper elevation={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <CssBaseline />
                    {userConfirmed && (
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Create a Password
                            </Typography>
                            <Box component="form" noValidate onSubmit={handleResetPassword} sx={{ mt: 10 }}>
                                <Grid container spacing={8} sx={{ pl: '1rem', pr: '1rem' }}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">Make sure your new password meets the following requirements:</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PasswordValidator passwordVal={passwordVal} setPasswordValid={(val) => setPasswordValid(val)}></PasswordValidator>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField required fullWidth onChange={handlePasswordChange} id="password" label="Password" name="password" type="password" autoComplete="" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            error={confirmPasswordError}
                                            onChange={handleConfirmPasswordChange}
                                            helperText={confirmPasswordErrorText}
                                            name="confirmPassword"
                                            label="Confirm Password"
                                            id="confirmPassword"
                                            autoComplete="organization"
                                            type="password"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {isLoading ? (
                                            <LinearProgress></LinearProgress>
                                        ) : (
                                            <Button type="submit" fullWidth disabled={!passwordValid || !confirmPasswordValid} variant="contained" sx={{ mt: 8, mb: 8 }}>
                                                Create Password
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    )}
                    {!userConfirmed && (
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Typography component="h1" variant="h5">
                                Reset link is no longer valid.
                            </Typography>
                        </Box>
                    )}
                </Paper>
            </Container>
        </Box>
    );
}
